import { mapGetters } from 'vuex';

export default {
    methods: {
        openPathwayAction(pathway_id) {
            dataCollector.addAction('open_pathway', {
                pathway_id: pathway_id
            });
        },
        openIntroPathwayAction(pathway_id) {
            dataCollector.addAction('open_intro_pathway', {
                pathway_id: pathway_id
            });
        },
        openLoginFormAction(pathway_id) {
            dataCollector.addAction('open_intro_login_form', {
                pathway_id: pathway_id
            });
        },
        backToIntroAction(pathway_id) {
            dataCollector.addAction('back_to_home', {
                pathway_id: pathway_id
            });
        },
        skipContentAction(content_id, node_id, pathway_id) {
            dataCollector.addAction('skip_content', {
                content_id: content_id,
                node_id: node_id,
                pathway_id: pathway_id
            });
        },
        openContentAction(content_id) {
            dataCollector.addAction('open_content', {
                content_id: content_id
            });
        },
        introLoginAction(email, message) {
            dataCollector.addAction('intro_login', {
                email: email,
                message: message
            });
        },
        loadNodeAction(node) {
            let data = {
                pathway_id: node.pathway_id,
                content_id: node.content_id,
                node_id: node.id
            };

            setTimeout(() => {
                dataCollector.addAction('load_node', data);
            }, 500);
        },
        goBackAction(node) {
            let data = {
                pathway_id: node.pathway_id,
                content_id: node.content_id,
                node_id: node.id
            };

            setTimeout(() => {
                dataCollector.addAction('go_back_node', data);
            }, 500);
        },
        openFeedAction() {
            dataCollector.addAction('open_feed');
        },
        openHomepageAction() {
            dataCollector.addAction('open_homepage');
        },
        answerQuestionAction(content, question, answer, pathway_id) {
            dataCollector.addAction('answer_question', {
                content_id: content.id,
                question_id: question.id,
                answer_id: answer.id,
                question_text: question.text,
                answer_text: answer.text,
                pathway_id: pathway_id
            });
        },
        bookmarkContentAction(content, pathway_id) {
            dataCollector.addAction('bookmark_content', {
                content_id: content.id,
                pathway_id: pathway_id
            });
        },
        discardBookmarkAction(content, pathway_id) {
            dataCollector.addAction('discard_content_bookmark', {
                content_id: content.id,
                pathway_id: pathway_id
            });
        },
        discardFeedContentAction(content) {
            dataCollector.addAction('discard_feed_content', {
                content_id: content.id
            });
        },
        submitEmailAction(email, pathway_id) {
            dataCollector.addAction('submit_email', {
                email: email,
                pathway_id: pathway_id
            });
        },
        playVideoAction(content, pathway_id) {
            dataCollector.addAction('play_video', {
                content_id: content.id,
                pathway_id: pathway_id
            });
        },
        exitPathwayAction(pathway_id, node_id) {
            dataCollector.addAction('exit_pathway', {
                pathway_id: pathway_id,
                node_id: node_id
            });
        },
        openProfileAction() {
            dataCollector.addAction('open_profile');
        },
        openActivityAction() {
            dataCollector.addAction('open_activity');
        },
        searchActivityAction(tab, searchText, results) {
            dataCollector.addAction('search_activity', {
                tab: tab,
                search_text: searchText,
                search_results: results
            });
        },
        updateProfileAction(guest) {
            dataCollector.addAction('update_profile', {
                name: guest.name,
                email: guest.email
            });
        },
        searchAction(searchText, results) {
            dataCollector.addAction('search_site', {
                search_text: searchText,
                search_results: results
            });
        },
        openSearchAction(searchText, results, page) {
            dataCollector.addAction('open_search_page', {
                search_text: searchText,
                pagination_page: page,
                search_results: results
            });
        },
        // Deck actions
        deckOpen() {
            dataCollector.addAction('deck_open', {
                deck_tab: this.deckTab,
                deck_view: this.deckView,
                isShared: !!this.sharedDeckFrom,
                shared_from: this.sharedDeckFrom ? this.sharedDeckFrom.id : false
            });
        },
        deckMyDeck() {
            dataCollector.addAction('deck_tab_my_deck', {
                deck_tab: this.deckTab,
                deck_view: this.deckView
            });
        },
        deckCustomize() {
            dataCollector.addAction('deck_tab_customize', {
                deck_tab: this.deckTab,
                deck_view: this.deckView
            });
        },
        deckCaregivingResources() {
            dataCollector.addAction('deck_tab_caregiving_resources', {
                deck_tab: this.deckTab,
                deck_view: this.deckView
            });
        },
        deckSelectStackView() {
            dataCollector.addAction('deck_select_stack_view', {
                deck_tab: this.deckTab,
                deck_view: this.deckView
            });
        },
        deckSelectGridView() {
            dataCollector.addAction('deck_select_grid_view', {
                deck_tab: this.deckTab,
                deck_view: this.deckView
            });
        },
        deckPrint() {
            dataCollector.addAction('deck_print', {
                deck_tab: this.deckTab,
                deck_view: this.deckView
            });
        },
        deckShareLink() {
            dataCollector.addAction('deck_share_link', {
                deck_tab: this.deckTab,
                deck_view: this.deckView
            });
        },
        deckShareEmail() {
            dataCollector.addAction('deck_share_email', {
                deck_tab: this.deckTab,
                deck_view: this.deckView
            });
        },
        deckFilterBy(category, order, onlyFavorites) {
            dataCollector.addAction('deck_filter_by', {
                deck_tab: this.deckTab,
                deck_view: this.deckView,
                category: category,
                order: order,
                onlyFavorites: onlyFavorites
            });
        },
        deckFavoriteCardToggle(card) {
            dataCollector.addAction('deck_favorite_card_toggle', {
                deck_tab: this.deckTab,
                deck_view: this.deckView,
                card: card.id,
                favorite: !this.guest.favorites.includes(card.id)
            });
        },
        deckRemoveCard(card) {
            dataCollector.addAction('deck_remove_card', {
                deck_tab: this.deckTab,
                deck_view: this.deckView,
                card: card.id
            });
        },
        deckAddCard(card) {
            dataCollector.addAction('deck_add_card', {
                deck_tab: this.deckTab,
                deck_view: this.deckView,
                card: card.id
            });
        },
        deckOpenSuggestModal() {
            dataCollector.addAction('deck_open_suggest_modal', {
                deck_tab: this.deckTab,
                deck_view: this.deckView
            });
        },
        deckSubmitSuggestion(data) {
            dataCollector.addAction('deck_submit_suggestion', {
                deck_tab: this.deckTab,
                deck_view: this.deckView,
                form_data: data
            });
        },
        ssoLoginClickedAction(data) {
            dataCollector.addAction('sso_auth', {});
            dataCollector.addAction('sso_login', {});
        },
        ssoRegisterClickedAction(data) {
            dataCollector.addAction('sso_auth', {});
            dataCollector.addAction('sso_register', {});
        }
    },
    computed: {
        ...mapGetters({
            guest: 'guest',
            deckView: 'deckView',
            deckTab: 'deckTab',
            sharedDeckFrom: 'sharedDeckFrom'
        })
    }
};
