<template>
    <div
        class="rfeed-featured"
        :style="`background-image: url('${savedContent.thumbnail_featured}')`"
    >
        <a :href="`/content/${savedContent.slug}`" class="rfeed-featured__link"></a>
        <div class="rfeed-featured__head">
            <div class="rfeed-featured__badge">From your saved resources</div>
            <div class="rfeed-featured__date">{{ renderFormDate(savedContent.created_at) }}</div>
            <template v-if="savedContent.content_type.name === 'Video'">
                <div class="rfeed-article__bull"></div>
                <div class="rfeed-article__date">
                    {{ videoDuration(savedContent.settings.video_duration) }}
                </div>
            </template>
        </div>
        <div class="rfeed-featured__cont">
            <h4 class="rfeed-featured__title">
                {{ savedContent.title }}
            </h4>
            <p class="rfeed-featured__subtitle" v-html="savedContent.short_description"></p>

            <a class="saved-content__link" href="#">View other saved resources</a>
        </div>
        <div class="rfeed-featured__actions">
            <button
                type="button"
                class="rfeed-featured__action rfeed-featured__action--book"
                :class="{
                    active:
                        guest && guest.resource_ids && guest.resource_ids.includes(savedContent.id)
                }"
                @click="bookmarkAction(guest, savedContent)"
            ></button>
            <button
                type="button"
                class="rfeed-featured__action rfeed-featured__action--share"
            ></button>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Time from '../../../mixins/Time';
import collectorActions from '../../../mixins/collectorActions';
import bookmarkActions from '../../../mixins/bookmarkActions';

export default {
    name: 'saved-card',
    props: ['savedContent'],
    mixins: [Time, collectorActions, bookmarkActions],
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            guest: 'guest'
        })
    },
    methods: {},
    watch: {}
};
</script>

<style lang="scss">
.rfeed-featured__action--book.active {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="%231097E2" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"><path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path></svg>');
}
.rfeed-featured__cont {
    position: relative;

    .saved-content__link {
        display: block;
        margin: 16px 0 0;
        font-size: 1rem;
        color: #1097e2;
        z-index: 1000;
    }
}
</style>
