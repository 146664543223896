$(document).ready(function() {
  /**/
  /* tabs */
  /**/
  $('[data-tab-open]').on('click', function(evnt) {
    evnt.preventDefault();
    const ths = $(this);
    const trgt = $(ths.data('tab-open'));
    
    ths.addClass('active').siblings().removeClass('active');
    trgt.addClass('active').siblings().removeClass('active');
  });
  
  
  /**/
  /* rfeed welcome */
  /**/
  $('.rfeed-welcome__close').on('click', function() {
    $(this).parent().slideUp(250);
  });
  
  
  /**/
  /* rfeed lessons */
  /**/
  $('.rfeed-lessons__close').on('click', function() {
    $(this).parent().slideUp(250);
  });
  
  
  /**/
  /* admin card */
  /**/
  $('.admin-card__toggle').on('click', function() {
    $(this).toggleClass('active');
    $(this).closest('.admin-card').find('.admin-card__spoiler').slideToggle(250);
  });
  
  
  /**/
  /* settings filter */
  /**/
  $('.settings-filter__toggle').on('click', function() {
    $(this).parent().toggleClass('active');
  });
  $(document).mouseup(function(e) 
  {
    var container = $('.settings-filter');
    if (!container.is(e.target) && container.has(e.target).length === 0) 
    {
      container.removeClass('active');
    }
  });
  
  
  /**/
  /* flow drawer filter */
  /**/
  $('.flow-drawer__filter-button').on('click', function() {
    $(this).closest('.flow-drawer').find('.flow-filter').toggleClass('active');
  });
  $(document).mouseup(function(e) 
  {
    var container = $('.flow-filter');
    if (!container.is(e.target) && container.has(e.target).length === 0) 
    {
      container.removeClass('active');
    }
  });
  
  
  /**/
  /* flow content type */
  /**/
  $('.flow-content-type__toggle').on('click', function() {
    $(this).parent().toggleClass('active');
  });
  $('.flow-content-type input').on('change', function() {
    const ths = $(this);
    ths.closest('.flow-content-type__dropdown').prev().text(ths.val());
  });
  $(document).mouseup(function(e) 
  {
    var container = $('.flow-content-type');
    if (!container.is(e.target) && container.has(e.target).length === 0) 
    {
      container.removeClass('active');
    }
  });
  
  
  /**/
  /* notepad */
  /**/
  $('#notepad__toggle').on('click', function() {
    $(this).parent().toggleClass('active');
  });
  
  
  /**/
  /* tooltip */
  /**/
  $('body').append('<div id="tooltip" class="tooltip"></div>');
  $('[data-tooltip]').hover(function() {
      var ths = $(this);
      $('#tooltip').html($(this).data('tooltip'));
      $('#tooltip').attr('class', 'tooltip active');
      $('#tooltip').css('top', ths.offset().top);
      $('#tooltip').css('left', ths.offset().left + ths.outerWidth()/2);
  }, function() {
      $('#tooltip').removeClass('active');
  });
  
  
  /**/
  /* deck nav */
  /**/
  $('.deck-nav__item--share').on('click', function(event) {
    event.preventDefault();
    $(this).toggleClass('active').next().toggleClass('active');
  });
  $(document).on('click', function(evnt) {
    if (!$('.deck-nav__dropdown').is(evnt.target) && $('.deck-nav__dropdown').has(evnt.target).length === 0) {
      if (!$('.deck-nav__item--share').is(evnt.target) && $('.deck-nav__item--share').has(evnt.target).length === 0) {
        $('.deck-nav__dropdown').removeClass('active');
        $('.deck-nav__item--share').removeClass('active');
      }
    }
  });
  
  
  /**/
  /* deck nav */
  /**/
  $('.ctt-sorting__toggle').on('click', function(event) {
    event.preventDefault();
    $(this).toggleClass('active').next().toggleClass('active');
  });
  $(document).on('click', function(evnt) {
    if (!$('.ctt-sorting__dropdown').is(evnt.target) && $('.ctt-sorting__dropdown').has(evnt.target).length === 0) {
      if (!$('.ctt-sorting__toggle').is(evnt.target) && $('.ctt-sorting__toggle').has(evnt.target).length === 0) {
        $('.ctt-sorting__dropdown').removeClass('active');
        $('.ctt-sorting__toggle').removeClass('active');
      }
    }
  });
});