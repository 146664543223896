<template>
    <div class="ctt-content">
        <template>
            <div class="grid-row grid-row--nog mb--125 mt--125" v-show="showDeckCategories">
                <div class="ctt-categories grid-cont grid-cont--xl ctt-tour-categories grid-col ">
                    <template v-if="!sharedDeckFrom">
                        <div class="category-item-holder">
                        <a
                            class="ctt-categories__item ctt-categories__item--all"
                            :class="{ active: (categoryFilter === 'all' && ! onlyFavorites) }"
                            @click="setCategoryFilter('all')"
                            tabindex="0"
                        >
                            All cards
                        </a>
                        <div class="ctt-categories__space removed--sm" style="background: #fff; width: auto!important;"></div>
                        <a
                            @click="filterFavorites"
                            class="ctt-categories__item ctt-categories__item--fav"
                            :class="{ active: onlyFavorites }"
                            tabindex="0"
                            data-formelementid="CARE_TO_TALK-BTN-CLK-MY_LIKES"
                            data-categoryid="caregiving"
                            data-subcategoryid="care to talk"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="13.917" height="12.37"
                                 viewBox="0 0 13.917 12.37">
                                <path id="Path"
                                      d="M8.9,10.982h0c-.473.393-.945.785-1.4,1.186a.818.818,0,0,1-1.076,0c-.458-.4-.93-.792-1.4-1.185A27.01,27.01,0,0,1,1.38,7.566,5.173,5.173,0,0,1,0,4.179,4.344,4.344,0,0,1,1.1,1.211,3.744,3.744,0,0,1,3.888,0,4.877,4.877,0,0,1,6.861,1.45l.1.1c.029-.029.061-.061.1-.1A4.877,4.877,0,0,1,10.029,0a3.744,3.744,0,0,1,2.784,1.211,4.343,4.343,0,0,1,1.1,2.968,5.172,5.172,0,0,1-1.38,3.388A26.985,26.985,0,0,1,8.9,10.982Z"
                                      fill="#ec1300"/>
                            </svg>

                            My Likes
                            <span>
                                {{
                                    guest && guest.favorites ? guest.favorites.length : 0
                                }}
                            </span>
                        </a>
                        </div>

                        <a
                            class="ctt-categories__item ctt-categories__item--help"
                            @click.stop="restartTourForGuest('deck', guest.id)"
                            tabindex="0"
                            aria-label="Restart Tour"
                            role="button"
                        >
                            Help?
                        </a>
                    </template>
                </div>
            </div>

            <transition
                name="fade"
                mode="out-in"
                v-if="deckTab === 'caregiving_resources'"
                key="transition-cards"
            >
                <div class="grid-cont grid-cont--xl mt--200 resource-content-holder">
                    <h1>Caregiving Resources</h1>

                    <div class="resource-group grid-row grid-row--smg resource-row" v-for="(resources, topic) in deckResources">
                        <h3 v-if="topic !== ''">{{topic}}</h3>
                        <caregiving-resource
                            v-for="resource in resources"
                            :resource="resource"
                            :key="resource.id + '-' + deckTab"
                        ></caregiving-resource>
                    </div>
                </div>
            </transition>
            <transition name="fade" mode="out-in" v-else key="transition-deck">
                <div>
                    <template v-if="cards.length">
                        <deck-stack
                            v-if="deckView === 'stack' && cards.length"
                            :cards="cards"
                            :reset-card-index="resetCardIndex"
                        ></deck-stack>
                        <deck-grid v-else :cards="cards"></deck-grid>
                    </template>
                    <template v-else>
                        <div class="ctt-no--cards">
                            <h1>Your deck contains no cards in this category</h1>
                        </div>
                    </template>
                </div>
            </transition>

            <div class="grid-row grid-row--nog mb--200" v-show="showDeckCategories">
                <div class="mt--200 removed blocked--md" id="mobile-cats" v-show="showDeckCategories">
                    <div id="ctt-filter" class="ctt-filter">
                        <div class="ctt-filter__cont">
                            <div class="ctt-filter__label">Sort by</div>
                            <div class="ctt-sorting ctt-tour-sorting">
                                <button class="ctt-sorting__toggle" tabindex="0">
                                    {{ cardOrderMap[cardOrder] }}
                                </button>
                                <div class="ctt-sorting__dropdown">
                                    <a
                                        tabindex="0"
                                        @click="setCardOrder('most_favorited')"
                                        class="ctt-sorting__option"
                                        data-formelementid="CARE_TO_TALK-BTN-CLK-SORT_BY-MOST_LIKED"
                                        data-categoryid="caregiving"
                                        data-subcategoryid="care to talk"
                                    >
                                        <svg
                                            aria-hidden="true"
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="currentColor"
                                        >
                                            <path
                                                d="M15 7.11107L13.25 8.88885L8.00002 3.55551L2.74999 8.88888L1 7.11111L7.99998 0L15 7.11107Z"
                                            ></path>
                                            <path
                                                d="M15 14.2222L13.25 16L8.00002 10.6666L2.74999 16L1 14.2222L7.99998 7.11111L15 14.2222Z"
                                            ></path>
                                        </svg
                                        >
                                        Most Liked</a
                                    >
                                    <a
                                        tabindex="0"
                                        @click="setCardOrder('newest')"
                                        class="ctt-sorting__option"
                                        data-formelementid="CARE_TO_TALK-BTN-CLK-SORT_BY-NEWEST"
                                        data-categoryid="caregiving"
                                        data-subcategoryid="care to talk"
                                    >
                                        <svg
                                            aria-hidden="true"
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="currentColor"
                                        >
                                            <path
                                                d="M5.10526 16L12.2632 8.8421L8.47368 6.31579L10.1579 0L3 7.15789L6.78947 9.68421L5.10526 16Z"
                                            ></path>
                                        </svg
                                        >
                                        Newest Cards</a
                                    >
                                    <a
                                        tabindex="0"
                                        @click="setCardOrder('oldest')"
                                        class="ctt-sorting__option"
                                        data-formelementid="CARE_TO_TALK-BTN-CLK-SORT_BY-OLDEST"
                                        data-categoryid="caregiving"
                                        data-subcategoryid="care to talk"
                                    >
                                        <svg
                                            aria-hidden="true"
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="currentColor"
                                        >
                                            <path
                                                d="M14 3H13V1C13 0.734784 12.8946 0.48043 12.7071 0.292893C12.5196 0.105357 12.2652 0 12 0C11.7348 0 11.4804 0.105357 11.2929 0.292893C11.1054 0.48043 11 0.734784 11 1V3H5V1C5 0.734784 4.89464 0.48043 4.70711 0.292893C4.51957 0.105357 4.26522 0 4 0C3.73478 0 3.48043 0.105357 3.29289 0.292893C3.10536 0.48043 3 0.734784 3 1V3H2C1.46957 3 0.960859 3.21071 0.585786 3.58579C0.210714 3.96086 0 4.46957 0 5L0 14C0 14.5304 0.210714 15.0391 0.585786 15.4142C0.960859 15.7893 1.46957 16 2 16H14C14.5304 16 15.0391 15.7893 15.4142 15.4142C15.7893 15.0391 16 14.5304 16 14V5C16 4.46957 15.7893 3.96086 15.4142 3.58579C15.0391 3.21071 14.5304 3 14 3ZM2 14V7H14V14H2Z"
                                            ></path>
                                        </svg
                                        >
                                        Oldest Cards</a
                                    >
                                </div>
                            </div>
                            <div class="ctt-filter__label">Categories</div>
                            <div class="ctt-categories ctt-tour-categories-mobile">
                                <a
                                    v-if="!sharedDeckFrom"
                                    @click="filterFavorites"
                                    class="ctt-categories__item ctt-categories__item--fav"
                                    :class="{ active: this.onlyFavorites }"
                                    tabindex="0"
                                    aria-label="My favorites"
                                >
                                    My Likes
                                    <span>
                                        {{guest && guest.favorites ? guest.favorites.length : 0}}
                                    </span>
                                </a>
                                <a
                                    @click="setCategoryFilter('all')"
                                    class="ctt-categories__item ctt-categories__item--all"
                                    :class="{ active: categoryFilter === 'all' }"
                                    tabindex="0"
                                >
                                    All
                                </a>
                                <a
                                    v-for="category in deckCategories"
                                    :key="category"
                                    class="ctt-categories__item"
                                    :class="[
                                    { active: category === categoryFilter },
                                    `ctt-categories__item--${category.toLowerCase()}`
                                ]"
                                    @click="setCategoryFilter(category)"
                                    tabindex="0"
                                >{{ category }}</a
                                >
                            </div>
                            <button
                                tabindex="0"
                                class="mt--150 button button--blocked button--red"
                                @click="closeFilters"
                            >
                                Apply
                            </button>
                            <button
                                tabindex="0"
                                aria-label="Close"
                                class="ctt-filter__close"
                                @click="closeFilters"
                            ></button>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import {mapGetters} from 'vuex';
import collectorActions from '../../../mixins/collectorActions';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import Tour from '../../../mixins/Tour';

export default {
    name: 'deck-content',
    mixins: [collectorActions, Tour],
    mounted() {
    },
    data() {
        return {
            resetCardIndex: false,
            overlay: false,
            categoryFilter: 'all',
            onlyFavorites: false,
            cardOrder: 'newest',
            editBtnHover: false,
            cardOrderMap: {
                newest: 'Newest Cards',
                oldest: 'Oldest Cards',
                most_favorited: 'Most Liked',
                least_favorited: 'Least Favorited'
            }
        };
    },
    computed: {
        ...mapGetters({
            guest: 'guest',
            deckView: 'deckView',
            deckTab: 'deckTab',
            showSuggestionModal: 'showSuggestionModal',
            guestCards: 'guestCards',
            deckCards: 'deckCards',
            deckResources: 'deckResources',
            deckCategories: 'deckCategories',
            sharedDeckFrom: 'sharedDeckFrom'
        }),
        cards() {
            return this.deckTab === 'my_deck' ? this.guestCards : this.deckCards;
        },
        showDeckCategories() {
            return this.deckTab !== 'caregiving_resources';
        }
    },
    methods: {
        setCardOrder(order) {
            this.cardOrder = order;
            this.setCategoryFilter(this.categoryFilter);
        },
        setCategoryFilter(category, onlyFavs = false) {
            this.categoryFilter = category;
            if (this.onlyFavorites && !onlyFavs) {
                this.onlyFavorites = false;
            }
            this.$store.dispatch('setDeckCategory', {
                category: category,
                favorites: this.onlyFavorites,
                order: this.cardOrder,
                callback: () => {
                    this.resetCardIndex = !this.resetCardIndex;
                }
            })

            this.deckFilterBy(category, this.cardOrder, this.onlyFavorites);

            // document.getElementById('deck-sorting-dropdown').classList.toggle('active');
            // document.getElementById('deck-sorting-toggle').classList.toggle('active');
        },
        filterFavorites() {
            this.onlyFavorites = !this.onlyFavorites;
            this.categoryFilter = 'all';
            this.setCategoryFilter(this.categoryFilter, true);
        },
        openFilters() {
            document.getElementById('ctt-filter').classList.toggle('active');

            const filterCont = document.querySelector('.ctt-filter__cont');
            const content = document.querySelector('.ctt-content');

            filterCont.style.top = content.getBoundingClientRect().top + 'px';

            disablePageScroll();
        },
        closeFilters() {
            document.getElementById('ctt-filter').classList.remove('active');
            enablePageScroll();
        },
        goToMyDeckTab() {
            this.$store.commit('SET_DECK_TAB', 'my_deck');
        },
        switchTab(tabName) {
            this.switchView('grid');
            this.$store.commit('SET_DECK_TAB', tabName);
            window.location.hash = tabName;

            switch (tabName) {
                case 'my_deck':
                    this.deckMyDeck();
                    break;
                case 'customize':
                    this.deckCustomize();
                    break;
                case 'caregiving_resources':
                    this.deckCaregivingResources();
                    break;
                default:
                    this.deckMyDeck();
            }
        },
        switchView(viewType) {
            if (viewType === 'grid') {
                this.deckSelectGridView();
            } else {
                this.deckSelectStackView();
            }
            this.$store.commit('SET_DECK_VIEW', viewType);
        },
    },
    watch: {
        deckTab: {
            handler(val) {
                if (val === 'my_deck') {
                    this.cardOrder = 'newest';
                    this.setCategoryFilter('all', false);
                }
            }
        }
    }
};
</script>

<style lang="scss">
.p-width--100 {
    width: 100%;
}

#mobile-cats {
    position: fixed;
    bottom: 10px;
    left: 20px;
    right: 20px;
    z-index: 9;
}

.ctt-no--cards {
    height: 100%;
    display: flex;
    justify-content: center;
    min-height: calc(100vh - 299px);
    align-items: center;

    h1 {
        color: #666;
        text-align: center;
    }
}

.center {
    margin: auto;
    width: 50%;
    border: 3px solid green;
    padding: 10px;
}

.vertical-container {
    position: relative;
    height: 359.594px;
}

.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    right: 6%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.caretotalk-cards-title {
    color: #e51c30;
    font-size: 35px;
    text-align: center;
}

.caretotalk-cards-subtitle {
    font-size: 25px;
    text-align: center;
}

#care-to-talk-cards-image {
    max-width: 400px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.cards-image-header-container {
    cursor: pointer;
}
</style>
