export default {
    methods: {
        bookmarkAction(guest, content, pathway_id) {
            if (guest.resource_ids.includes(content.id)) {
                this.removeBookmark(content, pathway_id);
            } else {
                this.bookmark(content, pathway_id);
            }
        },
        bookmark(content, pathway_id) {
            this.$store.dispatch('bookmarkContent', content);
            this.bookmarkContentAction(content, pathway_id);
        },
        removeBookmark(content, pathway_id) {
            this.$store.dispatch('removeContentBookmark', content);
            this.discardBookmarkAction(content, pathway_id);
        }
    }
};
