import Form from '../Form';

class SuggestionForm extends Form {
    constructor(data) {
        if (!data) {
            data = {
                content: '',
                suggestable_type: '',
                approved_by: null,
                card_category: null
            };
            super(data);
        } else {
            data.card_category = data.card_category;

            super(data);
        }
    }

    isCard() {
        return this.suggestable_type === 'App\\Models\\Content';
    }
}

export default SuggestionForm;
