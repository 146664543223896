class ErrorBag {
    constructor(errors = {}) {
        this.setErrors(errors);
    }

    hasErrors() {
        return !!this.keys.length;
    }

    get keys() {
        return Object.keys(this.errors);
    }

    hasError(key) {
        return this.keys.indexOf(key) > -1;
    }

    firstKey() {
        return this.keys[0];
    }

    first(key) {
        if (this.errors && this.errors[key]) {
            return typeof this.errors[key] == 'string' ? this.errors[key] : this.errors[key][0];
        }

        return '';
    }

    setErrors(errors) {
        this.errors = errors;
    }

    clearAll() {
        this.setErrors({});
    }

    clear(key) {
        return delete this.errors[key];
    }
    containsAnyOf(keys) {
        for (let i = 0; i < keys.length; i++) {
            if (this.errors[keys[i]]) {
                return true;
            }
        }

        return false;
    }
}

export default ErrorBag;
