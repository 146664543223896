<template>
    <v-row class="justify-content-center">
        <v-col sm="12" class="text-center pt-0">
            <youtube
                ref="youtube"
                :video-id="videoId"
                :player-width="content.settings.width"
                :player-height="content.settings.height"
                @ready="videoReady"
                @cued="videoReady"
                :key="content.url"
            ></youtube>
        </v-col>
    </v-row>
</template>
<script>
import { getIdFromURL } from 'vue-youtube-embed';
export default {
    name: 'youtube-preview',
    props: ['content'],
    created() {
        this.videoId = getIdFromURL(this.content.url);
    },
    data() {
        return {
            videoId: '',
            player: false
        };
    },
    methods: {
        videoReady(event) {
            this.player = event.target;

            setTimeout(() => {
                this.$emit('metadata', {
                    video_duration: this.player.getDuration()
                });
            }, 400);
        }
    },
    watch: {
        'content.url': function(newVal, oldVal) {
            this.videoId = getIdFromURL(newVal);
        }
    }
};
</script>

<style lang="scss">
iframe {
    max-width: 100%;
}
</style>
