<template>
    <div class="rfeed-article">
        <a :href="`/content/${content.slug}`" class="rfeed-article__link"></a>
        <div class="rfeed-article__head">
            <div class="rfeed-article__badge">{{ content.content_type.name }}</div>
            <div class="rfeed-article__date">{{ renderFormDate(content.created_at) }}</div>

            <template v-if="content.content_type.name === 'Video'">
                <div class="rfeed-article__bull"></div>
                <div class="rfeed-article__date">
                    {{ videoDuration(content.settings.video_duration) }}
                </div>
            </template>
        </div>
        <h4 class="rfeed-article__title" v-html="content.title_html"></h4>
        <p class="rfeed-article__subtitle" v-html="content.description_html"></p>
        <div class="rfeed-article__figure">
            <img
                :src="content.thumbnail_sm"
                width="606"
                height="270"
                alt=""
                class="rfeed-article__image"
            />
        </div>
        <div class="rfeed-article__actions">
            <button
                type="button"
                class="rfeed-article__action rfeed-article__action--book"
                :class="{ active: guest.resource_ids.includes(content.id) }"
                @click="bookmarkAction(guest, content)"
                v-tooltip="guest.resource_ids.includes(content.id) ? 'Discard' : 'Save'"
            ></button>

            <button
                type="button"
                v-tooltip="`Discard content`"
                class="rfeed-article__action rfeed-article__action--discard"
                style="margin-left: 0"
                @click="discardContent(content)"
            ></button>

            <v-popover popoverClass="share-popover" placement="top">
                <button
                    type="button"
                    v-tooltip="`Share`"
                    class="rfeed-article__action rfeed-article__action--share"
                ></button>

                <template slot="popover">
                    <share :content="content" :url="shareUrl"></share>
                    <a v-close-popover class="close-popover">
                        <svg
                            height="20px"
                            viewBox="0 0 512 512"
                            width="20px"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill="#666"
                                d="m256 512c-141.160156 0-256-114.839844-256-256s114.839844-256 256-256 256 114.839844 256 256-114.839844 256-256 256zm0-475.429688c-120.992188 0-219.429688 98.4375-219.429688 219.429688s98.4375 219.429688 219.429688 219.429688 219.429688-98.4375 219.429688-219.429688-98.4375-219.429688-219.429688-219.429688zm0 0"
                            />
                            <path
                                fill="#666"
                                d="m347.429688 365.714844c-4.679688 0-9.359376-1.785156-12.929688-5.359375l-182.855469-182.855469c-7.144531-7.144531-7.144531-18.714844 0-25.855469 7.140625-7.140625 18.714844-7.144531 25.855469 0l182.855469 182.855469c7.144531 7.144531 7.144531 18.714844 0 25.855469-3.570313 3.574219-8.246094 5.359375-12.925781 5.359375zm0 0"
                            />
                            <path
                                fill="#666"
                                d="m164.570312 365.714844c-4.679687 0-9.355468-1.785156-12.925781-5.359375-7.144531-7.140625-7.144531-18.714844 0-25.855469l182.855469-182.855469c7.144531-7.144531 18.714844-7.144531 25.855469 0 7.140625 7.140625 7.144531 18.714844 0 25.855469l-182.855469 182.855469c-3.570312 3.574219-8.25 5.359375-12.929688 5.359375zm0 0"
                            />
                        </svg>
                    </a>
                </template>
            </v-popover>

            <button
                type="button"
                style="display:none;"
                class="rfeed-article__action rfeed-article__action--more hidden"
            ></button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Time from '../../../mixins/Time';
import collectorActions from '../../../mixins/collectorActions';
import bookmarkActions from '../../../mixins/bookmarkActions';
import discardActions from '../../../mixins/discardActions';

export default {
    name: 'content-card',
    props: ['content'],
    mixins: [Time, collectorActions, bookmarkActions, discardActions],
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            guest: 'guest'
        }),
        shareUrl() {
            return window.location.origin + '/content/' + this.content.slug;
        }
    },
    methods: {},
    watch: {}
};
</script>

<style lang="scss">
.rfeed-article__action--book.active {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="%231097E2" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"><path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path></svg>');
}
</style>
