<template>
    <div class="onboard-quiz__cont">
        <h4 class="interest-header">Are you interested in this card?</h4>

        <div class="onboard-interest">
            <div
                class="onboard-interest__item"
                :class="{
                    active: currentIndex === index,
                    discard: discardedIndexes.includes(index),
                    saved: savedIndexes.includes(index)
                }"
                v-for="(content, index) in multiContent"
            >
                <div class="ctt-card">
                    <div :class="`ctt-card__category ctt-card__category--${cssCategory(content)}`">
                        {{ content.settings.category }}
                    </div>
                    <div class="ctt-card__cont">
                        <h3 class="h2--sm">{{ content.title }}</h3>
                    </div>
                </div>
            </div>

            <div class="grid-row grid-row--smg grid-row--aic grid-row--jcc interest-buttons">
                <div class="grid-col">
                    <button
                        type="button"
                        class="button onboard-interest__action onboard-interest__action--no"
                        @click="contentDiscarded"
                        data-formelementid="CARE_TO_TALK-BTN-CLK-DISCARD_CARD"
                        data-categoryid="caregiving"
                        data-subcategoryid="care to talk"
                    >
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            stroke-width="2.4"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        >
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                        <span>Discard</span>
                    </button>
                </div>
                <div class="grid-col">
                    <button
                        type="button"
                        class="button onboard-interest__action onboard-interest__action--yes"
                        @click="contentSaved"
                        data-formelementid="CARE_TO_TALK-BTN-CLK-SAVE_CARD"
                        data-categoryid="caregiving"
                        data-subcategoryid="care to talk"
                    >
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            stroke-width="2.4"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        >
                            <polyline points="20 6 9 17 4 12"></polyline>
                        </svg>
                        <span>Save</span>
                    </button>
                </div>
            </div>
        </div>

        <div class="mt--250 onboard-hotkeys" v-if="!isMobile">
            Keyboard: <span class="onboard-hotkeys__button">Y</span> for Save,
            <span class="onboard-hotkeys__button">N</span> for Discard.
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import collectorActions from '../../../mixins/collectorActions';

export default {
    props: ['revertChoice'],
    mixins: [collectorActions],
    data() {
        return {
            currentIndex: 0,
            savedIndexes: [],
            discardedIndexes: [],
            multiContent: []
        };
    },
    mounted() {
        this.multiContent.splice(0, this.multiContent.length, ...this.node.multi_content_data);
    },
    created() {
        window.addEventListener('keypress', this.selectContent);
    },
    destroyed() {
        window.removeEventListener('keypress', this.selectContent);
    },
    methods: {
        contentDiscarded() {
            this.discardedIndexes.push(this.currentIndex);

            this.$store.dispatch('removeContentBookmark', this.multiContent[this.currentIndex]);
            this.discardBookmarkAction(this.multiContent[this.currentIndex], this.pathway.id);

            if (this.currentContentIsLast) {
                setTimeout(() => {
                    this.$emit('loadNext');
                }, 200);
            }

            this.currentIndex++;
        },
        contentSaved() {
            this.savedIndexes.push(this.currentIndex);

            this.$store.dispatch('bookmarkContent', this.multiContent[this.currentIndex]);
            this.bookmarkContentAction(this.multiContent[this.currentIndex], this.pathway.id);

            if (this.currentContentIsLast) {
                setTimeout(() => {
                    this.$emit('loadNext');
                }, 200);
            }

            this.currentIndex++;
        },
        selectContent(event) {
            if (event.keyCode === 110) {
                this.contentDiscarded();
            }

            if (event.keyCode === 121) {
                this.contentSaved();
            }
        },
        deleteChoice() {
            this.currentIndex--;
            this.savedIndexes.splice(this.currentIndex, 1);
            this.discardedIndexes.splice(this.currentIndex, 1);

            this.$store.dispatch('removeContentBookmark', this.multiContent[this.currentIndex]);
        },
        cssCategory(content) {
            return content.settings.category.toLowerCase();
        }
    },
    computed: {
        ...mapGetters({
            pathway: 'pathway',
            guest: 'guest',
            node: 'node',
            content: 'content'
        }),
        currentContentIsLast() {
            return this.currentIndex + 1 >= this.multiContent.length;
        }
    },
    watch: {
        revertChoice: {
            handler() {
                if (this.revertChoice && this.currentIndex > 0) {
                    this.deleteChoice();
                    this.$emit('updateRevertChoice', false);
                } else if (this.revertChoice && this.currentIndex === 0) {
                    this.$emit('updateRevertChoice', false);
                    this.$emit('backStep');
                }
            },
            immediate: true
        }
    }
};
</script>

<style lang="scss" scoped>
.interest-buttons {
    margin-top: 50px;
}

.interest-header {
    font-size: 26px;
    font-weight: 700;
    padding-bottom: 35px;
}

.onboard-interest__item {
    border-radius: 10px;
}
.ctt-card__cont {
    min-height: 270px;
}

@media screen and (max-width: 767.97px) {
    .ctt-card__cont {
        min-height: 150px;
    }

    .onboard-interest {
        margin-top: 25px;
        padding: 0 10px;
    }

    .onboard-interest__action {
        margin-top: 25px;
        font-size: 14px;
    }

    .onboard-interest__item.active ~ .onboard-interest__item {
        padding: 0 10px;
    }
}
</style>
