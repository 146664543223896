<template>
    <div class="onboard-footer onboard-quiz__footer">
        <a @click="modal = true" class="onboard-footer__link" rel="nofollow" href="#"
            >Why am I answering these questions
            <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
            >
                <circle cx="12" cy="12" r="10"></circle>
                <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                <line x1="12" y1="17" x2="12.01" y2="17"></line></svg
        ></a>

        <div class="ctt-modal fixed" v-if="modal">
            <div class="grid-row grid-row--aic grid-row--jcb">
                <div class="grid-col">
                    <h4 class="ta--left">Why am I answering these questions</h4>
                </div>
                <div class="grid-col grid-col--auto">
                    <button @click="modal = false" class="ctt-modal__close"></button>
                </div>
            </div>
            <div class="mt--125 ctt-modal__cont">
                <div class="ctt-modal__pane active">
                    <div class="p p--sm ctt-modal__leading ta--left">
                        The answers that you provide to these questions are used to generate a
                        starter deck of Care To Talk cards.
                    </div>
                </div>
            </div>
            <div class="mt--125 grid-row grid-row--aic grid-row--jcb">
                <div class="grid-col grid-col--auto">
                    <button
                        @click="modal = false"
                        type="button"
                        class="button button--sm button--ghost"
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
        <div id="overlay" v-if="modal"></div>
    </div>
</template>
<script>
import collectorActions from '../../../mixins/collectorActions';

export default {
    name: 'pathway-nodes-footer',
    props: [],
    mixins: [collectorActions],
    data() {
        return {
            modal: false
        };
    },
    computed: {},
    methods: {},
    watch: {}
};
</script>

<style lang="scss">
.ctt-modal.fixed {
    position: fixed;
}
</style>
