<template>
    <div class="resource grid-col">
        <div class="resource__head">
            <h1 class="h2 resource__title" v-html="content.title"></h1>
            <p class="mt--050 p p--lg resource__subtitle" v-html="content.description"></p>

            <div class="grid-row grid-row--smg grid-row--aic grid-row--jcc">
                <div class="mt--100 grid-col grid-col--auto">
                    <div class="resource__meta">
                        <span>AARP</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="grid-row grid-row--nog grid-row--aic grid-row--jcc mt--250" v-if="pathway.id">
            <div class="grid-col grid-col--auto">
                <button type="button" class="button button--xs" @click="$emit('loadNext')">
                    <span>Next</span>
                    <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2.4"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    >
                        <line x1="19" y1="12" x2="5" y2="12"></line>
                        <polyline
                            points="11 18 5 12 11 6"
                            style="transform: rotate(180deg); transform-origin: 50% 50%;"
                        ></polyline>
                    </svg>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import collectorActions from '../../../mixins/collectorActions';
import Time from '../../../mixins/Time';

export default {
    props: ['revertChoice'],
    mixins: [collectorActions, Time],
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            pathway: 'pathway',
            guest: 'guest',
            node: 'node',
            content: 'content'
        })
    },
    watch: {
        revertChoice: {
            handler() {
                if (this.revertChoice) {
                    this.$emit('updateRevertChoice', false);
                    this.$emit('backStep');
                }
            },
            immediate: true
        }
    }
};
</script>
