import Vue from 'vue';

Vue.filter('pluralize', (message, count) => {
    return `${message}${count !== 1 ? 's' : ''}`;
});

Vue.filter('capitalize', function(value) {
    if (!value) return '';
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter('implode', data => {
    return data !== undefined && data.length ? data.join(', ') : '';
});

Vue.filter('implodeTopics', data => {
    return data !== undefined && data.length
        ? data
              .map(elem => {
                  return elem.name;
              })
              .join(', ')
        : '';
});
