<template>
    <div class="onboard-questionnaire">
        <div class="onboard-questionnaire__item active">
            <div class="message-holder">
                <h3>Your AARP Care to Talk?&trade; Card Deck</h3>

                <p>
                    You will now be taken to a set of 10 cards selected for you (based on your answers and selections).
                    <br><br>
                    You can edit your deck to add cards that fit your goals and remove cards that don’t interest you.
                </p>

                <button class="aarp-red-btn" @click="finishIntro">Continue</button>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import collectorActions from '../../../mixins/collectorActions';

export default {
    name: 'PathwayIntroEndMessage',
    mixins: [collectorActions],
    methods: {
        finishIntro() {
            axios.post('/guest/api/force_login').then(res => {
                window.location = '/';
            });
        }
    },
    computed: {
        ...mapGetters({
            pathway: 'pathway',
            guest: 'guest',
            node: 'node',
            content: 'content',
            latestAnswer: 'latestAnswer'
        }),
    },
};
</script>
<style lang="scss">
.onboard-questionnaire__item {
    &:before {
        content: none;
    }
}

.message-holder {
    padding: 20px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;

    h3 {
        font-size: 18px;
        color: #676767;
        margin-bottom: 30px;
        font-weight: 700;
    }

    p {
        text-align: center;
        font-size: 1.5rem;
        color: #37383D;
        font-weight: 700;
        margin-bottom: 50px;
    }
}
</style>
