<template>
    <div>
        <form class="header__search" @submit.prevent>
            <div class="header__search--cont">
                <input
                    type="text"
                    placeholder="Search articles, news, videos, etc."
                    class="header__input"
                    v-model="searchText"
                    @input="getDataFromApi"
                    :keydown.enter.prevent="getDataFromApi"
                />

                <transition name="slide">
                    <div class="header--search__results" v-if="showSearchResults">
                        <ul>
                            <li class="result--search">
                                <div class="grid-row grid-row--nog grid-row--aic">
                                    <div class="grid-col">
                                        <a :href="`/search?search_text=${searchText}`">
                                            <p class="result--title">{{ searchText }}</p>
                                        </a>
                                    </div>
                                    <div class="grid-col grid-col--auto">
                                        <button
                                            type="button"
                                            @click="openSearch"
                                            class="button button--xxs button--rounded"
                                        >
                                            <span style="font-weight: 100">Search site</span>
                                            <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                stroke-width="2.4"
                                                fill="none"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            >
                                                <line x1="5" y1="12" x2="19" y2="12"></line>
                                                <polyline points="13 6 19 12 13 18"></polyline>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </li>
                            <li
                                :class="`result--${content.content_type.name.toLowerCase()}`"
                                v-for="content in searchResults"
                                :key="`search-result-${content.id}`"
                            >
                                <div class="grid-row grid-row--nog grid-row--aic">
                                    <div class="grid-col">
                                        <a :href="`/content/${content.slug}`">
                                            <p class="result--title">{{ content.title }}</p>
                                            <p class="result--description">
                                                {{ content.short_description }}
                                            </p>
                                        </a>
                                    </div>
                                    <div class="grid-col grid-col--auto">
                                        <button
                                            type="button"
                                            @click="openContent(content)"
                                            class="button button--xxs button--rounded"
                                        >
                                            <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                stroke-width="2.4"
                                                fill="none"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            >
                                                <line x1="5" y1="12" x2="19" y2="12"></line>
                                                <polyline points="13 6 19 12 13 18"></polyline>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </transition>
            </div>
        </form>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import collectorActions from '../../mixins/collectorActions';
export default {
    name: 'search',
    props: [],
    mixins: [collectorActions],
    data() {
        return {
            showSearchResults: false,
            searchResults: [],
            searchText: '',
            limit: 6
        };
    },
    computed: {
        ...mapGetters({
            guest: 'guest'
        })
    },
    methods: {
        getDataFromApi: _.debounce(function() {
            this.searchResults = [];
            let endpoint = '/search/json';
            let queries = '?limit=' + this.limit + '&search_text=' + this.searchText;

            if (this.searchText.length) {
                axios
                    .get(endpoint + queries)
                    .then(response => {
                        this.searchAction(this.searchText, response.data);

                        this.searchResults = response.data;
                        this.showSearchResults = true;
                    })
                    .catch(function(error) {
                        console.error(error);
                    });
            } else {
                this.searchResults = [];
                this.showSearchResults = false;
            }
        }, 500),
        openSearch() {
            return (window.location = '/search?search_text=' + this.searchText);
        },
        openContent(content) {
            return (window.location = '/content/' + content.slug);
        }
    }
};
</script>

<style lang="scss" scoped>
.slide-enter-active {
    -moz-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -moz-transition-timing-function: ease-in;
    -webkit-transition-timing-function: ease-in;
    -o-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
}

.slide-leave-active {
    -moz-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
    max-height: 100px;
    overflow: hidden;
}

.slide-enter,
.slide-leave-to {
    overflow: hidden;
    max-height: 0;
}
</style>
