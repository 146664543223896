<template>
    <div>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <v-snackbar color="success" top v-model="snackbar" :timeout="1500">
            Content updated!
            <v-icon dark>check_circle</v-icon>
        </v-snackbar>

        <v-snackbar color="error" top v-model="snackbarError" :timeout="4000">
            There was an error updating the content!
            <br />
            {{ form.errors.errors }}
            <v-icon dark>highlight_off</v-icon>
        </v-snackbar>

        <v-col md="5" offset-md="1">
            <v-alert type="error" v-if="form.errors.first('general')">
                {{ form.errors.first('general') }}
            </v-alert>
            <v-alert type="error" v-if="form.errors.first('thumbnail')">
                {{ form.errors.first('thumbnail') }}
            </v-alert>
        </v-col>
        <v-row>
            <v-col class="col-6">
                <v-form ref="form" v-model="valid">
                    <v-row no-gutters>
                        <small style="color: grey" v-show="contentExists"
                            >Content type can not be changed</small
                        >
                        <v-col cols="12" dusk="contentTypeSelect" class="content-type-select">
                            <v-btn-toggle
                                v-model="form.content_type.name"
                                aria-required="true"
                                group
                                @change="resetSettingsToContentTypeDefault"
                            >
                                <v-btn
                                    color="secondary"
                                    :disabled="contentExists"
                                    class="my-0"
                                    tile
                                    outlined
                                    :dusk="`type-${type}`"
                                    :value="type"
                                    v-for="type in types"
                                    :key="type"
                                    style="padding: 0 2px; margin: 4px 0"
                                >
                                    {{ type }}
                                </v-btn>
                            </v-btn-toggle>
                        </v-col>
                    </v-row>

                    <v-row>
                        <div class="toggle-group">
                            <span>Featured</span>
                            <v-switch v-model="form.featured"></v-switch>
                        </div>
                    </v-row>

                    <v-row>
                        <v-col class="col-12">
                            <v-textarea
                                required
                                hide-details="auto"
                                label="Title"
                                dusk="titleInput"
                                placeholder="Enter the title of the content"
                                v-model="form.title"
                                :rules="[v => !!v || 'Please enter a title']"
                                :error-messages="form.errors.first('title')"
                                outlined
                            ></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <!--                            <tiptap-editor-->
                            <!--                                v-if="form.isArticle()"-->
                            <!--                                :options="options"-->
                            <!--                                v-model="form.description"-->
                            <!--                                placeholder=""-->
                            <!--                            />-->
                            <v-textarea
                                hide-details="auto"
                                label="Description"
                                placeholder="Add description"
                                dusk="descriptionInput"
                                v-model="form.description"
                                :rules="
                                    form.isCard() ? [] : [v => !!v || 'Please enter a description']
                                "
                                :error-messages="form.errors.first('description')"
                                outlined
                            ></v-textarea>
                        </v-col>
                    </v-row>

                    <v-row v-if="form.isQuestionSet()">
                        <v-col>
                            <v-textarea
                                hide-details="auto"
                                label="Preface"
                                placeholder="Write the preface"
                                dusk="prefaceInput"
                                v-model="form.preface"
                                :rules="[v => !!v || 'Please enter a preface']"
                                :error-messages="form.errors.first('preface')"
                                outlined
                            ></v-textarea>
                        </v-col>
                    </v-row>

                    <v-row v-if="form.isIframe()">
                        <v-col cols="12" sm="6">
                            <v-radio-group v-model="iframeType">
                                <v-radio
                                    label="Use external Iframe URL"
                                    dusk="urlRadio"
                                    value="url"
                                ></v-radio>

                                <v-radio
                                    label="Upload Iframe package"
                                    value="package"
                                    dusk="packageRadio"
                                ></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" v-if="form.isLink()">
                            <v-text-field
                                label="External URL"
                                dusk="urlInput"
                                :placeholder="`Enter External URL`"
                                v-model="form.url"
                                :error-messages="form.errors.first('url')"
                                outlined
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" v-if="form.isVideo() || iframeType === 'url'">
                            <v-text-field
                                label="URL"
                                dusk="urlInput"
                                :placeholder="`Enter ${form.content_type.name} URL`"
                                v-model="form.url"
                                :error-messages="form.errors.first('url')"
                                outlined
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" v-if="form.isIframe() && iframeType === 'package'">
                            <v-file-input
                                label="Upload package"
                                accept=".zip,.rar,.7zip"
                                v-model="form.path"
                                dusk="packageInput"
                                persistent-hint
                                :hint="
                                    'Only zip, rar and 7zip file types are allowed. Zip file MUST contain any of the following files: ' +
                                        zipFileNames.join(', ')
                                "
                                :error-messages="form.errors.first('path')"
                                truncate-length="15"
                            ></v-file-input>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <topic-dropdown
                                v-model="form.topics"
                                :all-topics-data="allTopicsData"
                            ></topic-dropdown>
                        </v-col>
                    </v-row>

                    <v-divider></v-divider>

                    <v-row no-gutters class="my-8">
                        <h4>Settings</h4>
                    </v-row>

                    <v-row no-gutters v-if="form.isCard()">
                        <v-col cols="12">
                            <v-select
                                v-model="form.settings['category']"
                                :items="cardTypes"
                                label="Category"
                            ></v-select>
                        </v-col>
                    </v-row>

                    <v-row no-gutters>
                        <v-col cols="12" v-if="form.isIframe()">
                            <v-checkbox
                                label="Load Iframe in full width/height"
                                v-model="fullIframe"
                                @change="updateIframeSettings"
                            ></v-checkbox>
                        </v-col>
                    </v-row>

                    <v-row no-gutters>
                        <v-col
                            cols="12"
                            v-for="(value, name) in form.settings"
                            :key="name"
                            v-if="name !== `video_duration`"
                        >
                            <v-text-field
                                v-if="['width', 'height'].includes(name)"
                                :label="form.content_type.name + ' ' + name"
                                placeholder="Enter value"
                                v-model="form.settings[name]"
                                :rules="form.settings[name] !== '100%' ? dimensionRules : []"
                                outlined
                                :dusk="`${name}Input`"
                                :type="form.settings[name] === '100%' ? 'text' : 'number'"
                                :error-messages="form.errors.first('settings.' + name)"
                            ></v-text-field>
                            <v-checkbox
                                v-else-if="name === 'allow_next'"
                                label="Show next button on pathway to skip content"
                                v-model="form.settings[name]"
                                :error-messages="form.errors.first('settings.' + name)"
                            ></v-checkbox>
                        </v-col>
                    </v-row>

                    <v-row v-if="!form.isCard()">
                        <v-col cols="12">
                            <v-file-input
                                label="Thumbnail"
                                accept="image/*"
                                v-model="form.thumbnail"
                                show-size
                                truncate-length="15"
                                :error-messages="form.errors.first('thumbnail')"
                                hint="Max upload size 4.5MB"
                                persistent-hint
                            ></v-file-input>
                        </v-col>

                        <v-col class="col-auto" v-if="thumbnail">
                            <v-img id="thumbnail-image" :src="thumbnail"></v-img>
                        </v-col>
                        <v-col class="col-auto" v-if="thumbnail">
                            <v-btn color="error" depressed @click="removeThumbnail">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <save-button @click="submit" dusk="submitButton"></save-button>
                        </v-col>
                    </v-row>
                </v-form>
            </v-col>

            <v-col cols="6" class="preview-holder">
                <h3 class="preview" v-if="form.isIframe() || form.isVideo()">Preview</h3>

                <iframe-preview v-if="form.isIframe() && form.url" :content="form"></iframe-preview>

                <youtube-preview
                    @metadata="storeMetadata"
                    v-if="form.isVideo() && form.url"
                    :content="form"
                ></youtube-preview>

                <question-set
                    v-if="form.isQuestionSet()"
                    ref="questionSet"
                    :content="form"
                    :disabled-message="disabledMessage"
                    :all-topics-data="allTopicsData"
                ></question-set>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import ContentForm from '../../../classes/Form/ContentForm';
import Time from '../../../mixins/Time';
import TopicDropdown from '../topic/TopicDropdown';
import YoutubePreview from './YoutubePreview';
import IframePreview from './IframePreview';
import SaveButton from '../buttons/SaveButton';
// import { TiptapEditor } from 'tiptap-editor/src';

export default {
    name: 'content-editor',
    components: { SaveButton, YoutubePreview, TopicDropdown, IframePreview },
    props: [
        'existingContent',
        'types',
        'modalForm',
        'settings',
        'disabledMessage',
        'zipFileNames',
        'allTopicsData',
        'cardTypes'
    ],
    mixins: [Time],
    created() {
        if (this.existingContent) {
            this.form = new ContentForm(this.existingContent);

            if (this.form.isIframe()) {
                this.iframeType = this.form.path ? 'package' : 'url';
            }

            this.thumbnail = this.form.thumbnail_sm;
            this.form.thumbnail = null;
            this.form.path = null;
            this.fullIframe =
                this.form.settings.width === '100%' && this.form.settings.height === '100%';
        }
    },
    data() {
        return {
            thumbnail: false,
            iframeType: false,
            fullIframe: false,
            form: new ContentForm(),
            snackbar: false,
            snackbarError: false,
            overlay: false,
            valid: false,
            videoId: false,
            dimensionRules: [
                v => v > 0 || 'This field must be a positive number',
                v => v <= 1200 || 'Max allowed dimension is 1200'
            ],
            options: {
                content: '',
                editable: true,
                fileSelectorUrl: 'moderator/webinarImages/images',
                fileUploadUrl: 'moderator/webinarImages/store',
                toolbar: [
                    'bold',
                    'italic',
                    'underline',
                    'font-family',
                    'strike',
                    'heading',
                    'fore-color',
                    'back-color',
                    'format_clear',
                    'align-dropdown',
                    'indent',
                    'outdent',
                    'line-height',
                    'horizontal',
                    'bullet_list',
                    'ordered_list',
                    'blockquote',
                    'link',
                    'undo',
                    'redo',
                    'photo',
                    'table'
                ]
            }
        };
    },
    methods: {
        submit() {
            let valid = this.$refs.form.validate();
            let childValid = true;

            if (this.form.isQuestionSet()) {
                childValid = this.validateQuestionSet();
            }

            if (valid && childValid) {
                this.overlay = true;
                if (this.existingContent) {
                    this.form
                        .put('/admin/content/' + this.form.id)
                        .then(response => {
                            this.snackbar = true;
                            this.overlay = false;

                            this.form = new ContentForm(response.content);

                            this.thumbnail = this.form.thumbnail_sm;
                            this.form.thumbnail = null;
                            this.form.path = null;

                            this.$store.dispatch('updateContentList', response.content);
                        })
                        .catch(e => {
                            this.overlay = false;
                            this.snackbarError = true;
                        });
                } else {
                    this.form
                        .post('/admin/content')
                        .then(response => {
                            this.$store.dispatch('updateContentList', response.content);

                            if (this.modalForm) {
                                this.$emit('closeModal');
                            } else {
                                window.location = '/admin/content';
                            }
                        })
                        .catch(e => {
                            this.overlay = false;
                            this.snackbarError = true;
                        });
                }
            } else {
                this.snackbarError = true;
            }
        },
        storeMetadata(metadata) {
            this.form.settings.video_duration = metadata.video_duration;
        },
        validateQuestionSet() {
            let valid = true;

            Object.keys(this.$refs.questionSet.$refs).forEach(element => {
                if (element.includes('questionEditor#')) {
                    let form = this.$refs.questionSet.$refs[element][0].$refs.questionSetForm;

                    if (typeof form !== 'undefined') {
                        let questionValid = form.validate();
                        if (!questionValid) {
                            valid = false;
                        }
                    }
                }
            });

            return valid;
        },
        removeThumbnail() {
            if (confirm('Remove thumbnail?')) {
                axios.delete('/admin/content/' + this.form.id + '/deleteThumbnail').then(res => {
                    this.thumbnail = null;
                });
            }
        },
        updateIframeSettings() {
            if (this.fullIframe) {
                this.form.settings.width = '100%';
                this.form.settings.height = '100%';

                this.dimensionRules = [];
            } else {
                this.form.settings.width = '700';
                this.form.settings.height = '400';

                this.dimensionRules = [
                    v => v > 0 || 'This field must be a positive number',
                    v => v <= 1200 || 'Max allowed dimension is 1200'
                ];
            }
        },
        resetSettingsToContentTypeDefault(newValue) {
            this.form.settings =
                this.settings[newValue] !== undefined ? this.settings[newValue] : [];
        }
    },
    computed: {
        contentExists() {
            return _.isObject(this.existingContent);
        }
    },
    watch: {
        // 'form.content_type.name': function(newVal, oldVal) {
        //     this.form.settings = this.settings[newVal] !== undefined ? this.settings[newVal] : [];
        //     console.log('form.content_type.name runs',newVal,this.form.settings)
        // }
    }
};
</script>

<style lang="scss">
#thumbnail-image {
    max-width: 400px;
}

.preview {
    background: #e2f0f9;
    color: #32799a;
    margin-bottom: 20px;
    padding: 5px;
    text-align: center;
}
</style>
