import Form from '../Form';

class PathwayForm extends Form {
    constructor(data) {
        if (!data) {
            data = {
                name: '',
                slug: '',
                active: false,
                description: '',
                thumbnail: '',
                logo: '',
                topics: [],
                settings: {
                    faq_tab: false,
                    faq_url: '',
                    resources_tab: false
                }
            };
        }

        if (!data.settings) {
            data.settings = {
                faq_tab: false,
                faq_url: '',
                resources_tab: false
            };
        }

        if (!data.topics) {
            data.topics = [];
        }

        super(data);
    }
}

export default PathwayForm;
