<template>
    <v-col cols="auto">
        <a class="admin-table__delete" @click="$emit('click', $event)">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
            >
                <path
                    d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
                />
            </svg>
            Delete
        </a>
    </v-col>
</template>
<script>
export default {
    name: 'delete-button'
};
</script>
