export default {
    methods: {
        discardContent(content) {
            if (confirm('Are you sure you are not interested in this resource?')) {
                this.$store.dispatch('discardContent', content);
                this.discardFeedContentAction(content);
            }
        }
    }
};
