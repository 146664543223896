<template>
    <div>
        <div class="admin-header">
            <v-row>
                <div class="col-auto">
                    <back-button url="/admin/content"></back-button>
                </div>
                <div class="display-1 col-auto">
                    {{ existingContent ? 'Edit' : 'Create' }} content
                </div>
                <v-spacer></v-spacer>
                <div class="grid-col grid-col--auto d-flex align-center">
                    <save-button @click="$refs.contentEditor.submit()"></save-button>
                </div>
                <div class="grid-col grid-col--auto d-flex align-center" v-if="existingContent">
                    <v-btn color="grey darken-3" depressed @click="deleteContent()">
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            stroke="#fff"
                            stroke-width="2.4"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        >
                            <polyline points="3 6 5 6 21 6"></polyline>
                            <path
                                d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                            ></path>
                            <line x1="10" y1="11" x2="10" y2="17"></line>
                            <line x1="14" y1="11" x2="14" y2="17"></line>
                        </svg>
                    </v-btn>
                </div>
            </v-row>
        </div>

        <content-editor
            ref="contentEditor"
            :existing-content="existingContent"
            :types="types"
            :zip-file-names="zipFileNames"
            :settings="settings"
            :disabled-message="disabledMessage"
            :all-topics-data="allTopicsData"
            :card-types="cardTypes"
        ></content-editor>
    </div>
</template>
<script>
import ContentEditor from './ContentEditor';
import SaveButton from '../buttons/SaveButton';
import BackButton from '../buttons/BackButton';

export default {
    name: 'content-c-u',
    components: { BackButton, SaveButton, ContentEditor },
    props: [
        'existingContent',
        'types',
        'settings',
        'disabledMessage',
        'zipFileNames',
        'allTopicsData',
        'cardTypes'
    ],
    mounted() {},
    data() {
        return {};
    },
    methods: {
        deleteContent() {
            if (
                confirm(
                    'Are you sure you want to delete content ' + this.existingContent.title + '?'
                )
            ) {
                axios
                    .delete('/admin/content/' + this.existingContent.id)
                    .then(res => {
                        window.location = '/admin/content';
                    })
                    .catch(err => {
                        alert(err.response.data.errors.content[0]);
                    });
            }
        }
    },
    watch: {}
};
</script>
