<template>
    <div class="article">
        <div class="article__head">
            <div class="grid-row grid-row--aic">
                <div class="grid-col grid-col--6 grid-col--sm-12">
                    <div class="p p--sm fw--500">{{ content.reading_time }} Min Read</div>
                    <h1 class="mt--050 article__title">{{ content.title }}</h1>
                    <div class="mt--150 grid-row grid-row--smg grid-row--aic">
                        <div class="grid-col grid-col--auto">
                            <button
                                class="button button--xs resource__button active__button"
                                @click="bookmarkAction(guest, content)"
                            >
                                <span>Save</span>
                                <svg
                                    :style="articleBookmarked() ? 'color: #1097e2;' : ''"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M19.3125 21.75L11.9992 17.6875L4.6875 21.75V3.0625C4.6875 2.84701 4.7731 2.64035 4.92548 2.48798C5.07785 2.3356 5.28451 2.25 5.5 2.25H18.5C18.7155 2.25 18.9222 2.3356 19.0745 2.48798C19.2269 2.64035 19.3125 2.84701 19.3125 3.0625V21.75Z"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    ></path>
                                </svg>
                            </button>
                        </div>
                        <div class="grid-col grid-col--auto">
                            <v-popover popoverClass="share-popover" placement="top">
                                <button class="button button--xs resource__button">
                                    <span>Share</span>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="26"
                                        height="24"
                                        viewBox="0 0 26 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    >
                                        <path d="M18.625 13.8125L24.25 8.1875L18.625 2.5625"></path>
                                        <path
                                            d="M20.5 21.3125H2.6875C2.43886 21.3125 2.2004 21.2137 2.02459 21.0379C1.84877 20.8621 1.75 20.6236 1.75 20.375V6.3125"
                                        ></path>
                                        <path
                                            d="M6.79199 16.625C7.41637 14.21 8.82531 12.0709 10.7975 10.5437C12.7696 9.01649 15.1932 8.18769 17.6876 8.1875H24.2501"
                                            stroke="#62727B"
                                        ></path>
                                    </svg>
                                </button>

                                <template slot="popover">
                                    <share :content="content" :url="linkToArticle(content)"></share>
                                    <a v-close-popover class="close-popover">
                                        <svg
                                            height="20px"
                                            viewBox="0 0 512 512"
                                            width="20px"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fill="#666"
                                                d="m256 512c-141.160156 0-256-114.839844-256-256s114.839844-256 256-256 256 114.839844 256 256-114.839844 256-256 256zm0-475.429688c-120.992188 0-219.429688 98.4375-219.429688 219.429688s98.4375 219.429688 219.429688 219.429688 219.429688-98.4375 219.429688-219.429688-98.4375-219.429688-219.429688-219.429688zm0 0"
                                            />
                                            <path
                                                fill="#666"
                                                d="m347.429688 365.714844c-4.679688 0-9.359376-1.785156-12.929688-5.359375l-182.855469-182.855469c-7.144531-7.144531-7.144531-18.714844 0-25.855469 7.140625-7.140625 18.714844-7.144531 25.855469 0l182.855469 182.855469c7.144531 7.144531 7.144531 18.714844 0 25.855469-3.570313 3.574219-8.246094 5.359375-12.925781 5.359375zm0 0"
                                            />
                                            <path
                                                fill="#666"
                                                d="m164.570312 365.714844c-4.679687 0-9.355468-1.785156-12.925781-5.359375-7.144531-7.140625-7.144531-18.714844 0-25.855469l182.855469-182.855469c7.144531-7.144531 18.714844-7.144531 25.855469 0 7.140625 7.140625 7.144531 18.714844 0 25.855469l-182.855469 182.855469c-3.570312 3.574219-8.25 5.359375-12.929688 5.359375zm0 0"
                                            />
                                        </svg>
                                    </a>
                                </template>
                            </v-popover>
                        </div>
                    </div>
                </div>
                <div class="grid-col grid-col--6 grid-col--sm-12 grid-col--sm-order-0">
                    <img
                        :src="content.thumbnail_article"
                        width="680"
                        height="460"
                        alt=""
                        class="article__cover"
                    />
                </div>
            </div>
        </div>

        <div class="grid-row">
            <div class="mt--250 grid-col grid-col--8 grid-col--sm-12">
                <div class="article__cont" v-html="content.description"></div>

                <hr class="mt--200" />

                <div class="mt--200 grid-row grid-row--smg grid-row--aic">
                    <div class="grid-col grid-col--auto">
                        <button
                            class="button button--xs resource__button"
                            @click="bookmarkAction(guest, content)"
                        >
                            <span>Save</span>
                            <svg
                                :style="articleBookmarked() ? 'color: #1097e2;' : ''"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M19.3125 21.75L11.9992 17.6875L4.6875 21.75V3.0625C4.6875 2.84701 4.7731 2.64035 4.92548 2.48798C5.07785 2.3356 5.28451 2.25 5.5 2.25H18.5C18.7155 2.25 18.9222 2.3356 19.0745 2.48798C19.2269 2.64035 19.3125 2.84701 19.3125 3.0625V21.75Z"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                ></path>
                            </svg>
                        </button>
                    </div>
                    <div class="grid-col grid-col--auto">
                        <v-popover popoverClass="share-popover" placement="top">
                            <button class="button button--xs resource__button">
                                <span>Share</span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="26"
                                    height="24"
                                    viewBox="0 0 26 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                >
                                    <path d="M18.625 13.8125L24.25 8.1875L18.625 2.5625"></path>
                                    <path
                                        d="M20.5 21.3125H2.6875C2.43886 21.3125 2.2004 21.2137 2.02459 21.0379C1.84877 20.8621 1.75 20.6236 1.75 20.375V6.3125"
                                    ></path>
                                    <path
                                        d="M6.79199 16.625C7.41637 14.21 8.82531 12.0709 10.7975 10.5437C12.7696 9.01649 15.1932 8.18769 17.6876 8.1875H24.2501"
                                        stroke="#62727B"
                                    ></path>
                                </svg>
                            </button>

                            <template slot="popover">
                                <share :content="content" :url="linkToArticle(content)"></share>
                                <a v-close-popover class="close-popover">
                                    <svg
                                        height="20px"
                                        viewBox="0 0 512 512"
                                        width="20px"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill="#666"
                                            d="m256 512c-141.160156 0-256-114.839844-256-256s114.839844-256 256-256 256 114.839844 256 256-114.839844 256-256 256zm0-475.429688c-120.992188 0-219.429688 98.4375-219.429688 219.429688s98.4375 219.429688 219.429688 219.429688 219.429688-98.4375 219.429688-219.429688-98.4375-219.429688-219.429688-219.429688zm0 0"
                                        />
                                        <path
                                            fill="#666"
                                            d="m347.429688 365.714844c-4.679688 0-9.359376-1.785156-12.929688-5.359375l-182.855469-182.855469c-7.144531-7.144531-7.144531-18.714844 0-25.855469 7.140625-7.140625 18.714844-7.144531 25.855469 0l182.855469 182.855469c7.144531 7.144531 7.144531 18.714844 0 25.855469-3.570313 3.574219-8.246094 5.359375-12.925781 5.359375zm0 0"
                                        />
                                        <path
                                            fill="#666"
                                            d="m164.570312 365.714844c-4.679687 0-9.355468-1.785156-12.925781-5.359375-7.144531-7.140625-7.144531-18.714844 0-25.855469l182.855469-182.855469c7.144531-7.144531 18.714844-7.144531 25.855469 0 7.140625 7.140625 7.144531 18.714844 0 25.855469l-182.855469 182.855469c-3.570312 3.574219-8.25 5.359375-12.929688 5.359375zm0 0"
                                        />
                                    </svg>
                                </a>
                            </template>
                        </v-popover>
                    </div>
                </div>
            </div>

            <div class="mt--250 grid-col grid-col--4 grid-col--sm-12">
                <div class="article-contents">
                    <div class="article-contents__title" v-if="similarArticles.length">
                        Also on AARP
                    </div>
                    <a
                        :href="linkToArticle(article)"
                        class="article-contents__link"
                        v-for="article in similarArticles"
                        >{{ article.title }}</a
                    >
                </div>

                <div class="mt--150 article-tags" v-if="content.topics && content.topics.length">
                    <a href="#" class="article-tags__link" v-for="topic in content.topics">{{
                        topic.name
                    }}</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import collectorActions from '../../../mixins/collectorActions';
import Time from '../../../mixins/Time';
import bookmarkActions from '../../../mixins/bookmarkActions';

export default {
    mixins: [collectorActions, Time, bookmarkActions],
    props: ['similarArticles'],
    data() {
        return {
            bookmarked: false
        };
    },
    methods: {
        articleBookmarked() {
            return this.guest.resource_ids.includes(this.content.id);
        },
        linkToArticle(article) {
            return window.location.origin + '/content/' + article.slug;
        }
    },
    computed: {
        ...mapGetters({
            pathway: 'pathway',
            guest: 'guest',
            node: 'node',
            content: 'content'
        })
    }
};
</script>
