<template>
    <div class="rfeed-lesson">
        <a :href="`/pathway/${pathway.slug}`" class="rfeed-lesson__link"></a>
        <div class="rfeed-lesson__head">
            <div class="rfeed-lesson__badge">Personalized Lesson</div>
            <div class="rfeed-lesson__time"></div>
        </div>
        <img
            src="img/rfeed-lesson/image-1.png"
            srcset="img/rfeed-lesson/image-1@2x.png 2x"
            width=""
            height=""
            alt=""
            class="rfeed-lesson__image"
        />
        <h4 class="rfeed-lesson__title">{{ pathway.name }}</h4>
        <p class="rfeed-lesson__subtitle" v-html="pathway.description_html"></p>
        <div class="button button--sm button--tertiary rfeed-lesson__button">
            <span>Start Personalized Lesson</span>
            <svg
                viewBox="0 0 24 24"
                width="20"
                height="20"
                stroke="currentColor"
                stroke-width="2.4"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
            >
                <line x1="5" y1="12" x2="19" y2="12"></line>
                <polyline points="13 6 19 12 13 18"></polyline>
            </svg>
        </div>
        <div class="rfeed-lesson__actions">
            <button type="button" class="rfeed-lesson__action rfeed-lesson__action--share"></button>
            <button type="button" class="rfeed-lesson__action rfeed-lesson__action--more"></button>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    name: 'lesson-card',
    props: ['pathway'],
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            guest: 'guest'
        })
    },
    methods: {},
    watch: {}
};
</script>

<style lang="scss"></style>
