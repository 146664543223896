<template>
    <div id="logs-table">
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <div class="admin-header">
            <v-row>
                <div class="display-1 col-auto">Activity logs</div>
            </v-row>

            <v-row>
                <v-col class="col-auto">
                    <v-menu
                        bottom
                        offset-y
                        :close-on-content-click="false"
                        class="filter-menu"
                        max-width="250px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-badge
                                :content="searchTotal"
                                :value="searchTotal"
                                color="red"
                                left
                                overlap
                            >
                                <v-btn text outlined v-bind="attrs" v-on="on">
                                    <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 24 24"
                                        stroke="#62727B"
                                        stroke-width="2.4"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    >
                                        <line x1="3" y1="7" x2="21" y2="7"></line>
                                        <line x1="6" y1="12" x2="18" y2="12"></line>
                                        <line x1="9" y1="17" x2="15" y2="17"></line>
                                    </svg>
                                </v-btn>
                            </v-badge>
                        </template>
                        <v-list>
                            <v-list-item class="filter-menu">
                                <v-list-item-content>
                                    <v-list-item-title class="filter-title"
                                        >Users</v-list-item-title
                                    >
                                    <v-list-item-subtitle class="pt-5">
                                        <v-select
                                            :items="users"
                                            v-model="selectedUsers"
                                            item-text="name"
                                            item-value="id"
                                            multiple
                                            label="Users"
                                            chips
                                            deletable-chips
                                            persistent-hint
                                            dense
                                        ></v-select>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item class="filter-menu">
                                <v-list-item-content>
                                    <v-list-item-title class="filter-title">
                                        Log types
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="pt-5">
                                        <v-select
                                            :items="logTypes"
                                            v-model="selectedTypes"
                                            multiple
                                            label="Log Types"
                                            chips
                                            deletable-chips
                                            persistent-hint
                                            dense
                                        ></v-select>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item class="filter-menu">
                                <v-list-item-content>
                                    <v-list-item-title class="filter-title">
                                        Date range
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="pt-5">
                                        <v-menu
                                            :close-on-content-click="false"
                                            v-model="fromMenu"
                                            ref="fromMenu"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    v-on="on"
                                                    v-model="from"
                                                    label="From"
                                                    persistent-hint
                                                    prepend-icon="event"
                                                    readonly
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="from"
                                                no-title
                                                @input="fromMenu = false"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-list-item-subtitle>

                                    <v-list-item-subtitle class="pt-5">
                                        <v-menu
                                            :close-on-content-click="false"
                                            v-model="toMenu"
                                            ref="toMenu"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    v-on="on"
                                                    v-model="to"
                                                    label="To"
                                                    persistent-hint
                                                    prepend-icon="event"
                                                    readonly
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="to"
                                                no-title
                                                @input="toMenu = false"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-col>

                <v-col cols="auto" v-if="searchIsActive">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="error" depressed @click="resetSearch" v-on="on">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    enable-background="new 0 0 24 24"
                                    viewBox="0 0 24 24"
                                    fill="white"
                                    width="18px"
                                    height="18px"
                                >
                                    <g>
                                        <path d="M0,0h24v24H0V0z" fill="none" />
                                    </g>
                                    <g>
                                        <g>
                                            <path
                                                d="M12,5V2L8,6l4,4V7c3.31,0,6,2.69,6,6c0,2.97-2.17,5.43-5,5.91v2.02c3.95-0.49,7-3.85,7-7.93C20,8.58,16.42,5,12,5z"
                                            />
                                            <path
                                                d="M6,13c0-1.65,0.67-3.15,1.76-4.24L6.34,7.34C4.9,8.79,4,10.79,4,13c0,4.08,3.05,7.44,7,7.93v-2.02 C8.17,18.43,6,15.97,6,13z"
                                            />
                                        </g>
                                    </g>
                                </svg>
                            </v-btn>
                        </template>
                        <span>Reset search</span>
                    </v-tooltip>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto" v-if="activityLogs.length">
                    <v-btn depressed color="primary" @click="exportLogs">Export</v-btn>
                </v-col>
            </v-row>
        </div>

        <div class="admin-table">
            <v-data-table
                fluid
                :headers="headers"
                :items="activityLogs"
                :footer-props="{ 'items-per-page-options': [20, 50, 75, 100] }"
                :options.sync="options"
                :loading="loading"
                :server-items-length="totalActivityLogs"
                :expanded.sync="expanded"
                style="margin-bottom: 70px;"
            >
                <template v-slot:expanded-item="{ item, headers }">
                    <td :colspan="headers.length">
                        <expanded-log :log="item" v-if="canExpandLog(item)"></expanded-log>
                    </td>
                </template>

                <template slot="item" slot-scope="props">
                    <tr @click="expandLog(props.item, props, $event)">
                        <td>{{ props.item.log_name }}</td>
                        <td>{{ props.item.description }}</td>
                        <td>{{ props.item.subject_id }}</td>
                        <td>{{ renderSubject(props.item.subject_type) }}</td>
                        <td>{{ props.item.causer ? props.item.causer.name : '' }}</td>
                        <td>{{ renderLocalTime(props.item.created_at) }}</td>
                        <td>
                            <template v-if="canExpandLog(props.item)">
                                <v-icon
                                    v-if="currentExpandedLog == props.item.id"
                                    color="black"
                                    small
                                    >expand_less
                                </v-icon>
                                <v-icon v-else color="black" small>expand_more</v-icon>
                            </template>
                        </td>
                    </tr>
                </template>

                <template slot="no-data">
                    <v-alert
                        :value="true"
                        color="error"
                        style="color: white;"
                        icon="warning"
                        v-if="!loading && activityLogs.length == 0"
                    >
                        No results for selected filters
                    </v-alert>
                </template>
            </v-data-table>
        </div>
    </div>
</template>
<script>
import Time from '../../../mixins/Time';
let FileSaver = require('file-saver');
export default {
    props: ['users', 'logTypes'],
    mixins: [Time],
    mounted() {},
    data() {
        return {
            activityLogs: [],
            subjectTypes: [{ text: 'User', value: 'App\\User' }],
            selectedUsers: [],
            selectedTypes: [],
            from: new Date().toISOString().substr(0, 10),
            fromMenu: false,
            to: new Date().toISOString().substr(0, 10),
            toMenu: false,
            overlay: false,
            headers: [
                { text: 'Log', value: 'log_name', sortable: true },
                { text: 'Description', value: 'description', sortable: true },
                { text: 'Subject ID', value: 'subject_id', sortable: true },
                { text: 'Type', value: 'subject_type', sortable: true },
                { text: 'User', value: 'causer_id', sortable: true },
                { text: 'Created', value: 'created_at', sortable: true },
                { text: '', value: 'used_for_expand_icon', sortable: false }
            ],
            options: { sortDesc: [true], sortBy: ['id'] },
            loading: false,
            totalActivityLogs: 0,
            currentExpandedLog: null,
            expanded: [],
            export: false
        };
    },
    computed: {
        searchIsActive() {
            return !!(this.selectedUsers.length || this.selectedTypes.length);
        },
        searchTotal() {
            let total = 0;
            total += this.selectedUsers.length;
            total += this.selectedTypes.length;
            return total;
        }
    },
    methods: {
        resetSearch() {
            this.selectedUsers = [];
            this.selectedTypes = [];

            this.getDataFromApi();
        },
        canExpandLog(log) {
            if (Object.keys(log.properties).length > 0) {
                return true;
            }

            return false;
        },
        logIsExpanded(log) {
            for (let i = 0; i < this.expanded.length; i++) {
                if (log.id == this.expanded[i].id) {
                    return true;
                }
            }

            return false;
        },
        expandLog(log, props, event) {
            if (!this.canExpandLog(log)) {
                return;
            }

            if (this.logIsExpanded(log)) {
                this.expanded = [];
                this.currentExpandedLog = null;
                return;
            }

            this.expanded.push(log);
            this.currentExpandedLog = props.item.id;
        },
        renderSubject(subject) {
            if (subject) {
                return subject.replace('App\\', '');
            }

            return '';
        },
        getDataFromApi: _.debounce(function() {
            this.loading = true;
            this.overlay = true;
            let instance = this;

            let endpoint = '/admin/activity_logs/json/';
            let descendingQuery =
                this.options.sortDesc[0] !== undefined
                    ? '&descending=' + this.options.sortDesc[0]
                    : '';
            let queries =
                '?page=' +
                this.options.page +
                '&rowsPerPage=' +
                this.options.itemsPerPage +
                '&sortBy=' +
                this.options.sortBy +
                descendingQuery;

            if (this.selectedTypes.length > 0) {
                queries += '&logTypes=' + this.selectedTypes.join(',');
            }

            if (this.selectedUsers.length > 0) {
                queries += '&users=' + this.selectedUsers.join(',');
            }

            if (this.from) {
                queries += '&from=' + this.from;
            }

            if (this.to) {
                queries += '&to=' + this.to;
            }

            if (this.export) {
                queries += '&export=' + this.export;
            }

            axios
                .get(endpoint + queries)
                .then(function(response) {
                    if (instance.export) {
                        let file = new File([response.data], 'ActivityLogs.csv', {
                            type: 'text/csv;charset=utf-8'
                        });
                        FileSaver.saveAs(file);
                        instance.loading = false;
                        instance.overlay = false;
                        instance.export = false;
                    } else {
                        instance.activityLogs = response.data.data;
                        instance.totalActivityLogs = response.data.total;
                        instance.loading = false;
                        instance.overlay = false;
                    }
                })
                .catch(function(error) {
                    console.error(error);
                });
        }, 100),
        exportLogs() {
            this.export = true;
            this.getDataFromApi();
        }
    },
    watch: {
        selectedUsers() {
            this.getDataFromApi();
        },
        selectedTypes() {
            this.getDataFromApi();
        },
        from() {
            this.getDataFromApi();
        },
        to() {
            this.getDataFromApi();
        },
        options: {
            handler() {
                this.getDataFromApi();
            },
            deep: true
        }
    }
};
</script>
<style>
.v-date-picker-table.v-date-picker-table--date > table {
    background-color: white;
}
#logs-table table tr {
    cursor: pointer;
}

.v-list-item {
    user-select: inherit;
}
</style>
