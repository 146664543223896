import Form from '../Form';

class TopicForm extends Form {
    constructor(data) {
        if (!data) {
            data = {
                name: ''
            };
        }

        super(data);
    }
}

export default TopicForm;
