var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"expanded-log"},[_c('br'),_c('br'),_vm._v(" "),_c('v-layout',{attrs:{"align-center":""}},[(_vm.expandType === 'differenceView')?[_c('v-flex',{staticClass:"col-4",attrs:{"offset-sm1":""}},[_c('v-data-iterator',{attrs:{"items":_vm.items,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item",fn:function(){return [_c('v-card',{staticStyle:{"margin-top":"10px"},attrs:{"width":"100%"}},[_c('v-card-title',{staticStyle:{"padding-top":"0px","padding-bottom":"0px"}},[_c('h4',{staticStyle:{"color":"black","margin-bottom":"0px"}},[_vm._v("\n                                    Old Values\n                                ")])]),_vm._v(" "),_c('v-divider',{staticStyle:{"border-color":"#FF5252 !important","border-width":"1.6px"}}),_vm._v(" "),_c('v-list',{attrs:{"dense":""}},_vm._l((Object.keys(_vm.getDiffHeaders())),function(header){return (header !== 'content')?_c('v-list-item',{key:'old' + header},[_c('v-list-item-content',[_vm._v(_vm._s(header))]),_vm._v(" "),(header == 'created_at' || header == 'updated_at')?_c('v-list-item-content',{staticClass:"align-end",domProps:{"textContent":_vm._s(
                                            _vm.renderLocalTime(_vm.log.properties.old_data[header])
                                        )}}):_c('v-list-item-content',{staticClass:"align-end",domProps:{"textContent":_vm._s(_vm.log.properties.old_data[header])}})],1):_vm._e()}),1)],1)]},proxy:true}],null,false,2335457186)})],1),_vm._v(" "),_c('v-flex',{staticClass:"col-4",attrs:{"offset-sm2":""}},[_c('v-data-iterator',{attrs:{"items":_vm.items,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item",fn:function(){return [_c('v-card',{staticStyle:{"margin-top":"10px"},attrs:{"width":"100%"}},[_c('v-card-title',{staticStyle:{"padding-top":"0px","padding-bottom":"0px"}},[_c('h4',{staticStyle:{"color":"black","margin-bottom":"0px"}},[_vm._v("\n                                    New Values\n                                ")])]),_vm._v(" "),_c('v-divider',{staticStyle:{"border-color":"#4CAE50 !important","border-width":"1.6px"}}),_vm._v(" "),_c('v-list',{attrs:{"dense":""}},_vm._l((Object.keys(_vm.getDiffHeaders())),function(header){return (header !== 'content')?_c('v-list-item',{key:'attributes' + header},[_c('v-list-item-content',[_vm._v(_vm._s(header))]),_vm._v(" "),(header == 'created_at' || header == 'updated_at')?_c('v-list-item-content',{staticClass:"align-end font-weight-bold",domProps:{"textContent":_vm._s(
                                            _vm.renderLocalTime(_vm.log.properties.new_data[header])
                                        )}}):_c('v-list-item-content',{staticClass:"align-end font-weight-bold",domProps:{"textContent":_vm._s(_vm.log.properties.new_data[header])}})],1):_vm._e()}),1)],1)]},proxy:true}],null,false,1372774375)})],1)]:_vm._e(),_vm._v(" "),(_vm.expandType === 'singleDiff')?[_c('br'),_c('br'),_vm._v(" "),_c('v-layout',{attrs:{"align-center":""}},[_c('v-flex',{staticClass:"col-7",attrs:{"offset-sm2":""}},[_c('v-data-iterator',{attrs:{"items":_vm.items,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item",fn:function(){return [_c('v-card',{staticStyle:{"margin-top":"10px"},attrs:{"width":"100%"}},[_c('v-card-title',{staticStyle:{"padding-top":"0px","padding-bottom":"0px"}},[_c('h4',{staticStyle:{"color":"black","margin-bottom":"0px"}},[_vm._v("\n                                        Updated Values\n                                    ")])]),_vm._v(" "),_c('v-divider',{staticStyle:{"border-color":"#4CAE50 !important","border-width":"1.6px"}}),_vm._v(" "),_c('v-list',{attrs:{"dense":""}},_vm._l((Object.keys(_vm.log.properties)),function(header){return _c('v-list-item',{key:'singleDiffHeader' + header},[_c('v-list-item-content',[_vm._v(_vm._s(header))]),_vm._v(" "),(
                                                header == 'created_at' || header == 'updated_at'
                                            )?_c('v-list-item-content',{staticClass:"align-end font-weight-bold",domProps:{"textContent":_vm._s(_vm.renderLocalTime(_vm.log.properties[header]))}}):_c('v-list-item-content',{staticClass:"align-end font-weight-bold"},[(
                                                    typeof _vm.log.properties[header] ===
                                                        'string' ||
                                                        typeof _vm.log.properties[header] ===
                                                            'number' ||
                                                        typeof _vm.log.properties[header] ===
                                                            'boolean'
                                                )?[_vm._v("\n                                                "+_vm._s(_vm.log.properties[header])+"\n                                            ")]:_vm._l((_vm.log.properties[header]),function(prop,index){return [(index !== 'content')?_c('span',[_vm._v("\n                                                    "+_vm._s(index)+" : "+_vm._s(prop)+"\n                                                ")]):_vm._e(),_vm._v(" "),_c('br')]})],2)],1)}),1)],1)]},proxy:true}],null,false,1951014303)})],1)],1)]:_vm._e()],2),_vm._v(" "),_c('br'),_c('br')],1)}
var staticRenderFns = []

export { render, staticRenderFns }