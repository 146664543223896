<template>
    <div
        class="grid-cont node-cont"
        :style="{
            'max-width':
                component === 'IframeContent' && node && node.content.settings.width === '100%'
                    ? '100%'
                    : '1024px'
        }"
    >
        <div class="next-btn--cont" v-if="allowNext" :key="content.id">
            <button
                type="button"
                class="button button--xs"
                dusk="nextNode"
                @click.once="loadNextNode"
            >
                <span>Next</span>
                <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2.4"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                >
                    <line x1="19" y1="12" x2="5" y2="12"></line>
                    <polyline
                        points="11 18 5 12 11 6"
                        style="transform: rotate(180deg); transform-origin: 50% 50%;"
                    ></polyline>
                </svg>
            </button>
        </div>

        <transition-group name="fade" mode="out-in">
            <component
                key="transition0"
                :is="component"
                :revert-choice="revertChoice"
                @loadNext="loadNextNode"
                @updateRevertChoice="$emit('updateRevertChoice')"
                :key="content.id"
                @backStep="goStepBack"
            ></component>
            <component
                is="content-feedback"
                key="transition1"
                :key="`feedback-${content.id}`"
                :content="content"
                :existing-content-feedback="existingContentFeedback"
            ></component>
        </transition-group>

        <pathway-feedback v-if="showFeedbackOverlay"></pathway-feedback>
    </div>
</template>
<script>
import collectorActions from '../../../mixins/collectorActions';
import { mapGetters } from 'vuex';

export default {
    name: 'pathway-nodes',
    props: ['originalNode', 'existingFeedback', 'revertChoice', 'existingContentFeedback'],
    mixins: [collectorActions],
    data() {
        return {
            component: '',
            typeMap: {
                Iframe: 'IframeContent',
                Video: 'VideoContent',
                QuestionSet: 'QuestionSetContent',
                Text: 'TextContent',
                Card: 'CardContent',
                Link: 'LinkContent'
            }
        };
    },
    mounted() {
        this.$store.dispatch('storeAndSetNode', this.originalNode);
        this.loadContent(this.originalNode.content);
        this.loadNodeAction(this.originalNode);
    },
    computed: {
        ...mapGetters({
            guest: 'guest',
            node: 'node',
            content: 'content',
            lastNodeInPathway: 'lastNodeInPathway'
        }),
        showFeedbackOverlay() {
            return this.lastNodeInPathway && !this.existingFeedback;
        },
        allowNext() {
            return (
                !_.isEmpty(this.content) &&
                this.content.settings &&
                this.content.settings.allow_next &&
                !this.node.multi_content
            );
        }
    },
    methods: {
        redirect() {
            window.location.replace('/');
        },
        loadContent(content) {
            content = content || this.originalNode.content;

            this.$store.commit('SET_CONTENT', content);

            this.component = this.node.multi_content
                ? 'MultiContent'
                : this.typeMap[content.content_type.name];
        },
        loadNextNode() {
            this.$store.dispatch('loadNextNode').then(() => {
                if (!this.lastNodeInPathway) {
                    this.loadContent(this.node.content);
                    this.loadNodeAction(this.node);
                }

                if (this.existingFeedback && this.lastNodeInPathway) {
                    this.redirect();
                }
            });
        },
        goStepBack() {
            if (!this.node.origin) {
                this.$store.dispatch('loadPreviousNode').then(() => {
                    this.loadContent(this.node.content);
                    this.goBackAction(this.node);
                });
            }
        }
    }
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
 {
    opacity: 0;
}

.node-cont {
    min-height: 100%;
    height: 100%;
}

.feedback-overlay {
    opacity: 0.7;
}

.next-btn--cont {
    min-height: 40px;
    margin-top: -20px;
    max-width: 1024px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    margin-right: auto;
}
</style>
