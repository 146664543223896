<template>
    <div>
        <loading :active="deckLoading" />
        <deck-header></deck-header>
        <deck-subheader></deck-subheader>
        <div class="mt--300 grid-col grid-col--12" style="height: 50px"></div>

        <deck-content></deck-content>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import collectorActions from '../../../mixins/collectorActions';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
    name: 'shared-deck-view',
    props: ['currentGuest', 'sharedGuest', 'guestCards', 'deckCategories'],
    mixins: [collectorActions],
    components: { Loading },
    mounted() {
        this.$store.commit('SET_GUEST', this.currentGuest);
        this.$store.commit('SET_BACK_TO_FEED', false);
        this.$store.commit('SET_GUEST_CARDS', this.guestCards);
        this.$store.commit('SET_DECK_LOADING', false);
        this.$store.commit('SET_DECK_CATEGORIES', this.deckCategories);
        this.$store.commit('SET_SHARED_DECK_FROM', this.sharedGuest);
    },
    data() {
        return {
            overlay: false
        };
    },
    computed: {
        ...mapGetters({
            guest: 'guest',
            showSuggestionModal: 'showSuggestionModal',
            deckLoading: 'deckLoading',
            snackbarMessage: 'snackbarMessage'
        })
    },
    methods: {},
    watch: {}
};
</script>

<style lang="scss">
.ctt-content {
    padding: 15px 10px;
}

.p-width--100 {
    width: 100%;
}
</style>
