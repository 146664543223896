<template>
    <div class="flow-popover">
        <div class="flow-popover__arrow"></div>
        <div :class="`admin-card__badge admin-card__badge--${typeMap[item.content_type.name]}`">
            {{ item.content_type.name }}
        </div>
        <h6 class="mt--050 tc--dblue">{{ item.title }}</h6>
        <div class="mt--075"></div>
        <div class="admin-tags admin-tags--xs">
            <div class="admin-tags__item" v-for="topic in item.topics" :key="`topic-${topic.id}`">
                {{ topic.name }}
            </div>
        </div>
        <p class="mt--100 p p--xxs">
            {{ item.description }}
        </p>
        <img
            :src="item.thumbnail_sm"
            v-if="item.thumbnail_sm"
            width="290"
            height="145"
            alt=""
            class="mt--100 flow-popover__image"
        />

        <dl
            class="mt--100 p p--xxs flow-popover__list"
            v-if="item.content_type.name === 'QuestionSet'"
        >
            <dt>Preface</dt>
            <dd class="tc--dark">
                <div>{{ item.preface }}</div>
            </dd>
            <dt>Questions</dt>
            <dd class="tc--dark">
                <div>
                    <template v-for="(question, index) in item.questions">
                        {{ index + 1 }}. {{ question.text }}
                        <br />
                    </template>
                </div>
            </dd>
        </dl>

        <dl
            v-if="['Iframe', 'Video'].includes(item.content_type.name)"
            class="mt--100 p p--xxs flow-popover__list"
        >
            <dt>Source Url</dt>
            <dd class="tc--dark">
                <div>
                    <a :href="item.url" target="_blank" class="text-link">
                        {{ item.url }}
                    </a>
                </div>
            </dd>
            <dt>Dimensions</dt>
            <dd class="tc--dark">
                <div>{{ item.settings.width }} by {{ item.settings.height }}</div>
            </dd>
        </dl>
    </div>
</template>
<script>
export default {
    name: 'info-popover',
    props: ['item', 'typeMap'],
    methods: {}
};
</script>

<style lang="scss"></style>
