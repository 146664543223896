<template>
    <div class="resource">
        <content-header></content-header>

        <div class="iframe-cont">
            <iframe
                :src="content.url"
                :width="content.settings.width"
                :height="content.settings.height"
                :style="{
                    width:
                        content.settings.width !== '100%'
                            ? content.settings.width + 'px'
                            : content.settings.width
                }"
                class="mt--200 resource__iframe"
                :class="{ largeIframe: fullIframe }"
            ></iframe>
        </div>
    </div>
</template>
<script>
import Time from '../../../mixins/Time';
import IframeMixin from '../../../mixins/IframeMixin';

export default {
    name: 'iframe-content',
    props: [''],
    mixins: [Time, IframeMixin]
};
</script>

<style lang="scss">
.largeIframe {
    position: absolute;
    left: 0;
}

.iframe-cont {
    width: 100%;
    position: relative;
    margin-bottom: 70px;
    min-height: 100%;
    iframe.resource__iframe {
        margin-left: auto;
        margin-right: auto;
    }
}
</style>
