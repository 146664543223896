<template>
    <v-col class="col-12" mt-3 v-if="!isDeleted">
        <v-card elevation="5" style="width: 100%">
            <v-container fluid style="padding: 40px;">
                <v-form ref="questionSetForm" v-model="valid">
                    <v-layout row>
                        <v-flex text-xs-left xs12>
                            <h5 class="float-left">Question {{ inc + 1 }}</h5>

                            <v-flex offset-xs10 xs4 mb-4>
                                <div class="">
                                    <v-btn
                                        :x-small="$vuetify.breakpoint.xsOnly"
                                        :small="$vuetify.breakpoint.smAndUp"
                                        color="error"
                                        @click="remove()"
                                        :dusk="'deleteQuestionButton#' + inc"
                                    >
                                        Delete
                                        <v-icon right small>delete</v-icon>
                                    </v-btn>
                                </div>
                            </v-flex>
                        </v-flex>

                        <v-flex>
                            <v-col md="12">
                                <v-textarea
                                    v-model="question.text"
                                    :rules="requiredAndLengthRules"
                                    :dusk="'questionTextArea#' + inc"
                                    label="Question"
                                    required
                                    rows="2"
                                    @changed="changed()"
                                    outlined
                                ></v-textarea>
                            </v-col>

                            <v-col md="12" class="text-right">
                                <v-btn
                                    small
                                    @click.native="addAnswerBtnHandler()"
                                    :dusk="'addAnswerButton#' + inc"
                                >
                                    Add Answer
                                    <v-icon right small>add_box</v-icon>
                                </v-btn>
                            </v-col>

                            <v-card
                                v-for="(answer, i) in question.answers"
                                :key="'answer#' + i"
                                elevation="2"
                                style="padding: 20px; margin-top: 20px; width: 100%"
                            >
                                <v-col md="12">
                                    <v-row no-gutters>
                                        <v-col class="col-md-1 mt-4">
                                            <h2>{{ abc[i] }}</h2>
                                        </v-col>
                                        <v-col cols="10" md="11" sm="10" xs="10">
                                            <v-textarea
                                                v-model="answer.text"
                                                :dusk="'answerTextArea#' + i"
                                                :rules="requiredAndLengthRules"
                                                label="Answer"
                                                auto-grow
                                                rows="1"
                                                required
                                                @changed="changed()"
                                            ></v-textarea>
                                        </v-col>
                                    </v-row>

                                    <v-row no-gutters>
                                        <v-col cols="10" md="10" sm="10" xs="10">
                                            <topic-dropdown
                                                v-model="answer.topics"
                                                :all-topics-data="allTopicsData"
                                            ></topic-dropdown>
                                        </v-col>
                                        <v-col
                                            cols="1"
                                            md="1"
                                            offset="1"
                                            sm="1"
                                            xs="2"
                                            class="mt-9"
                                        >
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                        small
                                                        color="error"
                                                        @click="removeAnswer(answer, i)"
                                                        :dusk="'deleteAnswerButton#' + i"
                                                        v-on="on"
                                                    >
                                                        <v-icon>delete</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Delete Answer</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-card>
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-container>
        </v-card>
    </v-col>
</template>

<script>
import TopicDropdown from '../topic/TopicDropdown';

export default {
    props: ['content', 'question', 'inc', 'allTopicsData'],
    components: { TopicDropdown },
    data: function() {
        return {
            isDeleted: false,
            answerRules: [],
            abc: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
            valid: false,
            requiredRules: [v => !!v || 'Required'],
            requiredAndLengthRules: [
                v => !!v || 'Required',
                v => (v && v.length <= 255) || 'This field exceeds maximum allowed characters'
            ]
        };
    },
    methods: {
        addAnswerBtnHandler() {
            this.question.answers.push({ text: '', id: null, topics: [] });
        },
        changed() {
            this.$emit('changed', this.question);
        },
        remove() {
            let questionNumber = this.inc + 1;

            if (confirm('Are you sure you want to delete Question ' + questionNumber + '?')) {
                this.$emit('deleted', this.question);
                this.isDeleted = true;
            }
        },
        removeAnswer(answer, index) {
            if (answer.id) {
                axios.delete('/admin/answers/' + answer.id + '').then(res => {
                    this.removeAnswerFromList(index);
                });
            } else {
                this.removeAnswerFromList(index);
            }
        },
        removeAnswerFromList(index) {
            this.question.answers.splice(index, 1);
        }
    }
};
</script>

<style lang="scss"></style>
