<template>
    <v-row style="z-index: 9999;">
        <v-dialog v-model="localDialog" max-width="340">
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>

            <admin-info-card v-if="!node.multi_content" :content="node.content"></admin-info-card>

            <template v-else-if="content.length">
                <admin-info-card
                    @deleteContent="deleteContentInNode($event)"
                    v-for="data in content"
                    :key="data.id"
                    :content="data"
                    :multi-content="true"
                ></admin-info-card>
            </template>

            <v-btn color="error" @click="localDialog = false" text outlined x-large v-if="!overlay">
                Close
            </v-btn>
        </v-dialog>
    </v-row>
</template>
<script>
export default {
    name: 'node-info-modal',
    props: ['node', 'dialog'],
    mounted() {
        if (this.node.multi_content) {
            this.overlay = true;
            axios.get('/admin/node_content/' + this.node.id).then(res => {
                this.content.splice(0, this.content.length, ...res.data.currentContent);
                this.overlay = false;
            });
        }
    },
    data() {
        return {
            localDialog: this.dialog,
            content: [],
            overlay: false
        };
    },
    methods: {
        capitalize(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        deleteContentInNode(eventData) {
            if (this.node.content_id === eventData) {
                this.$emit('deleteContent', { content_id: eventData, node_id: this.node.id });
            } else if (this.node.content_id2 === eventData) {
                this.$emit('deleteContent', { content_id2: eventData, node_id: this.node.id });
            } else {
                this.$emit('deleteContent', { content_id3: eventData, node_id: this.node.id });
            }
        }
    },
    watch: {
        localDialog(value) {
            this.$emit('modalClosed');
        },
        node: {
            handler() {
                if (this.node.multi_content) {
                    this.overlay = true;
                    axios.get('/admin/node_content/' + this.node.id).then(res => {
                        this.content.splice(0, this.content.length, ...res.data.currentContent);
                        this.overlay = false;
                    });
                } else {
                    this.content.splice(0, this.content.length);
                }
            },
            deep: true
        }
    }
};
</script>

<style lang="scss">
.v-dialog--active {
    background: #fff;
}
</style>
