<template>
    <div class="grid-col grid-col--12 mb--100 interested">
        <h2>Your personal deck of AARP Care To Talk?&trade; conversation cards</h2>

        <div class="mt--050 grid-row grid-row--smg">
            <div
                class="mt--100 grid-col grid-col--4 grid-col--md-4 grid-col--xs-12"
                v-for="content in links"
                :key="content.id"
            >
                <div class="interested-card">
                    <div class="interested-card__figure">
                        <img :src="content.thumbnail_xs" width="370" height="200" alt="" />
                    </div>
                    <h4 class="mt--100">{{ content.title }}</h4>
                    <p class="mt--050">{{ content.description }}</p>

                    <div class="mt--125 interested-card__foot">
                        <div class="interested-card__badge">{{ content.content_type.name }}</div>
                        <div class="interested-card__meta"></div>
                        <button
                            type="button"
                            class="interested-card__action interested-card__action--book"
                            :class="{
                                active:
                                    guest.resource_ids && guest.resource_ids.includes(content.id)
                            }"
                            @click="bookmarkAction(guest, content)"
                        ></button>
                        <v-popover popoverClass="share-popover" placement="top">
                            <button
                                type="button"
                                v-tooltip="`Share`"
                                class="interested-card__action interested-card__action--share"
                                style="margin-top: 4px"
                            ></button>

                            <template slot="popover">
                                <share :content="content" :url="`/content/${content.slug}`"></share>
                                <a v-close-popover class="close-popover">
                                    <svg
                                        height="20px"
                                        viewBox="0 0 512 512"
                                        width="20px"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill="#666"
                                            d="m256 512c-141.160156 0-256-114.839844-256-256s114.839844-256 256-256 256 114.839844 256 256-114.839844 256-256 256zm0-475.429688c-120.992188 0-219.429688 98.4375-219.429688 219.429688s98.4375 219.429688 219.429688 219.429688 219.429688-98.4375 219.429688-219.429688-98.4375-219.429688-219.429688-219.429688zm0 0"
                                        />
                                        <path
                                            fill="#666"
                                            d="m347.429688 365.714844c-4.679688 0-9.359376-1.785156-12.929688-5.359375l-182.855469-182.855469c-7.144531-7.144531-7.144531-18.714844 0-25.855469 7.140625-7.140625 18.714844-7.144531 25.855469 0l182.855469 182.855469c7.144531 7.144531 7.144531 18.714844 0 25.855469-3.570313 3.574219-8.246094 5.359375-12.925781 5.359375zm0 0"
                                        />
                                        <path
                                            fill="#666"
                                            d="m164.570312 365.714844c-4.679687 0-9.355468-1.785156-12.925781-5.359375-7.144531-7.140625-7.144531-18.714844 0-25.855469l182.855469-182.855469c7.144531-7.144531 18.714844-7.144531 25.855469 0 7.140625 7.140625 7.144531 18.714844 0 25.855469l-182.855469 182.855469c-3.570312 3.574219-8.25 5.359375-12.929688 5.359375zm0 0"
                                        />
                                    </svg>
                                </a>
                            </template>
                        </v-popover>
                    </div>
                    <a target="_blank" :href="content.url" class="interested-card__link"></a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import collectorActions from '../../../mixins/collectorActions';
import Time from '../../../mixins/Time';
import bookmarkActions from '../../../mixins/bookmarkActions';

export default {
    props: ['links'],
    mixins: [Time, collectorActions, bookmarkActions],
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            guest: 'guest'
        })
    },
    methods: {},
    watch: {}
};
</script>

<style lang="scss"></style>
