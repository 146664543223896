<template>
    <div
        class="rfeed-featured"
        :style="`background-image: url('${featuredContent.thumbnail_featured}')`"
    >
        <a :href="`/content/${featuredContent.slug}`" class="rfeed-featured__link"></a>
        <div class="rfeed-featured__head">
            <div class="rfeed-featured__badge">Featured</div>
            <div class="rfeed-featured__date">{{ renderFormDate(featuredContent.created_at) }}</div>
            <template v-if="featuredContent.content_type.name === 'Video'">
                <div class="rfeed-article__bull"></div>
                <div class="rfeed-article__date">
                    {{ videoDuration(featuredContent.settings.video_duration) }}
                </div>
            </template>
        </div>
        <div class="rfeed-featured__cont">
            <h4 class="rfeed-featured__title">
                {{ featuredContent.title }}
            </h4>
            <p class="rfeed-featured__subtitle" v-html="featuredContent.description_html"></p>
        </div>
        <div class="rfeed-featured__actions">
            <button
                type="button"
                class="rfeed-featured__action rfeed-featured__action--book"
                :class="{
                    active:
                        guest &&
                        featuredContent &&
                        guest.resource_ids &&
                        guest.resource_ids.includes(featuredContent.id)
                }"
                v-tooltip="
                    guest && guest.resource_ids && guest.resource_ids.includes(featuredContent.id)
                        ? 'Discard'
                        : 'Save'
                "
                @click="bookmarkAction(guest, featuredContent)"
            ></button>
            <v-popover popoverClass="share-popover" placement="top">
                <button
                    type="button"
                    v-tooltip="`Share`"
                    class="rfeed-featured__action rfeed-featured__action--share"
                ></button>

                <template slot="popover">
                    <share :content="featuredContent" :url="shareUrl"></share>
                    <a v-close-popover class="close-popover">
                        <svg
                            height="20px"
                            viewBox="0 0 512 512"
                            width="20px"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill="#666"
                                d="m256 512c-141.160156 0-256-114.839844-256-256s114.839844-256 256-256 256 114.839844 256 256-114.839844 256-256 256zm0-475.429688c-120.992188 0-219.429688 98.4375-219.429688 219.429688s98.4375 219.429688 219.429688 219.429688 219.429688-98.4375 219.429688-219.429688-98.4375-219.429688-219.429688-219.429688zm0 0"
                            />
                            <path
                                fill="#666"
                                d="m347.429688 365.714844c-4.679688 0-9.359376-1.785156-12.929688-5.359375l-182.855469-182.855469c-7.144531-7.144531-7.144531-18.714844 0-25.855469 7.140625-7.140625 18.714844-7.144531 25.855469 0l182.855469 182.855469c7.144531 7.144531 7.144531 18.714844 0 25.855469-3.570313 3.574219-8.246094 5.359375-12.925781 5.359375zm0 0"
                            />
                            <path
                                fill="#666"
                                d="m164.570312 365.714844c-4.679687 0-9.355468-1.785156-12.925781-5.359375-7.144531-7.140625-7.144531-18.714844 0-25.855469l182.855469-182.855469c7.144531-7.144531 18.714844-7.144531 25.855469 0 7.140625 7.140625 7.144531 18.714844 0 25.855469l-182.855469 182.855469c-3.570312 3.574219-8.25 5.359375-12.929688 5.359375zm0 0"
                            />
                        </svg>
                    </a>
                </template>
            </v-popover>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Time from '../../../mixins/Time';
import collectorActions from '../../../mixins/collectorActions';
import bookmarkActions from '../../../mixins/bookmarkActions';

export default {
    name: 'featured-card',
    props: ['featuredContent'],
    mixins: [Time, collectorActions, bookmarkActions],
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            guest: 'guest'
        }),
        shareUrl() {
            return window.location.origin + '/content/' + this.featuredContent.slug;
        }
    },
    methods: {},
    watch: {}
};
</script>

<style lang="scss">
.rfeed-featured__action--book.active {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="%231097E2" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"><path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path></svg>');
}
</style>
