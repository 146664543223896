<template>
    <v-col cols="auto">
        <a :href="url" class="admin-table__edit">
            <svg
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
            >
                <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
            </svg>
            {{ text || 'Edit' }}
        </a>
    </v-col>
</template>
<script>
export default {
    name: 'edit-button',
    props: ['url', 'text']
};
</script>
