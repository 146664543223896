<template>
    <div class="flow-logic">
        <h4 class="tc--dblue flow-logic__title" v-if="!isNested">
            <div
                :class="
                    `flow-logic__type flow-logic__type--${typeMap[node.content.content_type.name]}`
                "
            ></div>
            {{ node.content.title }}
        </h4>

        <div class="flow-logic__group">
            <div class="flow-logic__item group-item">
                <div class="grid-row grid-row--xsg grid-row--aic">
                    <div class="grid-col">
                        <div class="flow-logic__text">
                            If
                            <div class="select flow-logic__select">
                                <v-select
                                    dense
                                    outlined
                                    class="d-inline-block"
                                    :items="connectors"
                                    v-model="localRules.connector"
                                    single-line
                                    hide-details
                                    :disabled="pathwayActive"
                                    style="width: 100px; margin-left: 5px; margin-right: 5px; position: relative; bottom: 5px;"
                                ></v-select>
                            </div>
                            of the statements below are true
                        </div>
                    </div>
                    <div class="grid-col grid-col--auto">
                        <v-menu top close-on-click>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    class="primary-light"
                                    small
                                    v-bind="attrs"
                                    v-on="on"
                                    :disabled="pathwayActive"
                                    depressed
                                >
                                    Add Rule
                                </v-btn>
                            </template>

                            <v-list>
                                <v-list-item
                                    v-for="(item, index) in ruleTypesList"
                                    :key="'list-item#' + index"
                                    @click="addRule({ type: item.value, topics: [], content: [] })"
                                >
                                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                    <div class="grid-col grid-col--auto">
                        <v-btn
                            class="primary-light"
                            small
                            depressed
                            @click="addGroup"
                            :disabled="pathwayActive"
                            v-if="!isNested"
                        >
                            Add Group
                        </v-btn>

                        <v-tooltip top v-if="isNested">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-on="on"
                                    small
                                    v-bind="attrs"
                                    color="error"
                                    v-if="!pathwayActive"
                                    @click="deleteGroup()"
                                    depressed
                                    icon
                                >
                                    <svg
                                        viewBox="0 0 24 24"
                                        width="20"
                                        height="20"
                                        stroke="currentColor"
                                        stroke-width="1.8"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    >
                                        <polyline points="3 6 5 6 21 6"></polyline>
                                        <path
                                            d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                                        ></path>
                                        <line x1="10" y1="11" x2="10" y2="17"></line>
                                        <line x1="14" y1="11" x2="14" y2="17"></line>
                                    </svg>
                                </v-btn>
                            </template>
                            <span>Delete rule group</span>
                        </v-tooltip>
                    </div>
                </div>
            </div>
        </div>

        <v-row
            v-for="(rule, index) in localRules.rules"
            :key="'node#' + node.id + 'rule#' + index"
            :class="{ 'ml-10': !isNested, 'group-row': !rule.type }"
            class="rule-row"
        >
            <template v-if="rule.type">
                <v-col
                    cols="12"
                    class="flow-logic__item rule-column"
                    v-if="rule.type === 'answer_chosen'"
                >
                    <v-row>
                        <v-col style="max-width: 80%">
                            <div class="display-custom d-inline-block">
                                In question set for question
                            </div>
                            <v-select
                                :items="questionSetItems"
                                v-model="rule.question_id"
                                item-value="id"
                                item-text="text"
                                label="Pick Question"
                                single-line
                                :rules="requiredRules"
                                @change="loadAnswersForQuestion($event, index)"
                                class="d-inline-block"
                                :disabled="pathwayActive"
                                autocomplete
                                hide-details
                                dense
                                outlined
                                style=" margin-left: 5px; margin-right: 5px; max-width: 80%"
                            ></v-select>
                            <div class="display-custom d-inline-block">the user chose answer</div>
                            <v-select
                                :items="
                                    questionSetData[index] ? questionSetData[index].answers : []
                                "
                                v-model="rule.answer_chosen"
                                item-value="id"
                                item-text="text"
                                single-line
                                label="Pick Answer"
                                :rules="requiredRules"
                                class="d-inline-block"
                                :disabled="pathwayActive"
                                hide-details
                                dense
                                outlined
                                style="margin-left: 5px; margin-right: 5px;max-width: 80%"
                            ></v-select>
                        </v-col>
                        <v-col cols="auto" class="ml-auto">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        depressed
                                        v-on="on"
                                        v-bind="attrs"
                                        class="d-inline-block"
                                        v-if="!pathwayActive"
                                        @click="deleteRule(rule, index)"
                                        color="error"
                                        icon
                                    >
                                        <svg
                                            viewBox="0 0 24 24"
                                            width="20"
                                            height="20"
                                            stroke="currentColor"
                                            stroke-width="1.8"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        >
                                            <polyline points="3 6 5 6 21 6"></polyline>
                                            <path
                                                d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                                            ></path>
                                            <line x1="10" y1="11" x2="10" y2="17"></line>
                                            <line x1="14" y1="11" x2="14" y2="17"></line>
                                        </svg>
                                    </v-btn>
                                </template>
                                <span>Delete rule</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col
                    cols="12"
                    class="flow-logic__item rule-column"
                    v-if="rule.type === 'user_topics'"
                >
                    <v-row>
                        <v-col>
                            <div class="display-custom d-inline-block">
                                User
                                <v-select
                                    :items="userTopicsDropdownData"
                                    v-model="rule.has_topics"
                                    item-value="value"
                                    item-text="title"
                                    single-line
                                    :rules="requiredRules"
                                    class="d-inline-block"
                                    :disabled="pathwayActive"
                                    hide-details
                                    dense
                                    outlined
                                    style="margin-left: 5px; margin-right: 5px; width: 100px;"
                                ></v-select>
                                topics
                            </div>

                            <topic-dropdown
                                class="d-inline-block"
                                v-model="rule.topics"
                                :showLabel="false"
                                :solo="false"
                                :rules="requiredRules"
                                style="position: relative; bottom: 0px; margin-left: 5px; margin-right: 5px; width: 500px;"
                                :dense="true"
                            ></topic-dropdown>
                        </v-col>
                        <v-col cols="auto">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        depressed
                                        v-on="on"
                                        v-bind="attrs"
                                        class="d-inline-block"
                                        v-if="!pathwayActive"
                                        @click="deleteRule(rule, index)"
                                        color="error"
                                        icon
                                    >
                                        <svg
                                            viewBox="0 0 24 24"
                                            width="20"
                                            height="20"
                                            stroke="currentColor"
                                            stroke-width="1.8"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        >
                                            <polyline points="3 6 5 6 21 6"></polyline>
                                            <path
                                                d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                                            ></path>
                                            <line x1="10" y1="11" x2="10" y2="17"></line>
                                            <line x1="14" y1="11" x2="14" y2="17"></line>
                                        </svg>
                                    </v-btn>
                                </template>
                                <span>Delete rule</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col
                    cols="12"
                    class="flow-logic__item rule-column"
                    v-if="rule.type === 'saved_content'"
                >
                    <v-row>
                        <v-col>
                            <div class="display-custom d-inline-block">
                                User
                                <v-select
                                    :items="userContentDropdownData"
                                    v-model="rule.user_saved_content"
                                    item-value="value"
                                    item-text="title"
                                    single-line
                                    :rules="requiredRules"
                                    class="d-inline-block"
                                    :disabled="pathwayActive"
                                    hide-details
                                    dense
                                    outlined
                                    style="margin-left: 5px; margin-right: 5px; width: 100px;"
                                ></v-select>
                            </div>
                            <input type="hidden" v-model="rule.user_saved_content" />
                            <content-dropdown
                                class="d-inline-block"
                                v-model="rule.content"
                                :show-label="false"
                                :solo="false"
                                :default-content="defaultContent"
                                :all-content="allContent"
                                :rules="requiredRules"
                                style="position: relative; bottom: 5px; margin-left: 5px; margin-right: 5px; width: 500px;"
                            ></content-dropdown>
                        </v-col>
                        <v-col cols="auto">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        depressed
                                        v-on="on"
                                        v-bind="attrs"
                                        class="d-inline-block"
                                        v-if="!pathwayActive"
                                        @click="deleteRule(rule, index)"
                                        color="error"
                                        icon
                                    >
                                        <svg
                                            viewBox="0 0 24 24"
                                            width="20"
                                            height="20"
                                            stroke="currentColor"
                                            stroke-width="1.8"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        >
                                            <polyline points="3 6 5 6 21 6"></polyline>
                                            <path
                                                d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                                            ></path>
                                            <line x1="10" y1="11" x2="10" y2="17"></line>
                                            <line x1="14" y1="11" x2="14" y2="17"></line>
                                        </svg>
                                    </v-btn>
                                </template>
                                <span>Delete rule</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                </v-col>
            </template>
            <template v-else>
                <v-col cols="12 pa-1 pl-0">
                    <rule-builder
                        :pathway="pathway"
                        :parent-node="parentNode"
                        :node="node"
                        :nodes="nodes"
                        :rules="keyBy(node.id, rule)"
                        :groupIndex="index"
                        @rulesChanged="updateLocalRules($event)"
                        @removeRuleByIndex="removeRuleByIndex($event)"
                    ></rule-builder>
                </v-col>
            </template>

            <div class="flow-logic__item-divider" v-if="localRules.rules[index + 1]">
                <span v-if="localRules.connector === 'all'">And</span>
                <span v-else>Or</span>
            </div>
        </v-row>
    </div>
</template>
<script>
export default {
    name: 'rule-builder',
    props: ['pathway', 'node', 'parentNode', 'nodes', 'rules', 'index', 'groupIndex'],
    mounted() {
        let questionSetNodes = _.cloneDeep(this.pathway.nodes).filter(el => {
            return el.content.content_type.name === 'QuestionSet';
        });

        this.questionSets = questionSetNodes.map(el => {
            return el.content;
        });

        this.localRules.rules.forEach((rule, index) => {
            this.questionSetData[index] = { answers: [] };

            if (rule.question_id) {
                this.loadAnswersForQuestion(rule.question_id, index);
            }
        });

        this.questionSets.forEach((data, index) => {
            this.questionSetItems.push({ header: data.title });

            data.questions.forEach(item => {
                this.questionSetItems.push({
                    id: item.id,
                    text: item.text
                });
            });
        });

        this.ruleTypesList.push({
            title: 'Answer Chosen in question set',
            value: 'answer_chosen'
        });

        if (this.parentNode.multi_content) {
            this.ruleTypesList.push({
                title: 'User saved content',
                value: 'saved_content'
            });
        }

        axios.get('/admin/node_content/' + this.parentNode.id).then(res => {
            this.defaultContent.splice(0, this.defaultContent.length, ...res.data.currentContent);
            this.allContent.splice(0, this.allContent.length, ...res.data.allContent);
            this.overlay = false;
        });
    },
    data() {
        return {
            connectors: [{ text: 'All', value: 'all' }, { text: 'Any', value: 'any' }],
            questionSets: [],
            questionSetItems: [],
            questionSetData: {},
            defaultContent: [],
            allContent: [],
            questions: [],
            localRules: this.rules[this.node.id] ?? { connector: 'all', rules: [] },
            answers: [{ text: 'Answer 1', value: 1 }, { text: 'Answer 2', value: 2 }],
            ruleTypesList: [{ title: 'User has topic(s)', value: 'user_topics' }],
            userTopicsDropdownData: [
                { title: 'has', value: true },
                { title: 'does not have', value: false }
            ],
            userContentDropdownData: [
                { title: 'saved', value: true },
                { title: 'did not save', value: false }
            ],
            requiredRules: [value => !!value || 'Required.'],
            typeMap: {
                QuestionSet: 'question',
                Iframe: 'iframe',
                Video: 'video',
                Text: 'article',
                Card: 'card',
                Link: 'link'
            }
        };
    },
    methods: {
        addRule(data) {
            this.localRules.rules.push(data);
            this.localRules.rules.forEach((rule, index) => {
                if (!this.questionSetData[index]) {
                    this.questionSetData[index] = { answers: [] };
                }
            });
        },
        deleteRule(rule, index) {
            this.localRules.rules.splice(index, 1);
        },
        addGroup() {
            this.localRules.rules.push({
                connector: 'all',
                rules: []
            });
        },
        deleteGroup() {
            this.$emit('removeRuleByIndex', { index: this.groupIndex });
        },
        removeRuleByIndex($event) {
            this.localRules.rules.splice($event.index, 1);
        },
        localRulesContainType(type) {
            for (let i = 0; i < this.localRules.rules.length; i++) {
                if (this.localRules.rules[i].type === type) {
                    return true;
                }
            }

            return false;
        },
        loadAnswersForQuestion(questionId, index) {
            this.nodes.forEach(node => {
                if (node.content.content_type.name === 'QuestionSet') {
                    let content = node.content;
                    node.content.questions.forEach(question => {
                        if (questionId === question.id) {
                            this.localRules.rules[index].question_set_id = content.id;
                            this.questionSetData[index]['answers'] = question.answers;
                        }
                    });
                }
            });
        },
        updateLocalRules($event) {
            this.localRules.rules[$event.index] = $event.rulesList;
            this.$emit('rulesChanged', {
                node: this.node,
                rulesList: this.localRules,
                index: this.groupIndex
            });
        },
        keyBy(keyText, data) {
            let arr = {};
            arr[keyText] = data;
            return arr;
        }
    },
    computed: {
        isNested() {
            return this.groupIndex >= 0;
        },
        lastRuleType() {
            if (!this.localRules.length) {
                return null;
            }

            return this.localRules[this.localRules.length - 1].type;
        },
        pathwayActive() {
            return this.pathway.active === 1;
        }
    },
    watch: {
        localRules: {
            deep: true,

            handler(newValue) {
                this.$emit('rulesChanged', {
                    node: this.node,
                    rulesList: newValue,
                    index: this.groupIndex
                });
            }
        }
    }
};
</script>

<style scoped lang="scss">
.display-custom {
    font-size: 0.95rem !important;
    height: 1px !important;
}
</style>
