<template>
    <header class="ctt-header" :class="{'has-back': showBackButton}">
        <!-- we keep this div here, so that the logout option stays in place in the header -->
        <div class="ctt-header__back">
            <template v-if="showBackButton">
                <a @click="goBack">
                    <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    >
                        <path d="M13 7.00001H1"></path>
                        <path d="M7.00001 13L1 7.00001L7.00001 1"></path>
                    </svg>
                    <span>Back</span>
                </a>
            </template>
        </div>

        <div id="logo">
            <div class="ctt-header__slogan__big">AARP Care To Talk?&trade; conversation cards</div>
        </div>

        <div class="profile-nav" v-if="!sharedDeckFrom"></div>


        <button
            class="ctt-header__burger"
            aria-label="Menu"
            onclick="document.getElementById('ctt-mobile-nav').classList.toggle('active');"
        ></button>
        <deck-mobile-nav></deck-mobile-nav>
    </header>
</template>
<script>
import { mapGetters } from 'vuex';
import collectorActions from '../../../mixins/collectorActions';

export default {
    name: 'deck-header',
    mixins: [collectorActions],
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            guest: 'guest',
            showBackButton: 'showBackButton',
            sharedDeckFrom: 'sharedDeckFrom'
        })
    },
    methods: {
        goBack() {
            if (history.length > 1) {
                history.back();
            } else {
                window.location.href = "/dashboard";
            }
        }
    },
    watch: {}
};
</script>

<style lang="scss"></style>
