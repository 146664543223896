//import collectorActions from "./collectorActions";
import { mapGetters } from 'vuex';

export default {
    //mixins: [collectorActions],
    methods: {
      checkIfGuestNeedsReward(guest) {
          console.log('running');
          if (guest.user_fedid && guest.earned_reward_date != null && ! guest.awarded_reward_date) {
              console.log('about to check guest reward status');
              if (AARP.store.getState().user.rewardsUser) {
                  console.log('Giving Reward To Guest');
                  this.giveRewardToGuest(guest);
              } else {
                  this.$store.commit('SET_SHOW_REWARDS_MODAL', true);
              }
          } else console.log('WHAYAYFDSHASFY',guest.earned_reward_date,guest.awarded_reward_date,guest.id);
      },
      distributeReward(guest) {
          if (! guest.awarded_reward_date && ! guest.earned_reward_date) {
              // this sets the earned_reward_date timestamp
              axios.post('/guest/api/rewards/earn').then(response => {
                  if (AARP.store.getState().user.rewardsUser && guest.user_fedid) {
                      this.giveRewardToGuest(guest);
                  }
              });
          }
      },
      giveRewardToGuest() {
          console.log('Firing Custom Event', window.authUser.user_fedid);
          window.dispatchEvent(new CustomEvent('loyalty-deposit-points', {
              detail: {
                  userIdentifier: window.authUser.user_fedid,
                  activityIdentifier: '81683d00-ae56-430b-8f37-39795200b63e',
              }
          }));
          console.log('CARE-APP: Handling reward-awarded event');
          // set awarded_reward_date to current timestamp
          axios.post('/guest/api/rewards');
      }
    },
    computed: {
        ...mapGetters({
            guest: 'guest'
        })
    },
};
