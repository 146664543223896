import Form from '../Form';

class ContentForm extends Form {
    constructor(data) {
        if (!data) {
            data = {
                title: '',
                content_type_id: '',
                description: '',
                url: '',
                path: null,
                thumbnail: null,
                thumbnail_path: null,
                thumbnail_sm: null,
                featured: false,
                settings: {},
                preface: null,
                content_type: {
                    id: '',
                    name: ''
                },
                questions: []
            };
        }

        if (!data.topics) {
            data.topics = [];
        }

        if (
            !data.settings.allow_next &&
            ['Iframe', 'Video', 'Card', 'Link'].includes(data.content_type.name)
        ) {
            data.settings.allow_next = false;
        }

        super(data);
    }

    isIframe() {
        return this.content_type.name === 'Iframe';
    }

    isVideo() {
        return this.content_type.name === 'Video';
    }

    isQuestionSet() {
        return this.content_type.name === 'QuestionSet';
    }

    isArticle() {
        return this.content_type.name === 'Article';
    }

    isCard() {
        return this.content_type.name === 'Card';
    }

    isText() {
        return this.content_type.name === 'Text';
    }

    isLink() {
        return this.content_type.name === 'Link';
    }
}

export default ContentForm;
