<template>
    <div id="flow-editor">
        <v-overlay :value="pathway.active" absolute z-index="9999">
            <v-alert color="error"
                >The pathway flow cannot be modified while the pathway is active.</v-alert
            >
        </v-overlay>
        <div id="flow-frame">
            <div
                class="subframe"
                ref="frame"
                @drop="onDrop($event)"
                @dragover.prevent
                @dragenter.prevent
            >
                <template v-for="node in nodes">
                    <div
                        v-if="node.deleted === undefined"
                        :style="{
                            transform: 'translate(' + node.x + 'px,' + node.y + 'px)',
                            backgroundImage: 'url(' + node.content.thumbnail_xs + ')'
                        }"
                        class="slide-around node-pill"
                        @mouseover="$set(hovering, node.id, true)"
                        @mouseout="$set(hovering, node.id, false)"
                        draggable="true"
                        @dragstart="startNodeDrag($event, node.id)"
                        @drop="onDrop($event, node)"
                    >
                        <div class="node-text">
                            {{ node.multi_content ? 'MULTI CONTENT' : node.name }}
                        </div>

                        <div
                            class="split-rules-button"
                            v-if="node.next.length > 1"
                            @click="openRulesModal(node)"
                        >
                            <v-badge avatar bordered overlap>
                                <template v-slot:badge>
                                    <v-icon size="30">call_split</v-icon>
                                </template>
                            </v-badge>
                        </div>
                        <a role="button" class="delete-button" @click="deleteNodeRequest(node.id)"
                            ><v-icon>mdi-delete</v-icon></a
                        >
                        <a role="button" class="info-button" @click="showNodeInfo(node)"
                            ><v-icon>info</v-icon></a
                        >

                        <a
                            role="button"
                            class="test-button"
                            :href="'/admin/pathways/' + pathway.id + '/test/' + node.id"
                            target="_blank"
                            ><v-icon>play_circle_outline</v-icon></a
                        >
                    </div>
                </template>
                <svg>
                    <line
                        v-for="line in lines"
                        :x1="line[0]"
                        :y1="line[1]"
                        :x2="line[2]"
                        :y2="line[3]"
                        class="lineline"
                        :class="{ linelineHover: hovering[line[5]] }"
                        :style="{ stroke: line[4] }"
                    />
                    <line
                        v-if="snap"
                        v-for="n in 100"
                        :x1="n * blockSize"
                        :y1="0"
                        :x2="n * blockSize"
                        :y2="1000"
                        class="gridline"
                    />
                </svg>
            </div>
        </div>
        <div id="content-pick">
            <h2 class="text-center">Content</h2>
            <v-row class="mx-0 justify-center">
                <v-col md="3" class="new-content">
                    <v-btn color="primary" small text @click="openContentModal"
                        >+ Create New <br />
                        Content</v-btn
                    >
                </v-col>
                <v-col
                    md="3"
                    v-for="item in content"
                    :key="item.id"
                    class="content-drag"
                    draggable="true"
                    @dragstart="startContentDrag($event, item)"
                    :style="{
                        background: `url(${item.thumbnail_xs})`,
                        'background-size': 'cover'
                    }"
                >
                    <h5 class="content-title">{{ item.title }}</h5>
                </v-col>
            </v-row>
        </div>

        <split-rules-modal
            v-if="rulesModal"
            v-bind="rulesModalProperties"
            @splitRulesUpdated="updateSplitRulesForNode($event)"
            @modalClosed="rulesModal = false"
        ></split-rules-modal>

        <content-editor-modal
            v-if="contentModal"
            v-bind="contentModalProperties"
            :settings="settings"
            @modalClosed="contentModal = false"
            :zip-file-names="zipFileNames"
        ></content-editor-modal>

        <node-info-modal
            v-if="showNodeInfoModal"
            v-bind="nodeInfoModal"
            @modalClosed="showNodeInfoModal = false"
            @deleteContent="removeMultiContentRequest($event)"
        ></node-info-modal>
    </div>
</template>
<script>
//UNCOMMENT FOR PAPER const paper = require('paper')
import PathwayForm from '../../../classes/Form/PathwayForm';
import OrganizeFlow from '../../../mixins/OrganizeFlow';
import { mapGetters } from 'vuex';

export default {
    name: 'pathway-flow',
    //components: { },
    props: ['pathway', 'types', 'settings', 'zipFileNames'],
    mixins: [OrganizeFlow],
    mounted() {
        let counter = 0;
        let nodesLength = this.pathway.nodes.length;
        this.pathway.nodes.forEach(node => {
            node.next = node.children ?? [];

            this.nodes.push(
                this.createNode(
                    node.id,
                    node.next,
                    node.settings.x,
                    node.settings.y,
                    node.content_id,
                    false,
                    node
                )
            );

            counter++;

            if (counter === nodesLength) {
                this.drawLines();
            }
        });
    },
    data() {
        return {
            //temporary var to hold an id counter; we'll replace with ids from backend later
            tracers: 1,
            maxColumnIndex: 0,
            blockSize: 100,
            snap: true,
            nodes: [],
            lines: [],
            hovering: {},
            rulesModalProperties: {},
            contentModalProperties: {},
            contentModal: false,
            rulesModal: false,
            nodeInfoModal: {},
            showNodeInfoModal: false
        };
    },
    computed: {
        ...mapGetters({
            content: 'contentList'
        })
    },
    methods: {
        updateSplitRulesForNode(event) {
            let index = _.findIndex(this.nodes, { id: event.node_id });
            this.nodes[index].split_rules = event.rules;
        },
        showNodeInfo(node) {
            this.showNodeInfoModal = true;
            this.nodeInfoModal.node = node;
            this.nodeInfoModal.dialog = true;
        },
        createNodeRequest(data) {
            this.$emit('overlay', true);

            return axios
                .post('/admin/nodes', data)
                .then(response => {
                    this.$emit('overlay', false);
                    this.pathway.nodes = response.data.pathway.nodes;

                    return response.data.node;
                })
                .catch(e => {
                    this.$emit('overlay', false);
                });
        },
        removeMultiContentRequest(contentData) {
            let nodeIndex = this.nodes.findIndex(x => x.id === contentData.node_id);
            let contentKey = Object.keys(contentData)[0];

            let data = this.nodes[nodeIndex];
            data.content_id = data.contentId;
            data.remove_multi_content = contentKey;
            data.pathway_id = this.pathway.id;

            axios
                .put('/admin/nodes/' + this.nodes[nodeIndex].id, data)
                .then(response => {
                    this.$emit('overlay', false);

                    this.nodes[nodeIndex].multi_content = response.data.node.multi_content;
                    this.nodes[nodeIndex].content_id = response.data.node.content_id;
                    this.nodes[nodeIndex].content_id2 = response.data.node.content_id2;
                    this.nodes[nodeIndex].content_id3 = response.data.node.content_id3;

                    this.nodes.splice(nodeIndex, 1, this.nodes[nodeIndex]); // we must do this to update DOM state
                })
                .catch(e => {
                    this.$emit('overlay', false);
                });
        },
        updateNodeRequest(node) {
            let data = {
                id: node.id,
                pathway_id: this.pathway.id,
                content_id: node.content.id,
                content_id2: node.content_id2,
                content_id3: node.content_id3,
                split_rules: node.split_rules ?? null,
                children: node.next,
                settings: {
                    x: node.x,
                    y: node.y
                }
            };

            this.$emit('overlay', true);

            axios
                .put('/admin/nodes/' + node.id, data)
                .then(response => {
                    this.$emit('overlay', false);
                    let index = _.findIndex(this.nodes, { id: response.data.node.id });
                    this.nodes[index].multi_content = response.data.node.multi_content;

                    this.nodes.splice(index, 1, this.nodes[index]); // we must do this to update DOM state
                })
                .catch(e => {
                    this.$emit('overlay', false);
                });
        },
        deleteNodeRequest(nodeId) {
            if (confirm('Delete node?')) {
                axios
                    .delete('/admin/nodes/' + nodeId)
                    .then(response => {
                        this.$emit('overlay', false);
                        let index = this.nodes.findIndex(x => x.id === nodeId);

                        if (index > -1) {
                            /**
                             *  we set a deleted property on the node rather than removing it from the array
                             *  so that the css transition does not move nodes around, which could confuse users
                             */
                            this.nodes[index].deleted = true;
                        }

                        this.removeLinesForNode(nodeId);

                        // remove all entries of the nodeId from the "next" array from parent nodes
                        if (response.data.removedFromParents.length) {
                            response.data.removedFromParents.forEach(parentId => {
                                let parentIndex = this.nodes.findIndex(x => x.id === parentId);

                                this.nodes[parentIndex].next.splice(
                                    this.nodes[parentIndex].next.indexOf(nodeId),
                                    1
                                );

                                if (this.nodes[parentIndex].split_rules) {
                                    this.nodes[parentIndex].split_rules.splice(
                                        this.nodes[parentIndex].split_rules.indexOf(nodeId),
                                        1
                                    );
                                }
                            });
                        }
                    })
                    .catch(e => {
                        this.$emit('overlay', false);
                    });
            }
        },
        removeLinesForNode(nodeId) {
            let lineIndexesToBeRemoved = [];

            this.lines.forEach((line, index) => {
                if (line[5] === nodeId || line[6] === nodeId) {
                    lineIndexesToBeRemoved.push(index);
                }
            });

            while (lineIndexesToBeRemoved.length) {
                this.lines.splice(lineIndexesToBeRemoved.pop(), 1);
            }
        },
        createNode(id, next, x, y, contentId, sendRequest = true, nodeDbData = false) {
            let name = '';
            let thumbnail = '';
            let content = {};
            if (typeof id == 'undefined') id = null;
            if (typeof x == 'undefined') x = 0;
            if (typeof y == 'undefined') y = 0;
            if (typeof contentId != 'undefined') {
                content = this.content.find(element => element.id == contentId);
                name = content.title;
                thumbnail = content.thumbnail;
            } else {
                contentId = null;
                name = 'node ' + id;
            }

            let nodeData = {
                id: id,
                contentId: content.id,
                next: next,
                x: x,
                y: y,
                plotted: false,
                column: 0,
                name: name,
                content: nodeDbData ? nodeDbData.content : content,
                split_rules: nodeDbData.split_rules
            };

            if (nodeDbData) {
                nodeData.content_id2 = nodeDbData.content_id2;
                nodeData.content_id3 = nodeDbData.content_id3;
                nodeData.multi_content = nodeDbData.multi_content;
            }

            if (sendRequest) {
                this.createNodeRequest({
                    id: id,
                    pathway_id: this.pathway.id,
                    content_id: content.id,
                    origin: this.nodes.length === 0,
                    split_rules: null,
                    children: next,
                    settings: {
                        x: x,
                        y: y
                    }
                }).then(item => {
                    nodeData.id = item.id;
                    nodeData.content.questions = item.content.questions;
                });
            }

            return nodeData;
        },
        startContentDrag: (evt, content) => {
            evt.dataTransfer.dropEffect = 'copy';
            evt.dataTransfer.effectAllowed = 'copy';

            evt.dataTransfer.setData('type', 'content');
            evt.dataTransfer.setData('contentId', content.id);
            evt.dataTransfer.setData('contentType', content.content_type.name);
        },
        startNodeDrag: (evt, nodeId) => {
            evt.dataTransfer.dropEffect = 'move';
            evt.dataTransfer.effectAllowed = 'move';
            evt.dataTransfer.setData('type', 'node');
            evt.dataTransfer.setData('nodeId', nodeId);
        },
        onDrop(evt, childNode) {
            let type = evt.dataTransfer.getData('type');
            let contentId = evt.dataTransfer.getData('contentId');
            let contentType = evt.dataTransfer.getData('contentType');

            let nodeId = evt.dataTransfer.getData('nodeId');
            //SNAPPING
            let dropX = evt.layerX;
            let dropY = evt.layerY;

            if (this.snap) {
                dropX -= dropX % this.blockSize;
            } else {
                //account for size of box; it positions top left corner
                dropX -= 50;
            }
            dropY -= 25;

            if (type === 'content' && typeof childNode != 'undefined') {
                let index = _.findIndex(this.nodes, { id: childNode.id });
                if (
                    contentType !== 'QuestionSet' &&
                    this.nodes[index].content.content_type.name !== 'QuestionSet'
                ) {
                    if (index > -1) {
                        this.nodes[index].multi_content = true;

                        if (!this.nodes[index].content_id2) {
                            this.nodes[index].content_id2 = parseInt(contentId);

                            this.updateNodeRequest(this.nodes[index]);
                        } else if (!this.nodes[index].content_id3) {
                            this.nodes[index].content_id3 = parseInt(contentId);

                            this.updateNodeRequest(this.nodes[index]);
                        } else {
                            // we don't allow more that 3 pieces of content per node
                            evt.preventDefault();
                            evt.stopPropagation();
                            return;
                        }
                    }
                } else {
                    evt.preventDefault();
                    evt.stopPropagation();

                    alert('QuestionSet can not be used in multi content node');

                    return;
                }

                evt.preventDefault();
                evt.stopPropagation();
            } else if (type == 'content' && typeof childNode == 'undefined') {
                this.nodes.push(this.createNode(nodeId, [], dropX, dropY, contentId));
            } else if (type == 'node' && typeof childNode != 'undefined') {
                //draw a line and connect the nodes
                let node = this.nodes.find(element => element.id == nodeId);

                // if you drag a node and drop it on itself, don't make it a child of itself
                if (node.id === childNode.id) {
                    evt.preventDefault();
                    evt.stopPropagation();
                    return;
                }

                // if the node is already a parent of the child node, do not push it again
                if (node.next.includes(childNode.id)) {
                    alert('This node is already connected');
                    evt.preventDefault();
                    evt.stopPropagation();
                    return;
                }

                // if the child node is mistakenly dragged to the parent, show an alert saying a child cannot be a parent of its parent
                if (childNode.next.includes(node.id)) {
                    alert('A child node cannot be a parent to its parent');
                    evt.preventDefault();
                    evt.stopPropagation();
                    return;
                }

                node.next.push(childNode.id);

                let colour = this.randomColor();
                this.lines.push([
                    node.x + 50,
                    node.y + 25,
                    childNode.x + 50,
                    childNode.y + 25,
                    colour,
                    node.id,
                    childNode.id
                ]);

                this.updateNodeRequest(node);

                evt.preventDefault();
                evt.stopPropagation();
            } else if (type == 'node') {
                let node = this.nodes.find(element => element.id == nodeId);
                node.x = dropX;
                node.y = dropY;

                this.updateNodeRequest(node);

                this.drawLines();
            }
        },
        drawLines() {
            //now let's draw lines
            this.lines = [];
            for (let f = 0; f < this.nodes.length; f++) {
                let colour = this.randomColor();
                let parentNode = this.nodes[f];
                for (let n = 0; n < parentNode.next.length; n++) {
                    let childNode = this.nodes.find(element => element.id == parentNode.next[n]);
                    this.lines.push([
                        parentNode.x + 50,
                        parentNode.y + 25,
                        childNode.x + 50,
                        childNode.y + 25,
                        colour,
                        parentNode.id,
                        childNode.id
                    ]);
                }
            }
        },
        randomColor() {
            let colours = [
                'pink',
                'orange',
                'blue',
                'green',
                'red',
                'lightblue',
                'lightgreen',
                'purple'
            ];
            return colours[this.getRandomIntInclusive(0, colours.length - 1)];
        },
        getRandomIntInclusive(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min + 1) + min); //The maximum is inclusive and the minimum is inclusive
        },
        openRulesModal(node) {
            this.rulesModalProperties = {
                pathway: this.pathway,
                node: node,
                nodes: this.nodes,
                dialog: true
            };

            this.rulesModal = true;
        },
        openContentModal() {
            this.contentModalProperties = {
                dialog: true,
                types: this.types
            };

            this.contentModal = true;
        }
    }
};
</script>

<style lang="scss">
.slide-around {
    transition: all 0.2s;
}

.lineline {
    stroke-width: 3;
    opacity: 0.5;
    z-index: 1000;

    &:hover {
        opacity: 1;
        stroke-width: 5;
        cursor: pointer;
    }
}

.linelineHover {
    stroke-width: 5;
    opacity: 0.8;
}

.gridline {
    stroke-width: 1;
    stroke: rgba(55, 55, 55, 0.1);
}

.toolbar {
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: 1005;
    height: 30px;
    overflow: hidden;
}

.node-pill {
    width: 86px;
    margin-left: 7px;
    height: 50px;
    border-radius: 15px;
    background-size: cover;
    position: absolute;
    display: flex;
    z-index: 1000;

    &:hover .delete-button {
        display: block;
    }

    .delete-button {
        position: absolute;
        top: -9px;
        right: -10px;
        outline: none;
        text-decoration: none;
        font-size: 4px;
        background: #fff;
        border-radius: 40px;
        padding: 2px;
        border: 1px solid #999;
        cursor: pointer;
        z-index: 220;
        display: none;

        &:hover {
            opacity: 0.8;
        }

        i {
            color: #fa0000;
            font-size: 16px;
        }
    }

    &:hover .info-button {
        display: block;
    }

    .info-button {
        position: absolute;
        top: 15px;
        right: -10px;
        outline: none;
        text-decoration: none;
        font-size: 4px;
        background: #fff;
        border-radius: 40px;
        padding: 2px;
        border: 1px solid #999;
        cursor: pointer;
        z-index: 220;
        display: none;

        &:hover {
            opacity: 0.8;
        }

        i {
            color: deepskyblue;
            font-size: 16px;
        }
    }

    &:hover .test-button {
        display: block;
    }

    .test-button {
        position: absolute;
        top: 18px;
        right: 70px;
        outline: none;
        text-decoration: none;
        font-size: 4px;
        background: #fff;
        border-radius: 40px;
        padding: 2px;
        border: 1px solid #999;
        cursor: pointer;
        z-index: 220;
        display: none;

        &:hover {
            opacity: 0.8;
        }

        i {
            color: deepskyblue;
            font-size: 16px;
        }
    }

    &:hover .split-rules-button {
        display: block;
    }

    .split-rules-button {
        position: absolute;
        cursor: pointer;
        left: 5px;
        top: -15px;
        display: none;

        &:hover {
            opacity: 0.8;
        }
    }
}

.node-text {
    font-size: 12px;
    text-align: center;
    line-height: 15px;
    vertical-align: middle;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.8);
    width: 100%;
}

#content-pick {
    width: 30%;
    height: 100%;
    border-left: 1px solid #ccc;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 200;
    background-color: rgba(255, 255, 255, 0.8);
    overflow: auto;

    .new-content {
        height: 110px;
        margin: 5px;
        border: 1px solid #aaa;
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .content-drag {
        width: 200px;
        height: 110px;
        margin: 5px;
        border: 1px solid #aaa;
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: relative;

        .content-title {
            position: absolute;
            z-index: 230;
            color: #fff;
            font-size: 13px;
            text-shadow: 0 0 4px black;
            word-break: break-word;
        }

        img {
            width: 100%;
            height: auto;
            margin: 3px auto;
            position: relative;
            display: block;
            max-height: 100%;
        }
    }
}

#flow-editor {
    position: relative;
    height: 60vh;
    flex-direction: row;
    width: 100%;
    border: 1px solid #ccc;
}

#flow-frame {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 200;
    overflow: auto;

    .subframe {
        height: 200%;
        width: 150%;
        position: relative;
        z-index: 200;

        svg {
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 900;
            overflow: auto;
        }
    }
}
</style>
