<template>
    <div class="onboard-quiz__cont">
        <form @submit.prevent="submitEmail" method="POST" class="onboard-callout">
            <h1 class="tc--secondary onboard-callout__title">You’re all set!</h1>

            <!--  @todo apply changes here -->
            <h2 class="onboard-callout__subtitle">
                Enter your email to access your<br />
                personalized card deck
            </h2>

            <p class="onboard-callout__label">We will email you a link to create your account</p>

            <div class="input onboard-callout__input">
                <input
                    type="email"
                    v-model="email"
                    placeholder="Enter your email address..."
                    class="input__input"
                />
            </div>

            <p class="tc--primary" v-show="message !== ''">{{ message }}</p>

            <button
                type="submit"
                id="onboard-intro__button"
                class="button button--rounded button--primary onboard-callout__button"
            >
                <span>Access my card deck</span>
                <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2.4"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                >
                    <line x1="12" y1="4" x2="12" y2="20"></line>
                    <polyline points="18 14 12 20 6 14"></polyline>
                </svg>
            </button>
            <div class="p p--xs onboard-callout__footnote">
                By registering with AARP, you’re accepting
                <a href="/aarp/terms-and-conditions" class="text-link">terms and conditions</a> of
                our platform.
            </div>
        </form>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import collectorActions from '../../../mixins/collectorActions';

export default {
    mixins: [collectorActions],
    data() {
        return {
            message: '',
            email: ''
        };
    },
    methods: {
        submitEmail() {
            this.$store.dispatch('submitEmail', this.email).then(res => {
                this.submitEmailAction(this.email, this.pathway.id);

                if (res.data.message) {
                    this.message = res.data.message;
                    this.email = '';
                } else {
                    window.location = '/#caregiving_resources';
                }
            });
        }
    },
    computed: {
        ...mapGetters({
            guest: 'guest',
            pathway: 'pathway'
        })
    },
    watch: {}
};
</script>
