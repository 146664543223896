<template>
    <div
        class="grid-col--4 grid-col--md-6 grid-col--sm-12 resource-container grid-col--xs-12"
        @click="goToResource"
    >
        <div class="grid-row grid-row--nog resource-content">
            <div class="grid-col--12 resource-thumbnail-container">
                <img :src="resource.thumbnail_xs" alt="thumb" class="resource-thumbnail"/>
            </div>

            <div class="grid-col--12 resource-details">
                <div
                    class="resource-type"
                    style="margin-bottom: 9px !important;"
                    v-text="typeMap[resource.content_type.name]"
                ></div>
                <h5>{{ resource.title }}</h5>
                <p>{{ resource.resource_description }}</p>

                <a @click.prevent="goToResource" role="button" tabindex="0">
                    {{resource.content_type.name === 'Video' ? 'Watch Video >' : 'View resource >'}}
                </a>
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters} from 'vuex';
import collectorActions from '../../../mixins/collectorActions';

export default {
    name: 'caregiving-resource',
    props: ['inGrid', 'resource', 'type'],
    mixins: [collectorActions],
    data() {
        return {
            typeMap: {
                Link: 'Caregiving Resource',
                Video: 'Care To Talk Video'
            }
        };
    },
    computed: {
        ...mapGetters({
            guest: 'guest',
            deckTab: 'deckTab',
            guestCards: 'guestCards',
            deckLoading: 'deckLoading',
            sharedDeckFrom: 'sharedDeckFrom'
        }),
        cssCategory() {
            return this.resource.content_type.name.toLowerCase();
        }
    },
    methods: {
        goToResource() {
            if (this.resource.content_type.name === 'Link') {
                window.open(this.resource.url, '_blank');
            } else {
                window.location = '/content/' + this.resource.slug;
            }
        }
    }
};
</script>

<style lang="scss">
.resource-content-holder {
    h1 {
        font-size: 50px;
        font-weight: 400;
        padding: 10px 20px 20px;
        width: 100%;
    }
}

.resource-row {
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;

    &:last-child {
        padding-bottom: 50px;
    }

    h3 {
        padding-left: 15px;
        font-size: 40px;
        font-weight: 400;
        width: 100%;
    }
}

.resource-container {
    padding: 15px;
}

.resource-content {
    background-color: #F9F8F6;
    align-content: start;
    height: 100%;
    border-radius: 5px;

    .resource-details {
        letter-spacing: 0.05rem;
        color: #37383D;
        .resource-type {
            color: #EC1300;
            font-weight: 900;
            font-size: 16px;
            text-transform: uppercase;
        }

        h5 {
            font-size: 24px;
            font-weight: normal;
            margin-bottom: 10px;
        }

        p {
            font-size: 16px;
            margin-bottom: 30px;
        }

        a {
            font-size: 16px;
            font-weight: 900;
            color: #EC1300;
        }
    }
}

.border-link {
    border: 1.5px solid #891f5e;

    &:hover {
        box-shadow: 0 0 8px rgba(137, 31, 94, 0.7);
    }
}

.border-video {
    border: 1.5px solid #f16b65;

    &:hover {
        box-shadow: 0 0 8px rgba(241, 107, 101, 0.7);
    }
}

.resource-thumbnail-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .resource-thumbnail {
        border-radius: 5px 5px 0 0;
        width: 100%;
    }
}

.resource-details {
    margin-left: 0;
    padding: 20px 15px;
}

.category-title {
    text-transform: none;
    font-size: 16px;
}

.title-link {
    color: #891f5e;
}

.title-video {
    color: #f16b65;
}

@media screen and (max-width: 600px) {
    .title-link,
    .title-video {
        font-size: 14px;
    }
}
</style>
