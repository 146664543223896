<template>
    <div>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <v-snackbar color="success" top v-model="snackbar" :timeout="1500">
            Topic updated!
            <v-icon dark>check_circle</v-icon>
        </v-snackbar>
        <v-snackbar color="error" top v-model="snackbarError" :timeout="1500">
            There are errors in form, please fix them.
            <v-icon dark>highlight_off</v-icon>
        </v-snackbar>

        <div class="admin-header">
            <v-row>
                <div class="col-auto">
                    <back-button url="/admin/topics"></back-button>
                </div>
                <div class="display-1 col-auto">{{ existingTopic ? 'Edit' : 'Create' }} topic</div>

                <v-spacer></v-spacer>

                <div class="grid-col grid-col--auto d-flex align-center">
                    <save-button @click="saveTopic"></save-button>
                </div>
            </v-row>
        </div>

        <v-form ref="form" v-model="valid" @submit.prevent="saveTopic">
            <v-row>
                <v-col cols="12" md="12" sm="12" xs="12">
                    <v-text-field
                        label="Topic"
                        :rules="[v => !!v || 'Please enter topic name']"
                        placeholder="Enter Topic name"
                        v-model="form.name"
                        :error-messages="form.errors.first('topic')"
                        outlined
                    ></v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="auto">
                    <save-button @click="saveTopic"></save-button>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>
<script>
import TopicForm from '../../../classes/Form/TopicForm';

export default {
    name: 'topic-c-u',
    props: ['existingTopic'],
    created() {
        if (this.existingTopic) {
            this.form = new TopicForm(this.existingTopic);
        }
    },
    data() {
        return {
            topic: {},
            form: new TopicForm(),
            valid: false,
            snackbar: false,
            snackbarError: false,
            overlay: false
        };
    },
    methods: {
        saveTopic() {
            if (this.$refs.form.validate()) {
                this.overlay = true;
                if (this.existingTopic) {
                    this.form
                        .put('/admin/topics/' + this.form.id)
                        .then(response => {
                            this.snackbar = true;
                            this.overlay = false;

                            this.form = new TopicForm(response.topic);
                        })
                        .catch(e => {
                            this.overlay = false;
                            this.snackbarError = true;
                        });
                } else {
                    this.form
                        .post('/admin/topics')
                        .then(response => {
                            window.location = '/admin/topics';
                        })
                        .catch(e => {
                            this.overlay = false;
                            this.snackbarError = true;
                        });
                }
            }
        }
    },
    watch: {}
};
</script>
