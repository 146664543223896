<template>
    <div>
        <v-row justify="center" v-if="disabledMessage" style="width: 100%">
            <v-alert type="error" v-html="disabledMessage"></v-alert>
        </v-row>
        <v-layout>
            <v-flex xs12 justify-center align-start style="position: relative;">
                <div class="form-overlay" v-if="disabledMessage"></div>
                <v-form style="width: 100%" disabled readonly>
                    <v-row justify="center">
                        <v-col md="6" sm="8">
                            <div class="title">Questions</div>
                            <v-btn
                                small
                                color="info"
                                class="white--text float-left"
                                @click.native="dialog = true"
                                v-if="questions.length > 1"
                            >
                                Sort questions
                            </v-btn>
                        </v-col>
                        <v-col md="2" sm="4">
                            <div>
                                <div>
                                    <v-btn
                                        color="blue-grey"
                                        class="white--text float-right"
                                        dusk="addQuestionButton"
                                        @click.native="addQuestionBtnHandler()"
                                    >
                                        Add
                                        <v-icon right>add_box</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </v-col>
                    </v-row>

                    <v-layout row>
                        <question-editor
                            v-for="(question, i) in questions"
                            :question="question"
                            :ref="'questionEditor#' + i"
                            :content="content"
                            :key="i"
                            :inc="i"
                            @changed="updateQuestion(i, $event)"
                            @deleted="deleteQuestion(i, $event)"
                            :all-topics-data="allTopicsData"
                        ></question-editor>
                    </v-layout>
                    <br />
                </v-form>
            </v-flex>

            <v-flex>
                <template>
                    <v-dialog v-model="dialog" persistent max-width="700px">
                        <v-card>
                            <v-card-title class="headline">Sort questions</v-card-title>
                            <v-card-text>
                                <v-col>
                                    <v-layout wrap>
                                        <v-flex xs12>
                                            <draggable
                                                :list="questions"
                                                class="list-group"
                                                ghost-class="ghost"
                                                @end="questionMoved()"
                                            >
                                                <div
                                                    class="list-group-item"
                                                    v-for="(question, index) in questions"
                                                    :key="question.title"
                                                >
                                                    {{ index + 1 }}. {{ question.text }}
                                                </div>
                                            </draggable>
                                        </v-flex>
                                    </v-layout>
                                </v-col>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="error" text @click="cancelQuestionOrder()"
                                    >Cancel</v-btn
                                >
                                <v-btn color="primary" text @click="saveQuestionOrder()"
                                    >Save</v-btn
                                >
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </template>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
import QuestionEditor from './QuestionEditor';
import draggable from 'vuedraggable';

export default {
    props: ['content', 'disabledMessage', 'allTopicsData'],
    components: {
        draggable,
        QuestionEditor
    },
    created() {
        this.questionList = _.cloneDeep(this.content.questions);

        if (this.content.questions.length) {
            this.questions = this.content.questions;
        }
    },
    data: function() {
        return {
            questions: [
                {
                    id: null,
                    text: '',
                    order: 0,
                    answers: []
                }
            ],
            dialog: false,
            questionList: [],
            id: -1
        };
    },
    methods: {
        addQuestionBtnHandler() {
            let lastQuestion = this.questions[this.questions.length - 1];

            let order = lastQuestion ? lastQuestion.order : 0;

            this.questions.push({
                id: null,
                text: '',
                order: order + 1,
                answers: []
            });
        },
        updateQuestion(i, question) {
            this.questions[i] = question;
        },
        deleteQuestion(i, question) {
            this.questions[i].deleted = true;

            if (question.id) {
                axios.delete('/admin/questions/' + question.id + '').then(res => {
                    this.questions[i].deleted = true;
                });
            }
        },
        saveQuestionOrder() {
            if (this.content.id) {
                axios
                    .patch(
                        '/admin/questions/update_order/' + this.content.id,
                        this.content.questions
                    )
                    .then(res => {
                        this.dialog = false;
                    })
                    .catch(err => {
                        this.dialog = false;
                    });
            } else {
                this.dialog = false;
            }
        },
        cancelQuestionOrder() {
            this.questions = _.cloneDeep(this.questionList);
            this.dialog = false;
        },
        questionMoved() {
            this.questions.map(function(question, index) {
                question.order = index + 1;
            });
        },
        validate() {}
    },
    watch: {
        questions: {
            handler(value) {
                this.content.questions = value;
            },
            deep: true
        },
        'content.questions': {
            handler(value) {
                this.questions = value;
            },
            deep: true
        }
    }
};
</script>

<style lang="scss">
.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}

.list-group {
    width: 100%;
}

.list-group-item {
    border: 1px solid #ccc;
    padding: 5px;
    cursor: all-scroll;
    margin-bottom: 5px;
}

.form-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: #fff;
    opacity: 0.5;
    z-index: 4;
}
</style>
