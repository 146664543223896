<template>
    <v-row class="justify-content-center">
        <v-col sm="12" class="text-center pt-0">
            <iframe
                :src="content.url"
                :width="content.settings.width"
                :height="content.settings.height"
            ></iframe>
        </v-col>
    </v-row>
</template>
<script>
export default {
    name: 'iframe-preview',
    props: ['content'],
    data() {
        return {};
    },
    methods: {}
};
</script>

<style lang="scss">
iframe {
    border: 1px solid #e2f0f9;
    max-width: 100%;
}
</style>
