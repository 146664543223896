<template>
    <div>
        <main-header></main-header>
        <pathway-subheader
            :content-type="node.content ? node.content.content_type.name : ''"
        ></pathway-subheader>

        <div class="grid--row">
            <div class="grid-col grid-col--auto">
                <button
                    type="button"
                    id="btn-quiz-go-back"
                    class="button button--xs onboard-quiz__back"
                    @click="revertChoice = true"
                    dusk="previousNode"
                    v-if="!node.origin"
                >
                    <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2.4"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    >
                        <line x1="19" y1="12" x2="5" y2="12"></line>
                        <polyline points="11 18 5 12 11 6"></polyline>
                    </svg>
                    <span>Back</span>
                </button>
            </div>
        </div>

        <pathway-nodes
            :original-node="originalNode"
            :revert-choice="revertChoice"
            :existing-feedback="existingFeedback"
            @updateRevertChoice="revertChoice = false"
            :existing-content-feedback="existingContentFeedback"
        ></pathway-nodes>

        <pathway-notes :disabled="disableNotes"></pathway-notes>

        <pathway-footer></pathway-footer>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import collectorActions from '../../../mixins/collectorActions';
import Tour from '../../../mixins/Tour';

export default {
    name: 'pathway-view',
    props: [
        'originalPathway',
        'originalNode',
        'originalGuest',
        'originalLatestAnswer',
        'existingFeedback',
        'existingContentFeedback'
    ],
    mixins: [collectorActions, Tour],
    mounted() {
        this.$store.commit('SET_PATHWAY', this.originalPathway);
        this.$store.commit('SET_GUEST', this.originalGuest);

        if (this.originalLatestAnswer) {
            this.$store.commit('SET_LATEST_ANSWER', this.originalLatestAnswer);
        }

        this.openPathwayAction(this.originalPathway.id);

        if (APP_ENV !== 'testing' && APP_ENV !== 'dusk') {
            setTimeout(() => {
                if (!this.tourIsCompleteFor('pathway-view', this.guest.id)) {
                    this.disableNotes = true;

                    this.$nextTick(() => {
                        const tour = this.$shepherd({
                            useModalOverlay: true,
                            defaultStepOptions: {
                                scrollTo: { behavior: 'smooth', block: 'center' },
                                popperOptions: {
                                    modifiers: [{ name: 'offset', options: { offset: [0, 20] } }]
                                }
                            }
                        });

                        tour.on('complete', () => {
                            this.completeTourForGuest('pathway-view', this.guest.id);
                            this.disableNotes = false;
                        });

                        this.getTourStepsForPathwayView(tour).forEach(step => {
                            tour.addStep(step);
                        });

                        tour.start();
                    });
                }
            }, 100);
        }
    },
    data() {
        return {
            revertChoice: false,
            disableNotes: false
        };
    },
    computed: {
        ...mapGetters({
            pathway: 'pathway',
            guest: 'guest',
            node: 'node'
        })
    },
    methods: {},
    watch: {}
};
</script>
