<template>
    <div :class="`ctt-card border-${cssCategory} ${inListCss}`">
        <div :class="`ctt-card__category ctt-card__category--${cssCategory}`">
            {{ card.settings.category }}
        </div>
        <div class="ctt-card__cont">
            <h3 class="h2--sm">{{ card.title }}</h3>
            <p class="ctt-card__submitted" v-if="card.suggested_by">
                Submitted by {{ card.suggested_by }}
            </p>
        </div>
        <div class="ctt-card__foot">
            <template v-if="deckTab === 'customize'">
                <button
                    class="ctt-card__add-to-deck"
                    v-if="!hasCard"
                    @click="addCardToDeck()"
                    data-categoryid="caregiving"
                    data-subcategoryid="care to talk"
                    :data-formelementid="`CARE_TO_TALK-BTN-CLK-CARD_ADD_TO_DECK-${card.title.replaceAll(' ', '_')}`"
                >
                    + Add to My Card Deck
                </button>
                <template v-else>
                    <div class="ctt-card__added-to-deck">In Your Deck</div>
                    <button
                        class="ctt-card__remove-from-deck"
                        @click="removeCardFromDeck()"
                        aria-label="Remove from My Card Deck"
                        tabindex="0"
                        :disabled="deckLoading"
                        data-categoryid="caregiving"
                        data-subcategoryid="care to talk"
                        :data-formelementid="`CARE_TO_TALK-BTN-CLK-CARD_REMOVE_FROM_DECK-${card.title.replaceAll(' ', '_')}`"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="13.828" height="13.828" viewBox="0 0 13.828 13.828">
                            <g id="Group_585" data-name="Group 585" transform="translate(1.414 1.414)">
                                <line id="Line_45" data-name="Line 45" x2="11" y2="11" fill="none" stroke="#37383d" stroke-linecap="round" stroke-width="2"/>
                                <line id="Line_46" data-name="Line 46" x1="11" y2="11" fill="none" stroke="#37383d" stroke-linecap="round" stroke-width="2"/>
                            </g>
                        </svg>
                        Remove
                    </button>
                </template>
            </template>
            <template v-else>
                <div class="card-help">
                    <a
                        @click.stop="goToResources()"
                        aria-label="Customize"
                        tabindex="0"
                        role="button"
                        data-formelementid="CARE_TO_TALK-BTN-CLK-NEED_HELP_LINK"
                        data-categoryid="caregiving"
                        data-subcategoryid="care to talk"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24.3" height="24.3" viewBox="0 0 24.3 24.3">
                            <g id="Group_733" data-name="Group 733" transform="translate(-1486.196 -548.169)">
                                <circle id="Ellipse_53" data-name="Ellipse 53" cx="11.4" cy="11.4" r="11.4" transform="translate(1486.946 548.919)" fill="none" stroke="#2172bf" stroke-miterlimit="10" stroke-width="1.5"/>
                                <text id="_" data-name="?" transform="translate(1494.276 565.034)" fill="#2172bf" font-size="15" font-family="HelveticaNeue-Bold, Helvetica Neue" font-weight="700"><tspan x="2" y="0">?</tspan></text>
                            </g>
                        </svg>
                    Need help navigating <br>conversations?
                    </a>
                </div>

                <button
                    v-if="!sharedDeckFrom"
                    v-tooltip="isFavorite ? `Remove from My Likes` : `Add to My Likes`"
                    class="ctt-card__favourite"
                    :class="{ active: isFavorite }"
                    @click="toggleFavorite()"
                    aria-label="Add/Remove from My Likes"
                    :data-formelementid="isFavorite ? `CARE_TO_TALK-BTN-CLK-CARD_DISLIKE_ACTION-${card.title.replaceAll(' ', '_')}`: `CARE_TO_TALK-BTN-CLK-CARD_LIKE_ACTION-${card.title.replaceAll(' ', '_')}`"
                    data-categoryid="caregiving"
                    data-subcategoryid="care to talk"
                ></button>
                <div class="ctt-card__count">
                    {{ card.favorites_count ? card.favorites_count : '0 ' }}
                    <div style="display: inline">
                        <span :data-localize="caregiverText">{{ caregiverText }}</span>
                        <br>
                        <span data-localize="liked this card">liked this card.</span>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import collectorActions from '../../../mixins/collectorActions';
import Rewards from "../../../mixins/rewards";
export default {
    name: 'deck-card',
    props: ['inGrid', 'card'],
    mixins: [collectorActions,Rewards],
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            guest: 'guest',
            deckTab: 'deckTab',
            guestCards: 'guestCards',
            deckLoading: 'deckLoading',
            sharedDeckFrom: 'sharedDeckFrom',
            addedCardsTotal: 'addedCardsTotal',
            dismissedRewardsModal: 'dismissedRewardsModal',
        }),
        cssCategory() {
            return this.card ? this.card.settings.category.toLowerCase() : '';
        },
        hasCard() {
            return _.findIndex(this.guestCards, { id: this.card.id }) > -1;
        },
        isFavorite() {
            return this.guest.favorites.includes(this.card.id);
        },
        inListCss() {
            return this.inGrid ? 'ctt-card--inlist' : '';
        },
        caregiverText() {
            return `caregiver${this.card.favorites_count !== 1 ? 's' : ''}`;
        }
    },
    methods: {
        toggleFavorite() {
            console.log('this.guest.user_fedid', this.guest.user_fedid);
            console.log('AARP.store.getState().cookie.fedid', AARP.store.getState().cookie.fedid);
            console.log('APP ENV', APP_ENV);
            const canFavorite = (this.guest.user_fedid && AARP.store.getState().cookie.fedid !== undefined) || (this.guest.user_fedid && (APP_ENV !== 'production' || location.origin === 'https://care.livetech.dev'));
            console.log('canFavorite', canFavorite);
            // if we have fedid favorite card, if not force login modal
            if (canFavorite) {
                this.deckFavoriteCardToggle(this.card);
                // if we have 3 likes taken in a single session, distribute rewards
                if (this.addedCardsTotal >= 3) {
                    if (AARP.store.getState().user.rewardsUser) {
                        this.$store.dispatch('toggleFavoriteCard', this.card).then(() => {
                            this.distributeReward(this.guest);
                        });
                    } else {
                        if (this.dismissedRewardsModal) {
                            this.$store.dispatch('toggleFavoriteCard', this.card).then(() => {
                                this.distributeReward(this.guest);
                            });
                        } else {
                            // if rewards modal has not been dismissed previously, show it
                            this.$store.commit('SET_SHOW_REWARDS_MODAL', true);
                        }
                    }
                } else {
                    this.$store.dispatch('toggleFavoriteCard', this.card);
                }
            } else {
                this.$store.commit('SET_SHOW_LOGIN_MODAL', true);
            }
        },
        removeCardFromDeck() {
            this.deckRemoveCard(this.card);
            this.$store.dispatch('removeCardFromDeck', this.card);
        },
        goToResources() {
            this.$store.commit('SET_DECK_TAB', 'caregiving_resources');
            window.location.hash = 'caregiving_resources';
            this.deckCaregivingResources();
        },
    },
    watch: {}
};
</script>

<style lang="scss"></style>
