//note, this is currently tightly coupled with ../components/admin/pathways/flow.js
//just moving the sorting functions here to clean up a bit
// @TODO decouple better
export default {
    methods: {
        beginAutoPlot() {
            //columns = [];
            for (var i = 0; i < this.nodes; i++) {
                //must clear some values or it don't work none good
                this.nodes[i].column = 0;
                this.nodes[i].plotted = false;
            }
            this.maxColumnIndex = 0;
            this.lines = [];
            this.tracers = 1;
            this.autoPlot(1, 0, 1, 1);
        },
        //recursive function, requires set-up, should never be called directly, call beginAutoPlot instead
        autoPlot(column, siblingIndex, childCount, nodeId) {
            //context.tracers++;
            //tracersOnPath++;
            console.log(
                'hit recursion function with column:' +
                    column +
                    ', sibIndex:' +
                    siblingIndex +
                    ', sibCount:' +
                    childCount +
                    ', nodeId:' +
                    nodeId +
                    ', total tracers:' +
                    this.tracers
            );

            var node = this.nodes.find(element => element.id == nodeId);

            var possibleX = this.blockSize * column;
            //unless it has already been moved further down the x axis by another parent...
            //TODO THIS IS REALLY CONFUSING - NODE.COLUMN IS ZERO-INDEXED BUT THE COLUMN PASSED THROUGH THE RECURSIVE FUNCTION IS NOT. MAYBE CALL NODE.COLUMN NODE.COLUMNINDEX INSTEAD?
            var nodeColumn = node.column ? node.column + 1 : 0;
            if (possibleX > nodeColumn * this.blockSize) {
                //push our node to the column that comes after the parent column
                node.x = possibleX;
                //mark it's column explicitly for ease of use later
                node.column = column - 1; //-1 because 0 indexed TODO THIS IS REALLY CONFUSING - NODE.COLUMN IS ZERO-INDEXED BUT THE COLUMN PASSED THROUGH THE RECURSIVE FUNCTION IS NOT. MAYBE CALL NODE.COLUMN NODE.COLUMNINDEX INSTEAD?
            }
            this.maxColumnIndex =
                this.maxColumnIndex > column - 1 ? this.maxColumnIndex : column - 1;
            console.log('at this iteration, maxColumnIndex is ' + this.maxColumnIndex);
            //center it vertically with children
            node.y = this.blockSize * (childCount - siblingIndex);
            //if we are not at a terminal node (eg, it has at least one child),
            //increase the count of tracers by the number of *NEW* paths (hence the minus one - if a pack of dogs
            //are traveling down a road and encounter a fork, and half the pack goes one way and the other the other way,
            //you have increased the number of dog packs on this road by one. Formerly there was one, now there are two.
            //Hence, dogpacks = options at this fork minus one)

            if (node.next.length > 0) this.tracers += node.next.length - 1;
            //call this again for each child
            for (var j = 0; j < node.next.length; j++) {
                //autoPlot(column + 1, j, node.next.length, node.next[j]);
                var context = this;
                setTimeout(context.autoPlot, 250, column + 1, j, node.next.length, node.next[j]);
            }
            //if at a terminal node, dismiss one tracer
            if (node.next.length <= 0) this.tracers--;
            if (this.tracers == 0) {
                //finished recursion
                console.log(
                    'finished x axis recursion with ' + this.maxColumnIndex + ' maxColumnIndex'
                );
                //now do it again!
                // this.tracers = 1;
                // setYPositions(1,0,1,1);
                //actually let's just loop columns

                var columns = [];
                //first let's create an explicit column array
                for (var nn = 0; nn <= this.maxColumnIndex; nn++) {
                    columns.push([]);
                }
                for (var nodeI = 0; nodeI < this.nodes.length; nodeI++) {
                    var node = this.nodes[nodeI];
                    columns[node.column].push(node.id);
                }

                //first loop to get tallest column:
                var maxHeight = 0;
                for (var i = 0; i < columns.length; i++) {
                    maxHeight = columns[i].length > maxHeight ? columns[i].length : maxHeight;
                    console.log(
                        'iterating column ' +
                            i +
                            ', length ' +
                            columns[i].length +
                            ', maxh currently: ' +
                            maxHeight
                    );
                }
                var modifier = maxHeight % 2 == 0 ? 200 : 150;
                maxHeight *= this.blockSize;
                console.log('maxheight iiiiis ' + maxHeight);

                //NOW LOOP TO ARRANGE VERTICALLY
                //but before loop, start with the first node because it'll always be in the cente rand have no sibs
                var firstNode = this.nodes.find(element => element.id == columns[0][0]);
                firstNode.y = Math.round(maxHeight / 2);

                firstNode.y = firstNode.y - (firstNode.y % this.blockSize) + modifier;
                //object containing one array per column. Each array will be populated with descendents that skip at least one generation.
                var stragglers = {};
                //only need to do this for all columns but last, since we organize a given column's node's children on each loop
                for (var i = 0; i < columns.length - 1; i++) {
                    //in the future, we could go back and get the topmost or bottommost ancestor, or try to center between ancestors or stick to them or something
                    //or maybe based on number of paths leading toward? I dunno we could find something cool here
                    //for now just space them out

                    //first, IF THIS IS COLUMN 2 (index = 1), order nodes by whether they have descendents that've skipped generations? actually ideally you'd order them by whether any in their lineage skip generations...

                    //new plan: "make room for my family"
                    // first, get total height of NEXT column after current one (total "house" size for next generation)
                    var column = columns[i];
                    var nextColumn = columns[i + 1];
                    var nextGenY = firstNode.y + (nextColumn.length * this.blockSize) / 2;
                    var nextGenAccountedFor = 0;
                    console.log(
                        'Iterating over nodes in column ' +
                            i +
                            ', length ' +
                            column.length +
                            '. Arranging nodes in column ' +
                            (i + 1) +
                            ', length ' +
                            nextColumn.length
                    );
                    // for each node in current column,
                    for (var j = 0; j < column.length; j++) {
                        // {
                        var node = this.nodes.find(element => element.id == column[j]);

                        //    check stragglers for this column; if any have (parentY > firstNode.y && parenty > this node's y) AND plotted=false, then put them above your children. increase nextGenAccountedFor by number of stragglers you did this for, to account. set plotted = true for that node

                        if (stragglers[i] && stragglers[i].length > 0) {
                            for (var b = 0; b < stragglers[i].length; b++) {
                                if (
                                    stragglers[i][b][0] >= firstNode.y &&
                                    stragglers[i][b][0] >= node.y
                                ) {
                                    //get the straggler
                                    var stragglerNode = this.nodes.find(
                                        element => element.id == stragglers[i][b][1]
                                    );
                                    if (stragglerNode.plotted == false) {
                                        //plot it
                                        stragglerNode.y =
                                            nextGenY - nextGenAccountedFor * this.blockSize;
                                        stragglerNode.plotted = true;
                                        nextGenAccountedFor++;
                                    }
                                }
                            }
                        }

                        //loop through children of this node
                        for (var d = 0; d < node.next.length; d++) {
                            var childNode = this.nodes.find(element => element.id == node.next[d]);
                            //if they are in the next column:
                            if (nextColumn.includes(childNode.id)) {
                                if (childNode.plotted == false) {
                                    //set their y values = nextGenY - (nextGenAccountedFor * this.blockSize). set plotted = true for that node. Increase nextGenAccountedFor by one.
                                    childNode.y = nextGenY - nextGenAccountedFor * this.blockSize;
                                    childNode.plotted = true;
                                    nextGenAccountedFor++;
                                }
                            } else {
                                //if they are not in the next column (skipped generation kids) put in stragglers[columnIndex].push([parentY, id]).
                                for (var s = 0; s < columns.length; s++) {
                                    if (columns[s].includes(childNode.id)) {
                                        if (typeof stragglers[s] == 'undefined') stragglers[s] = [];
                                        stragglers[s].push([node.y, childNode.id]);
                                    }
                                }
                            }
                        }

                        //check stragglers for this column again; if any have (parentY < firstNOde.y && parenty < this node's y) AND plotted = false, then put them below your children, THEN remove them from stragglers array so we don't do it twice. increase nextGenAccountedFor by number of stragglers you did this for, to account. set plotted = true for that node
                        if (stragglers[i] && stragglers[i].length > 0) {
                            for (var b = 0; b < stragglers[i].length; b++) {
                                if (
                                    stragglers[i][b][0] < firstNode.y &&
                                    stragglers[i][b][0] < node.y
                                ) {
                                    //get the straggler
                                    var stragglerNode = this.nodes.find(
                                        element => element.id == stragglers[i][b][1]
                                    );
                                    if (stragglerNode.plotted == false) {
                                        //plot it
                                        stragglerNode.y =
                                            nextGenY - nextGenAccountedFor * this.blockSize;
                                        stragglerNode.plotted = true;
                                        nextGenAccountedFor++;
                                    }
                                }
                            }
                        }

                        //OLD WAY OF DOING IT: just space them out evenly
                        //node.y = (j+1)*this.blockSize;

                        //this doesn't work on timeout for some reason
                        //setTimeout(function(){ node.y = (j+1)*this.blockSize;console.log('trying') },50);
                    }
                }
                this.drawLines();
            }
        }
    }
};
