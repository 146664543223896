<template>
    <v-btn text outlined :href="url">
        <svg
            width="20"
            height="20"
            viewBox="0 0 24 24"
            stroke="#62727B"
            stroke-width="2.4"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
        >
            <line x1="19" y1="12" x2="5" y2="12"></line>
            <polyline points="11 18 5 12 11 6"></polyline>
        </svg>
    </v-btn>
</template>
<script>
export default {
    name: 'back-button',
    props: ['url']
};
</script>
