<template>
    <div>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <div class="admin-header">
            <v-row>
                <div class="display-1 col-auto">Guest Suggestions</div>
            </v-row>

            <v-row>
                <v-col class="col-auto">
                    <v-menu
                        bottom
                        offset-y
                        :close-on-content-click="false"
                        class="filter-menu"
                        max-width="250px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-badge
                                :content="searchTotal"
                                :value="searchTotal"
                                color="red"
                                left
                                overlap
                            >
                                <v-btn text outlined v-bind="attrs" v-on="on">
                                    <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 24 24"
                                        stroke="#62727B"
                                        stroke-width="2.4"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    >
                                        <line x1="3" y1="7" x2="21" y2="7"></line>
                                        <line x1="6" y1="12" x2="18" y2="12"></line>
                                        <line x1="9" y1="17" x2="15" y2="17"></line>
                                    </svg>
                                </v-btn>
                            </v-badge>
                        </template>
                        <v-list>
                            <v-list-item class="filter-menu">
                                <v-list-item-content>
                                    <v-list-item-title class="filter-title"
                                        >Filters</v-list-item-title
                                    >
                                    <v-list-item-subtitle>
                                        <v-radio-group v-model="search.type">
                                            <v-radio
                                                hide-details
                                                dense
                                                value="question"
                                                label="Question"
                                            ></v-radio>
                                            <v-radio
                                                hide-details
                                                dense
                                                value="topic"
                                                label="Topic"
                                            ></v-radio>
                                        </v-radio-group>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>

                        <v-list>
                            <v-list-item class="filter-menu">
                                <v-list-item-content>
                                    <v-list-item-title class="filter-title"
                                        >Approval type</v-list-item-title
                                    >
                                    <v-list-item-subtitle>
                                        <v-radio-group v-model="search.approval_type">
                                            <v-radio
                                                hide-details
                                                dense
                                                value="not_approved"
                                                label="Not Approved"
                                            ></v-radio>
                                            <v-radio
                                                hide-details
                                                dense
                                                value="approved"
                                                label="Approved"
                                            ></v-radio>
                                            <v-radio
                                                hide-details
                                                dense
                                                value="deleted"
                                                label="Deleted"
                                            ></v-radio>
                                        </v-radio-group>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-col>

                <v-col class="col-auto">
                    <v-menu
                        bottom
                        offset-y
                        :close-on-content-click="false"
                        class="filter-menu"
                        max-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn text outlined v-bind="attrs" v-on="on">
                                <svg
                                    width="21"
                                    height="20"
                                    viewBox="0 0 21 19"
                                    fill="#62727B"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1.81611 5.90989H12.7269V3.74443H1.81611C1.21817 3.74443 0.733398 4.2292 0.733398 4.82714C0.733398 5.42508 1.21817 5.90989 1.81611 5.90989Z"
                                    ></path>
                                    <path
                                        d="M1.81611 10.255H12.7269V8.08954H1.81611C1.21817 8.08954 0.733398 8.5743 0.733398 9.17227C0.733398 9.77025 1.21817 10.255 1.81611 10.255Z"
                                    ></path>
                                    <path
                                        d="M1.81611 12.4345C1.21817 12.4345 0.733398 12.9193 0.733398 13.5173C0.733398 14.1153 1.21817 14.6 1.81611 14.6H9.39614C9.31051 14.3363 9.26531 14.0581 9.26531 13.7728C9.26531 13.2951 9.39059 12.8368 9.62459 12.4345H1.81611Z"
                                    ></path>
                                    <path
                                        d="M19.8249 12.8048C19.2903 12.27 18.4233 12.27 17.8886 12.8048L16.7643 13.929V2.22614C16.7643 1.46991 16.1513 0.856934 15.3951 0.856934C14.6389 0.856934 14.0259 1.46995 14.0259 2.22614V13.929L12.9016 12.8048C12.3669 12.2701 11.5 12.27 10.9653 12.8048C10.4305 13.3395 10.4305 14.2064 10.9653 14.7411L14.4267 18.2025C14.9604 18.7364 15.8284 18.7382 16.3635 18.2025L19.8249 14.7411C20.3596 14.2064 20.3597 13.3395 19.8249 12.8048Z"
                                    ></path>
                                </svg>
                            </v-btn>
                        </template>

                        <v-list>
                            <v-list-item class="filter-menu">
                                <v-list-item-content>
                                    <v-list-item-title class="filter-title"
                                        >Sort by</v-list-item-title
                                    >
                                    <v-list-item-subtitle>
                                        <v-radio-group v-model="options.sortBy[0]">
                                            <v-radio
                                                hide-details
                                                dense
                                                value="content"
                                                label="Content"
                                            ></v-radio>
                                            <v-radio
                                                hide-details
                                                dense
                                                value="name"
                                                label="Guest Name"
                                            ></v-radio>
                                            <v-radio
                                                hide-details
                                                dense
                                                value="created_at"
                                                label="Date added"
                                            ></v-radio>
                                        </v-radio-group>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item class="filter-menu">
                                <v-list-item-content>
                                    <v-list-item-title class="filter-title"
                                        >Order</v-list-item-title
                                    >
                                    <v-list-item-subtitle>
                                        <v-radio-group v-model="options.sortDesc[0]">
                                            <v-radio
                                                hide-details
                                                dense
                                                :value="false"
                                                label="Ascending"
                                            ></v-radio>
                                            <v-radio
                                                hide-details
                                                dense
                                                :value="true"
                                                label="Descending"
                                            ></v-radio>
                                        </v-radio-group>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-col>

                <v-col cols="auto" v-if="searchIsActive">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="error" depressed @click="resetSearch" v-on="on">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    enable-background="new 0 0 24 24"
                                    viewBox="0 0 24 24"
                                    fill="white"
                                    width="18px"
                                    height="18px"
                                >
                                    <g>
                                        <path d="M0,0h24v24H0V0z" fill="none" />
                                    </g>
                                    <g>
                                        <g>
                                            <path
                                                d="M12,5V2L8,6l4,4V7c3.31,0,6,2.69,6,6c0,2.97-2.17,5.43-5,5.91v2.02c3.95-0.49,7-3.85,7-7.93C20,8.58,16.42,5,12,5z"
                                            />
                                            <path
                                                d="M6,13c0-1.65,0.67-3.15,1.76-4.24L6.34,7.34C4.9,8.79,4,10.79,4,13c0,4.08,3.05,7.44,7,7.93v-2.02 C8.17,18.43,6,15.97,6,13z"
                                            />
                                        </g>
                                    </g>
                                </svg>
                            </v-btn>
                        </template>
                        <span>Reset search</span>
                    </v-tooltip>
                </v-col>
            </v-row>
        </div>

        <div class="admin-table">
            <v-data-table
                :headers="headers"
                :items="suggestions"
                :options.sync="options"
                :footer-props="{ 'items-per-page-options': [20, 50, 75, 100] }"
                :loading="loading"
                :server-items-length="totalSuggestions"
                :items-per-page="20"
                class="elevation-1"
            >
                <template v-slot:no-data>
                    <div>No data</div>
                </template>
                <template v-if="suggestions.length" v-slot:body="{ items }">
                    <tbody>
                        <tr v-for="item in items" :key="item.uuid">
                            <td>{{ item.guest.name ? item.guest.name : item.guest.uuid }}</td>
                            <td>
                                {{
                                    item.suggestable_type.split('\\').pop() === 'Content'
                                        ? 'Card question'
                                        : 'Topic'
                                }}
                            </td>
                            <td>{{ item.content }}</td>
                            <td>
                                <template v-if="item.deleted_at"
                                    >Deleted</template
                                >
                                <template v-else>
                                    {{ item.suggestable_id ? item.user.name : 'Not approved' }}
                                </template>
                            </td>
                            <td>
                                <v-row no-gutters align="center" v-if="!item.deleted_at">
                                    <edit-button
                                        v-if="!item.approved_by"
                                        :url="`/admin/suggestions/${item.id}/edit`"
                                        text="Review"
                                    ></edit-button>
                                    <delete-button
                                        v-if="!item.approved_by"
                                        @click="deleteSuggestion(item)"
                                    ></delete-button>
                                </v-row>
                            </td>
                            <td>{{ renderLocalTime(item.created_at) }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-data-table>
        </div>
    </div>
</template>
<script>
import Time from '../../../mixins/Time';
import DeleteButton from '../buttons/DeleteButton';

export default {
    components: { DeleteButton },
    props: ['authUser'],
    mixins: [Time],
    mounted() {
        this.getDataFromApi();
    },
    data() {
        return {
            suggestions: [],
            loading: false,
            search: {
                type: false,
                approval_type: 'not_approved'
            },
            overlay: false,
            totalSuggestions: 0,
            headers: [
                { text: 'Submitted by', value: 'guest_name', sortable: false },
                { text: 'Type', value: 'suggestable_type', sortable: true },
                { text: 'Text', value: 'content', sortable: true },
                { text: 'Approved by', value: 'user_name', sortable: false },
                { text: 'Actions', value: 'guest_name', sortable: false },
                { text: 'Created at', value: 'created_at', sortable: true }
            ],
            options: { sortDesc: [true], sortBy: ['created_at'] }
        };
    },
    computed: {
        searchIsActive() {
            return this.searchTotal > 0;
        },
        searchTotal() {
            let total = 0;
            total += this.search.type ? 1 : 0;
            total += this.search.approval_type !== 'not_approved' ? 1 : 0;
            total += this.search.admin ? 1 : 0;
            return total;
        }
    },
    methods: {
        resetSearch() {
            this.search = {
                type: '',
                approval_type: 'not_approved'
            };

            this.options.sortBy[0] = 'created_at';
            this.options.sortDesc[0] = true;

            this.getDataFromApi();
        },
        getDataFromApi: _.debounce(function() {
            this.loading = true;
            this.overlay = true;

            let endpoint = '/admin/suggestions/json';
            let descendingQuery =
                this.options.sortDesc[0] !== undefined
                    ? '&descending=' + this.options.sortDesc[0]
                    : '';
            let queries =
                '?page=' +
                this.options.page +
                '&rowsPerPage=' +
                this.options.itemsPerPage +
                '&sortBy=' +
                this.options.sortBy +
                descendingQuery;

            if (this.search.type) {
                queries += '&type=' + this.search.type;
            }

            if (this.search.approval_type) {
                queries += '&approval_type=' + this.search.approval_type;
            }

            axios
                .get(endpoint + queries)
                .then(response => {
                    this.suggestions = response.data.data;
                    this.totalSuggestions = response.data.total;
                    this.loading = false;
                    this.overlay = false;
                })
                .catch(function(error) {
                    console.error(error);
                });
        }, 100),
        deleteSuggestion(suggestion) {
            if (confirm('Are you sure you want to delete suggestion?')) {
                axios.delete('/admin/suggestions/' + suggestion.id).then(res => {
                    this.getDataFromApi();
                });
            }
        }
    },
    watch: {
        search: {
            handler() {
                this.getDataFromApi();
            },
            deep: true
        },
        page: {
            handler() {
                this.getDataFromApi();
            }
        },
        options: {
            handler() {
                this.getDataFromApi();
            },
            deep: true
        }
    }
};
</script>
