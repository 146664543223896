import ErrorBag from './ErrorBag';

class Form {
    /**
     * Create a new Form instance.
     *
     * @param {object} data
     */
    constructor(data) {
        this.originalData = data;

        for (let field in data) {
            if (this.isDateProperty(field)) {
                continue;
            }

            this[field] = data[field];
        }

        this.errors = new ErrorBag();
    }

    /**
     * Set FormData object
     *
     * @return {object} FormData
     */
    formData(requestType) {
        const isFile = input => 'File' in window && input instanceof File;
        let formData = new FormData();

        formData.append('_method', requestType);

        for (let property in this.originalData) {
            if (property === 'questions') {
                formData.append(property, JSON.stringify(this[property]));
                continue;
            }

            if (this.isDateProperty(property)) {
                continue;
            }

            if (typeof this[property] === 'object' && !isFile(this[property])) {
                for (let prop in this[property]) {
                    if (this.isDateProperty(prop)) {
                        continue;
                    }

                    formData.append(property + '[' + prop + ']', this[property][prop]);
                }
            } else {
                formData.append(property, this[property]);
            }
        }

        return formData;
    }

    isDateProperty(property) {
        return ['created_at', 'updated_at', 'deleted_at'].includes(property);
    }

    /**
     * Fetch all relevant data for the form.
     */
    data() {
        let data = {};

        for (let property in this.originalData) {
            data[property] = this[property];
        }

        return data;
    }

    /**
     * Reset the form fields.
     */
    reset() {
        for (let field in this.originalData) {
            this[field] = '';
        }

        this.errors.clearAll();
    }

    /**
     * Send a POST request to the given URL.
     * .
     * @param {string} url
     */
    post(url) {
        return this.submit('post', url);
    }

    /**
     * Send a PUT request to the given URL.
     * .
     * @param {string} url
     */
    put(url) {
        return this.submit('put', url);
    }

    /**
     * Send a PATCH request to the given URL.
     * .
     * @param {string} url
     */
    patch(url) {
        return this.submit('patch', url);
    }

    /**
     * Send a DELETE request to the given URL.
     * .
     * @param {string} url
     */
    delete(url) {
        return this.submit('delete', url);
    }

    /**
     * Submit the form.
     *
     * @param {string} requestType
     * @param {string} url
     */
    submit(requestType, url) {
        return new Promise((resolve, reject) => {
            axios
                .post(url, this.formData(requestType))
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    this.onFail(error.response.data.errors);

                    reject(error.response.data);
                });
        });
    }

    /**
     * Handle a failed form submission.
     *
     * @param {object} errors
     */
    onFail(errors) {
        this.errors.setErrors(errors);
    }
}

export default Form;
