<template>
    <v-btn depressed class="float-right" :href="url" color="primary">
        <svg
            width="20"
            height="20"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2.4"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
        >
            <line x1="12" y1="5" x2="12" y2="19"></line>
            <line x1="5" y1="12" x2="19" y2="12"></line>
        </svg>
        &nbsp; {{ text }}
    </v-btn>
</template>
<script>
export default {
    props: ['url', 'text'],
    name: 'create-button'
};
</script>
