<template>
    <div>
        <v-autocomplete
            v-model="selectedContent"
            :items="contentItems"
            chips
            clearable
            deletable-chips
            multiple
            small-chips
            item-text="title"
            item-value="id"
            @input="handleInput"
        ></v-autocomplete>
    </div>
</template>
<script>
export default {
    name: 'content-dropdown',
    props: {
        value: { required: true },
        defaultContent: { default: [] },
        allContent: { default: [] },
        rules: {
            type: [Array],
            default: function() {
                return [];
            }
        }
    },
    mounted() {
        this.selectedContent.splice(0, this.selectedContent.length, ...this.value);

        setTimeout(() => {
            this.handleInput();
        }, 300);
    },
    data() {
        return {
            selectedContent: [],
            contentItems: []
        };
    },
    methods: {
        handleInput() {
            this.$emit('input', this.selectedContent);
        }
    },
    watch: {
        defaultContent: {
            handler: function(newVal) {
                newVal.forEach((item, index) => {
                    if (index === 0) {
                        this.contentItems.push({ header: 'Content in this node' });
                    }
                    this.contentItems.push({
                        id: item.id,
                        title: item.title,
                        group: 'Content in this node'
                    });
                });
            },
            immediate: true,
            deep: true
        },
        allContent: {
            handler: function(newVal) {
                newVal.forEach((item, index) => {
                    if (index === 0) {
                        this.contentItems.push({ header: 'All available content' });
                    }

                    this.contentItems.push({
                        id: item.id,
                        title: item.title,
                        group: 'All available content'
                    });
                });
            },
            immediate: true,
            deep: true
        }
    }
};
</script>
