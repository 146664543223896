window._ = require('lodash');
import { DataCollector } from './DataCollector/DataCollector';

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.dataCollector = new DataCollector();

require('../aarp-uikit/js/main.js');
require('./main2.js');

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';
// window.Pusher = require('pusher-js');
// Pusher.logToConsole = true;
// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: 'websocketkey',
//     wsHost: window.location.hostname,
//     wssHost: window.location.hostname,
//     disableStats: false,
//     encrypted: process.env.MIX_APP_ENV === 'production',
//     enabledTransports: ['ws', 'wss']
// });
