$(document).ready(function() {
    $(window)
        .on('resize', function() {
            const intro_iframe = document.querySelector('.iframe-intro__cont');
            const resource_head = document.querySelector('.resource__head');
            const intro_header = 150;

            if (intro_iframe) {
                intro_iframe.style.height =
                    window.innerHeight - (resource_head.offsetHeight + intro_header) + 'px';
            }

            const iframe_content = document.querySelector('.iframe-cont');
            const iframe = document.querySelector('.iframe-cont iframe');

            if (iframe_content && iframe.getAttribute('height') === '100%') {
                iframe_content.style.height =
                    window.innerHeight - (resource_head.offsetHeight + intro_header + 200) + 'px';
            }
        })
        .resize();

    /**/
    /* tooltip */
    /**/
    $('body').append('<div id="tooltip" class="tooltip"></div>');
    $('[data-tooltip]').on(
        'hover',
        function() {
            var ths = $(this);
            $('#tooltip').html($(this).data('tooltip'));
            $('#tooltip').attr('class', 'tooltip active');
            $('#tooltip').css('top', ths.offset().top);
            $('#tooltip').css('left', ths.offset().left + ths.outerWidth() / 2);
        },
        function() {
            $('#tooltip').removeClass('active');
        }
    );

    var crrntStp = 0;
    var ttlStps = $('.onboard-questions__item').length + $('.onboard-interest__item').length;
    var prgrss = 0;
    var svdRsrcs = 0;

    function updateProgress() {
        crrntStp++;
        prgrss = parseInt((crrntStp * 100) / ttlStps);

        $('.onboard-progress__value').text(prgrss + '%');
        $('.onboard-progress__bar--left').css('width', prgrss + '%');
        $('.onboard-progress__bar--right').css('width', parseInt(100 - prgrss) + '%');
    }

    // $('#onboard-intro__button').on('click', function() {
    //     $(this)
    //         .closest('.onboard__slide')
    //         .addClass('passed')
    //         .next()
    //         .addClass('current');
    // });
    //
    // $('#onboard-quiz__back').on('click', function() {
    //     $(this)
    //         .closest('.onboard__slide')
    //         .removeClass('current')
    //         .prev()
    //         .addClass('current')
    //         .removeClass('passed ');
    // });

    /**/
    /* rfeed welcome */
    /**/
    $('.rfeed-welcome__close').on('click', function() {
        $(this)
            .parent()
            .slideUp(250);
    });

    /**/
    /* rfeed lessons */
    /**/
    // $('.rfeed-lessons__close').on('click', function() {
    //     $(this)
    //         .parent()
    //         .slideUp(250);
    // });
});
