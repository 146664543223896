<template>
    <div class="admin-card">
        <div class="grid-row grid-row--smg grid-row--aic">
            <div class="grid-col">
                <h4 class="h4 h4--sm">{{ pathway.name }}</h4>
            </div>
            <div class="grid-col grid-col--auto" v-if="pathway.slug === 'intro'">
                <div class="admin-card__status admin-card__status--intro">Intro</div>
            </div>
            <div class="grid-col grid-col--auto">
                <div
                    :class="
                        `admin-card__status admin-card__status--${
                            pathway.active ? 'active' : 'inactive'
                        }`
                    "
                >
                    {{ pathway.active ? 'Active' : 'Inactive' }}
                </div>
            </div>
            <div class="grid-col grid-col--auto">
                <a class="admin-card__edit" :href="'/admin/pathways/' + pathway.id + '/edit'">
                    <svg
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    >
                        <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                        <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                    </svg>
                </a>
            </div>
        </div>

        <div class="grid-row">
            <div class="grid-col grid-col--8">
                <p class="mt--050">{{ pathway.description }}</p>
            </div>
        </div>

        <div class="mt--150 grid-row grid-row--aic">
            <div class="grid-col">
                <div class="admin-tags">
                    <div
                        class="admin-tags__item"
                        v-for="topic in pathway.topics"
                        @click="$emit('applySearch', { topics: topic.id })"
                    >
                        {{ topic.name }}
                    </div>
                </div>
            </div>
            <div class="grid-col grid-col--auto">
                <button class="admin-card__toggle" @click="spoilerActive = !spoilerActive">
                    <svg
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        stroke="currentColor"
                        stroke-width="3"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    >
                        <polyline points="6 9 12 15 18 9"></polyline>
                    </svg>
                </button>
            </div>
        </div>

        <div class="admin-card__spoiler" :class="{ active: spoilerActive }">
            <div class="grid-row">
                <div class="mt--075 grid-col grid-col--auto">
                    <div class="p p--xs fw--500 tc--light">Resource Tab:</div>
                    <div class="p p--sm fw--500 tc--dark">
                        {{ pathway.settings.resources_tab ? 'Available' : 'Not available' }}
                    </div>
                </div>

                <div class="mt--075 grid-col grid-col--auto">
                    <div class="p p--xs fw--500 tc--light">Faq Tab:</div>
                    <div class="p p--sm fw--500 tc--dark">
                        {{ pathway.settings.faq_tab ? 'Available' : 'Not available' }}
                    </div>
                </div>

                <div class="mt--075 grid-col grid-col--auto" v-if="pathway.settings.faq_tab">
                    <div class="p p--xs fw--500 tc--light">FAQ URL:</div>
                    <div class="p p--sm fw--500 tc--dark">
                        <a
                            target="_blank"
                            :href="pathway.settings.faq_url"
                            class="text-link text-link--inh"
                            >{{ pathway.settings.faq_url }}</a
                        >
                    </div>
                </div>

                <div class="mt--075 grid-col grid-col--auto">
                    <div class="p p--xs fw--500 tc--light">Pathway URL:</div>
                    <div class="p p--sm fw--500 tc--dark">
                        <a
                            target="_blank"
                            :href="`/pathway/${pathway.slug}`"
                            class="text-link text-link--inh"
                            >/pathway/{{ pathway.slug }}</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'admin-pathway-card',
    props: ['pathway'],
    data() {
        return {
            spoilerActive: false
        };
    },
    methods: {}
};
</script>

<style lang="scss"></style>
