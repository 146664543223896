<template>
    <v-row>
        <v-dialog v-model="localDialog" persistent>
            <v-card>
                <v-card-title class="headline">
                    Content Creator

                    <v-spacer></v-spacer>

                    <v-btn color="error" @click="localDialog = false">
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    <content-editor
                        :modal-form="true"
                        :existing-content="content"
                        :types="types"
                        :settings="settings"
                        @closeModal="localDialog = false"
                        :zip-file-names="zipFileNames"
                        :card-types="cardTypes"
                    ></content-editor>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import ContentEditor from './ContentEditor';

export default {
    name: 'content-editor-modal',
    props: ['existingContent', 'types', 'dialog', 'settings', 'zipFileNames', 'cardTypes'],
    components: { ContentEditor },
    data() {
        return {
            localDialog: this.dialog,
            content: this.existingContent
        };
    },
    methods: {},
    watch: {
        localDialog() {
            this.$emit('modalClosed', this.localDialog);
        }
    }
};
</script>
