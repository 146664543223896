<template>
    <div class="subheader">
        <div class="grid-cont">
            <div class="grid-row grid-row--aic">
                <div class="grid-col">
                    <div class="subheader__title" v-html="pathway.name"></div>
                </div>
                <div class="grid-col grid-col--auto">
                    <a @click.prevent="saveAndExit" dusk="saveAndExit" class="subheader__exit">
                        Save<span class="removed--sm"> Progress</span> &amp; Exit
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import collectorActions from '../../../mixins/collectorActions';

export default {
    name: 'pathway-subheader',
    mixins: [collectorActions],
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            pathway: 'pathway',
            guest: 'guest',
            node: 'node'
        })
    },
    methods: {
        saveAndExit() {
            this.exitPathwayAction(this.pathway.id, this.node.id);
            window.location = '/';
        }
    },
    watch: {}
};
</script>

<style lang="scss">
.subheader {
    margin-top: 49px;
    margin-bottom: 40px;

    .subheader__exit {
        cursor: pointer;
    }
}
</style>
