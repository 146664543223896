var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rfeed-featured",style:(("background-image: url('" + (_vm.featuredContent.thumbnail_featured) + "')"))},[_c('a',{staticClass:"rfeed-featured__link",attrs:{"href":("/content/" + (_vm.featuredContent.slug))}}),_vm._v(" "),_c('div',{staticClass:"rfeed-featured__head"},[_c('div',{staticClass:"rfeed-featured__badge"},[_vm._v("Featured")]),_vm._v(" "),_c('div',{staticClass:"rfeed-featured__date"},[_vm._v(_vm._s(_vm.renderFormDate(_vm.featuredContent.created_at)))]),_vm._v(" "),(_vm.featuredContent.content_type.name === 'Video')?[_c('div',{staticClass:"rfeed-article__bull"}),_vm._v(" "),_c('div',{staticClass:"rfeed-article__date"},[_vm._v("\n                "+_vm._s(_vm.videoDuration(_vm.featuredContent.settings.video_duration))+"\n            ")])]:_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"rfeed-featured__cont"},[_c('h4',{staticClass:"rfeed-featured__title"},[_vm._v("\n            "+_vm._s(_vm.featuredContent.title)+"\n        ")]),_vm._v(" "),_c('p',{staticClass:"rfeed-featured__subtitle",domProps:{"innerHTML":_vm._s(_vm.featuredContent.description_html)}})]),_vm._v(" "),_c('div',{staticClass:"rfeed-featured__actions"},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                _vm.guest && _vm.guest.resource_ids && _vm.guest.resource_ids.includes(_vm.featuredContent.id)
                    ? 'Discard'
                    : 'Save'
            ),expression:"\n                guest && guest.resource_ids && guest.resource_ids.includes(featuredContent.id)\n                    ? 'Discard'\n                    : 'Save'\n            "}],staticClass:"rfeed-featured__action rfeed-featured__action--book",class:{
                active:
                    _vm.guest &&
                    _vm.featuredContent &&
                    _vm.guest.resource_ids &&
                    _vm.guest.resource_ids.includes(_vm.featuredContent.id)
            },attrs:{"type":"button"},on:{"click":function($event){return _vm.bookmarkAction(_vm.guest, _vm.featuredContent)}}}),_vm._v(" "),_c('v-popover',{attrs:{"popoverClass":"share-popover","placement":"top"}},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:("Share"),expression:"`Share`"}],staticClass:"rfeed-featured__action rfeed-featured__action--share",attrs:{"type":"button"}}),_vm._v(" "),_c('template',{slot:"popover"},[_c('share',{attrs:{"content":_vm.featuredContent,"url":_vm.shareUrl}}),_vm._v(" "),_c('a',{directives:[{name:"close-popover",rawName:"v-close-popover"}],staticClass:"close-popover"},[_c('svg',{attrs:{"height":"20px","viewBox":"0 0 512 512","width":"20px","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill":"#666","d":"m256 512c-141.160156 0-256-114.839844-256-256s114.839844-256 256-256 256 114.839844 256 256-114.839844 256-256 256zm0-475.429688c-120.992188 0-219.429688 98.4375-219.429688 219.429688s98.4375 219.429688 219.429688 219.429688 219.429688-98.4375 219.429688-219.429688-98.4375-219.429688-219.429688-219.429688zm0 0"}}),_vm._v(" "),_c('path',{attrs:{"fill":"#666","d":"m347.429688 365.714844c-4.679688 0-9.359376-1.785156-12.929688-5.359375l-182.855469-182.855469c-7.144531-7.144531-7.144531-18.714844 0-25.855469 7.140625-7.140625 18.714844-7.144531 25.855469 0l182.855469 182.855469c7.144531 7.144531 7.144531 18.714844 0 25.855469-3.570313 3.574219-8.246094 5.359375-12.925781 5.359375zm0 0"}}),_vm._v(" "),_c('path',{attrs:{"fill":"#666","d":"m164.570312 365.714844c-4.679687 0-9.355468-1.785156-12.925781-5.359375-7.144531-7.140625-7.144531-18.714844 0-25.855469l182.855469-182.855469c7.144531-7.144531 18.714844-7.144531 25.855469 0 7.140625 7.140625 7.144531 18.714844 0 25.855469l-182.855469 182.855469c-3.570312 3.574219-8.25 5.359375-12.929688 5.359375zm0 0"}})])])],1)],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }