<template>
    <div>
        <div class="mt--200 mb--200 grid-row grid-row--smg grid-row--jcc">
            <div class="grid-col grid-col--auto">
                <a
                    @click="rate('helpful')"
                    dusk="helpfulRate"
                    :class="
                        'button button--xxs' +
                            (existingContentFeedbackLocal &&
                            existingContentFeedbackLocal.rating === 'helpful'
                                ? ' button--active'
                                : '')
                    "
                    ><svg
                        width="35"
                        height="35"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
                            fill="#FFCC4D"
                        ></path>
                        <path
                            d="M10.0004 16.3887C12.4115 16.3887 13.0415 13.9803 13.0476 13.9559C13.0782 13.8348 13.0237 13.7087 12.9148 13.6465C12.8721 13.6226 12.8248 13.6109 12.7782 13.6109C12.7087 13.6109 12.6398 13.637 12.5871 13.687C12.5654 13.7053 11.9948 14.1665 10.0004 14.1665C7.97596 14.1665 7.41818 13.6915 7.41318 13.687C7.32263 13.6059 7.18652 13.5915 7.08207 13.6537C6.97763 13.7165 6.92374 13.8376 6.95318 13.9559C6.9593 13.9803 7.58929 16.3887 10.0004 16.3887Z"
                            fill="#664500"
                        ></path>
                        <path
                            d="M6.23706 8.09131C6.06094 8.09131 5.88372 8.11298 5.71094 8.15575C4.1165 8.54853 3.75928 10.4452 3.74483 10.5263C3.72261 10.6502 3.78706 10.7724 3.90095 10.8263C3.93817 10.8435 3.97872 10.8524 4.01817 10.8524C4.09872 10.8524 4.17761 10.8174 4.23206 10.7524C4.23761 10.7458 4.79317 10.098 6.10928 9.77409C6.50594 9.67631 6.89372 9.62686 7.26094 9.62686C7.87428 9.62686 8.22317 9.76742 8.22483 9.76798C8.26205 9.78353 8.29761 9.7902 8.33983 9.7902C8.49094 9.79686 8.62261 9.66742 8.62261 9.51242C8.62261 9.42075 8.57817 9.33964 8.50983 9.28909C8.30594 9.04742 7.42483 8.09131 6.23706 8.09131ZM13.7637 8.09131C13.9398 8.09131 14.1171 8.11298 14.2898 8.15575C15.8843 8.54853 16.2415 10.4452 16.2559 10.5263C16.2782 10.6502 16.2137 10.7724 16.0998 10.8263C16.0626 10.8435 16.0221 10.8524 15.9826 10.8524C15.9021 10.8524 15.8232 10.8174 15.7687 10.7524C15.7632 10.7458 15.2076 10.098 13.8915 9.77409C13.4948 9.67631 13.1071 9.62686 12.7398 9.62686C12.1265 9.62686 11.7782 9.76742 11.7759 9.76798C11.7387 9.78353 11.7032 9.7902 11.6615 9.7902C11.5104 9.79686 11.3787 9.66742 11.3787 9.51242C11.3787 9.42075 11.4232 9.33964 11.4915 9.28909C11.6948 9.04742 12.5759 8.09131 13.7637 8.09131Z"
                            fill="#664500"
                        ></path></svg
                ></a>
            </div>
            <div class="grid-col grid-col--auto">
                <a
                    @click.prevent="rate('not_interested')"
                    :class="
                        'button button--xxs' +
                            (existingContentFeedbackLocal &&
                            existingContentFeedbackLocal.rating === 'not_interested'
                                ? ' button--active'
                                : '')
                    "
                    ><svg
                        width="35"
                        height="35"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
                            fill="#B6C6D7"
                        ></path>
                        <path
                            d="M14.29 8.68261C14.1172 8.63983 13.94 8.61816 13.7638 8.61816C12.5761 8.61816 11.695 9.57427 11.4916 9.81594C11.4233 9.8665 11.3788 9.94761 11.3788 10.0393C11.3788 10.1943 11.5105 10.3237 11.6616 10.3171C11.7033 10.3171 11.7388 10.3104 11.7761 10.2948C11.7783 10.2943 12.1266 10.1537 12.74 10.1537C13.1072 10.1537 13.495 10.2032 13.8916 10.3009C15.2077 10.6248 15.7633 11.2726 15.7688 11.2793C15.8233 11.3443 15.9022 11.3793 15.9827 11.3793C16.0222 11.3793 16.0627 11.3704 16.1 11.3532C16.2138 11.2993 16.2783 11.1771 16.2561 11.0532C16.2416 10.9721 15.8844 9.07539 14.29 8.68261Z"
                            fill="#778595"
                        ></path>
                        <path
                            d="M13.0469 16.0438C13.0408 16.0193 12.4108 13.611 9.99972 13.611C7.58861 13.611 6.95861 16.0193 6.9525 16.0438C6.92305 16.1621 6.97694 16.2832 7.08138 16.346C7.18583 16.4082 7.32194 16.3938 7.4125 16.3127C7.4175 16.3082 7.97527 15.8332 9.99972 15.8332C11.9942 15.8332 12.5647 16.2943 12.5864 16.3127C12.6392 16.3627 12.708 16.3888 12.7775 16.3888C12.8242 16.3888 12.8714 16.3771 12.9142 16.3532C13.023 16.291 13.0775 16.1649 13.0469 16.0438ZM6.23638 8.61768C6.06027 8.61768 5.88305 8.63934 5.71027 8.68212C4.11583 9.0749 3.75861 10.9716 3.74416 11.0527C3.72194 11.1766 3.78638 11.2988 3.90027 11.3527C3.9375 11.3699 3.97805 11.3788 4.0175 11.3788C4.09805 11.3788 4.17694 11.3438 4.23138 11.2788C4.23694 11.2721 4.7925 10.6243 6.10861 10.3005C6.50527 10.2027 6.89305 10.1532 7.26027 10.1532C7.87361 10.1532 8.22249 10.2938 8.22416 10.2943C8.26138 10.3099 8.29694 10.3166 8.33916 10.3166C8.49027 10.3232 8.62194 10.1938 8.62194 10.0388C8.62194 9.94712 8.57749 9.86601 8.50916 9.81545C8.30527 9.57379 7.42416 8.61768 6.23638 8.61768Z"
                            fill="#778595"
                        ></path></svg
                ></a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'content-feedback',
    props: ['content', 'existingContentFeedback'],
    data() {
        return {
            existingContentFeedbackLocal: this.existingContentFeedback
        };
    },
    methods: {
        rate(rating) {
            axios
                .post('/guest/api/content_feedback/' + this.content.id, {
                    rating: rating
                })
                .catch(response => {
                    console.log(response);
                })
                .then(response => {
                    this.existingContentFeedbackLocal = response.data.contentFeedback;
                });
        }
    }
};
</script>
