import { mapGetters } from 'vuex';

export default {
    props: ['revertChoice'],
    mounted() {
        window.dispatchEvent(new Event('resize'));
        window.addEventListener(
            'message',
            event => {
                //@todo configure list of accepted origins

                let action = event.data.action;
                let data = null;

                if (event.data.name && event.data.name === 'athena-content-api') {
                    if (event.origin === window.location.hostname) {
                        data = event.data.data;
                    }

                    //@todo store the data and action
                    this.$emit('loadNext');
                }
            },
            false
        );
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            content: 'content',
            guest: 'guest',
            pathway: 'pathway'
        }),
        fullIframe() {
            return (
                this.content.settings.height === '100%' && this.content.settings.width === '100%'
            );
        }
    },
    methods: {},
    watch: {
        revertChoice: {
            handler() {
                if (this.revertChoice) {
                    this.$emit('updateRevertChoice', false);
                    this.$emit('backStep');
                }
            },
            immediate: true
        }
    }
};
