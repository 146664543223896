<template>
    <div>
        <v-col cols="auto">
            <div class="flow-drawer" :class="{ 'flow-drawer--expanded': expandedDrawer }">
                <div class="flow-drawer__head">
                    <div class="grid-row grid-row--xsg">
                        <div class="grid-col grid-col--auto">
                            <v-menu
                                bottom
                                offset-y
                                :close-on-content-click="false"
                                class="filter-menu"
                                max-width="250px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-badge
                                        :content="searchTotal"
                                        :value="searchTotal"
                                        color="red"
                                        left
                                        overlap
                                    >
                                        <v-btn text outlined v-bind="attrs" v-on="on">
                                            <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 24 24"
                                                stroke="#62727B"
                                                stroke-width="2.4"
                                                fill="none"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            >
                                                <line x1="3" y1="7" x2="21" y2="7"></line>
                                                <line x1="6" y1="12" x2="18" y2="12"></line>
                                                <line x1="9" y1="17" x2="15" y2="17"></line>
                                            </svg>
                                        </v-btn>
                                    </v-badge>
                                </template>
                                <v-list>
                                    <v-list-item class="filter-menu">
                                        <v-list-item-content>
                                            <v-list-item-title class="filter-title"
                                                >Type</v-list-item-title
                                            >
                                            <v-list-item-subtitle v-for="type in types" :key="type">
                                                <v-checkbox
                                                    hide-details
                                                    dense
                                                    multiple
                                                    :value="type"
                                                    v-model="search.types"
                                                    :label="type"
                                                ></v-checkbox>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item class="filter-menu">
                                        <v-list-item-content>
                                            <v-list-item-title class="filter-title"
                                                >Topics</v-list-item-title
                                            >
                                            <v-list-item-subtitle>
                                                <v-autocomplete
                                                    label="Search by topics"
                                                    v-model="search.topics"
                                                    :items="allTopics"
                                                    chips
                                                    clearable
                                                    deletable-chips
                                                    multiple
                                                    small-chips
                                                    item-text="name"
                                                    item-value="id"
                                                ></v-autocomplete>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item class="filter-menu">
                                        <v-list-item-content>
                                            <v-list-item-title class="filter-title"
                                                >Sort by</v-list-item-title
                                            >
                                            <v-list-item-subtitle>
                                                <v-radio-group v-model="sortOptions.sortBy">
                                                    <v-radio
                                                        hide-details
                                                        dense
                                                        value="title"
                                                        label="Title"
                                                    ></v-radio>
                                                    <v-radio
                                                        hide-details
                                                        dense
                                                        value="created_at"
                                                        label="Date added"
                                                    ></v-radio>
                                                </v-radio-group>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item class="filter-menu">
                                        <v-list-item-content>
                                            <v-list-item-title class="filter-title"
                                                >Order</v-list-item-title
                                            >
                                            <v-list-item-subtitle>
                                                <v-radio-group v-model="sortOptions.desc">
                                                    <v-radio
                                                        hide-details
                                                        dense
                                                        :value="false"
                                                        label="Ascending"
                                                    ></v-radio>
                                                    <v-radio
                                                        hide-details
                                                        dense
                                                        :value="true"
                                                        label="Descending"
                                                    ></v-radio>
                                                </v-radio-group>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                        <div class="grid-col">
                            <v-text-field
                                class="flow-drawer--search"
                                outlined
                                solo
                                dense
                                hide-details
                                append-icon="mdi-magnify"
                                label="Search content"
                                v-model="search.title"
                                @input="getContentFromApi()"
                            ></v-text-field>
                        </div>
                        <div
                            class="col col-auto"
                            :class="{ 'my-1 mx-auto': !expandedDrawer }"
                            :style="expandedDrawer ? 'padding-top: 1px' : ''"
                        >
                            <v-btn
                                depressed
                                class="float-right"
                                @click="openContentModal"
                                color="primary"
                            >
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    stroke-width="2.4"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                >
                                    <line x1="12" y1="5" x2="12" y2="19"></line>
                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                </svg>
                                Create New Content
                            </v-btn>
                        </div>
                    </div>
                </div>

                <div class="flow-drawer__cont">
                    <template v-if="expandedDrawer">
                        <div class="grid-row grid-row--nog grid-row--nowrap">
                            <div
                                class="grid-col grid-col--auto"
                                v-for="(items, type) in content"
                                :key="`expanded-type-${type}`"
                            >
                                <div class="flow-drawer__type">{{ type }}</div>

                                <div
                                    :class="
                                        `flow-card flow-card--short flow-card--${
                                            typeMap[item.content_type.name]
                                        }`
                                    "
                                    v-for="item in items"
                                    :key="`expanded-item-${item.id}`"
                                    draggable="true"
                                    @dragstart="startContentDrag($event, item)"
                                >
                                    <div class="flow-card__name flow-card__name">
                                        {{ item.title }}
                                    </div>
                                    <v-menu
                                        :close-on-content-click="false"
                                        :nudge-width="200"
                                        offset-x
                                        :nudge-right="8"
                                        :z-index="300"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <div
                                                class="flow-card__info"
                                                v-bind="attrs"
                                                v-on="on"
                                            ></div>
                                        </template>
                                        <info-popover
                                            :item="item"
                                            :type-map="typeMap"
                                            :key="`popover-${item.id}`"
                                        ></info-popover>
                                    </v-menu>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div
                            class="flow-card"
                            :class="
                                `flow-card--${typeMap[item.content_type.name]} ${
                                    expandedDrawer ? 'flow-card--short' : ''
                                }`
                            "
                            v-for="item in content"
                            :key="`item-${item.id}`"
                            draggable="true"
                            @dragstart="startContentDrag($event, item)"
                        >
                            <div class="flow-card__name">{{ item.title }}</div>

                            <v-menu
                                :close-on-content-click="false"
                                :nudge-width="200"
                                :nudge-right="8"
                                offset-x
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <div class="flow-card__info" v-bind="attrs" v-on="on"></div>
                                </template>

                                <info-popover
                                    :item="item"
                                    :type-map="typeMap"
                                    :key="`popover-${item.id}`"
                                ></info-popover>
                            </v-menu>
                        </div>
                    </template>
                </div>

                <button class="flow-drawer__toggle" @click="toggleDrawerExpansion"></button>
            </div>
        </v-col>
        <content-editor-modal
            v-if="contentModal"
            v-bind="contentModalProperties"
            :settings="settings"
            @modalClosed="closeContentModal"
            :zip-file-names="zipFileNames"
            :card-types="cardTypes"
        ></content-editor-modal>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import InfoPopover from './InfoPopover';

export default {
    name: 'flow-drawer',
    components: { InfoPopover },
    props: ['pathway', 'types', 'allTopics', 'settings', 'zipFileNames', 'cardTypes'],
    data() {
        return {
            contentModalProperties: {},
            contentModal: false,
            search: {
                title: '',
                types: [],
                topics: []
            },
            sortOptions: {
                sortBy: 'created_at',
                desc: true
            },
            typeMap: {
                Iframe: 'iframe',
                Video: 'video',
                QuestionSet: 'quest',
                Text: 'article',
                Card: 'card',
                Link: 'link'
            },
            expandedDrawer: false
        };
    },
    computed: {
        ...mapGetters({
            content: 'contentList'
        }),
        searchIsActive() {
            return !!(this.search.title || this.search.topics.length || this.search.types.length);
        },
        searchTotal() {
            let total = 0;
            total += this.search.types.length;
            total += this.search.topics.length;
            return total;
        }
    },
    methods: {
        startContentDrag: (evt, content) => {
            evt.dataTransfer.dropEffect = 'copy';
            evt.dataTransfer.effectAllowed = 'copy';

            evt.dataTransfer.setData('type', 'content');
            evt.dataTransfer.setData('contentId', content.id);
            evt.dataTransfer.setData('contentType', content.content_type.name);
        },
        openContentModal() {
            this.contentModalProperties = {
                dialog: true,
                types: this.types,
                allTopics: this.allTopics
            };

            this.contentModal = true;
        },
        closeContentModal() {
            this.contentModal = false;

            this.getContentFromApi();
        },
        toggleDrawerExpansion() {
            this.expandedDrawer = !this.expandedDrawer;
            this.$emit('drawerExpanded');
        },
        getContentFromApi: _.debounce(function() {
            this.$emit('overlayToggle');

            let endpoint = '/admin/content/json';

            let queries =
                '?sortBy=' +
                this.sortOptions.sortBy +
                '&expanded_drawer=' +
                this.expandedDrawer +
                '&descending=' +
                this.sortOptions.desc +
                '&skip_articles=true';

            for (let property in this.search) {
                if (typeof this.search[property] !== 'object') {
                    queries += '&' + property + '=' + this.search[property];
                } else if (
                    typeof this.search[property] === 'object' &&
                    !_.isEmpty(this.search[property])
                ) {
                    for (let prop in this.search[property]) {
                        queries +=
                            '&' + property + '[' + prop + ']' + '=' + this.search[property][prop];
                    }
                }
            }

            axios
                .get(endpoint + queries)
                .then(response => {
                    this.$store.commit('SET_CONTENT_LIST', response.data);
                    this.$emit('overlayToggle');
                })
                .catch(function(error) {
                    console.error(error);
                });
        }, 400)
    },
    watch: {
        search: {
            handler() {
                this.getContentFromApi();
            },
            deep: true
        },
        sortOptions: {
            handler() {
                this.getContentFromApi();
            },
            deep: true
        },
        expandedDrawer: {
            handler() {
                if (this.expandedDrawer) {
                    let groupedContent = _.groupBy(this.content, 'content_type.name');
                    let ordered = {};

                    _(groupedContent)
                        .keys()
                        .sort()
                        .each(function(key) {
                            ordered[key] = groupedContent[key];
                        });

                    groupedContent = ordered;

                    this.$store.commit('SET_CONTENT_LIST', groupedContent);
                } else {
                    this.$store.commit('SET_CONTENT_LIST', _.flatten(_.values(this.content)));
                }
            }
        }
    }
};
</script>

<style lang="scss"></style>
