<style>
.v-data-iterator__actions {
    display: none;
}
</style>
<template>
    <div class="expanded-log">
        <br /><br />
        <v-layout align-center>
            <template v-if="expandType === 'differenceView'">
                <v-flex class="col-4" offset-sm1>
                    <v-data-iterator :items="items" hide-default-footer>
                        <template v-slot:item>
                            <v-card style="margin-top: 10px;" width="100%">
                                <v-card-title style="padding-top: 0px; padding-bottom: 0px;"
                                    ><h4 style="color: black; margin-bottom: 0px;">
                                        Old Values
                                    </h4></v-card-title
                                >
                                <v-divider
                                    style="border-color: #FF5252 !important; border-width: 1.6px;"
                                ></v-divider>
                                <v-list dense>
                                    <v-list-item
                                        v-for="header in Object.keys(getDiffHeaders())"
                                        :key="'old' + header"
                                        v-if="header !== 'content'"
                                    >
                                        <v-list-item-content>{{ header }}</v-list-item-content>
                                        <v-list-item-content
                                            v-if="header == 'created_at' || header == 'updated_at'"
                                            class="align-end"
                                            v-text="
                                                renderLocalTime(log.properties.old_data[header])
                                            "
                                        ></v-list-item-content>
                                        <v-list-item-content
                                            v-else
                                            class="align-end"
                                            v-text="log.properties.old_data[header]"
                                        ></v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card>
                        </template>
                    </v-data-iterator>
                </v-flex>

                <v-flex class="col-4" offset-sm2>
                    <v-data-iterator :items="items" hide-default-footer>
                        <template v-slot:item>
                            <v-card style="margin-top: 10px;" width="100%">
                                <v-card-title style="padding-top: 0px; padding-bottom: 0px;"
                                    ><h4 style="color: black; margin-bottom: 0px;">
                                        New Values
                                    </h4></v-card-title
                                >
                                <v-divider
                                    style="border-color: #4CAE50 !important; border-width: 1.6px;"
                                ></v-divider>
                                <v-list dense>
                                    <v-list-item
                                        v-for="header in Object.keys(getDiffHeaders())"
                                        :key="'attributes' + header"
                                        v-if="header !== 'content'"
                                    >
                                        <v-list-item-content>{{ header }}</v-list-item-content>
                                        <v-list-item-content
                                            v-if="header == 'created_at' || header == 'updated_at'"
                                            class="align-end font-weight-bold"
                                            v-text="
                                                renderLocalTime(log.properties.new_data[header])
                                            "
                                        ></v-list-item-content>
                                        <v-list-item-content
                                            v-else
                                            class="align-end font-weight-bold"
                                            v-text="log.properties.new_data[header]"
                                        ></v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card>
                        </template>
                    </v-data-iterator>
                </v-flex>
            </template>

            <template v-if="expandType === 'singleDiff'">
                <br /><br />
                <v-layout align-center>
                    <v-flex class="col-7" offset-sm2>
                        <v-data-iterator :items="items" hide-default-footer>
                            <template v-slot:item>
                                <v-card style="margin-top: 10px;" width="100%">
                                    <v-card-title style="padding-top: 0px; padding-bottom: 0px;"
                                        ><h4 style="color: black; margin-bottom: 0px;">
                                            Updated Values
                                        </h4></v-card-title
                                    >
                                    <v-divider
                                        style="border-color: #4CAE50 !important; border-width: 1.6px;"
                                    ></v-divider>
                                    <v-list dense>
                                        <v-list-item
                                            v-for="header in Object.keys(log.properties)"
                                            :key="'singleDiffHeader' + header"
                                        >
                                            <v-list-item-content>{{ header }}</v-list-item-content>
                                            <v-list-item-content
                                                v-if="
                                                    header == 'created_at' || header == 'updated_at'
                                                "
                                                class="align-end font-weight-bold"
                                                v-text="renderLocalTime(log.properties[header])"
                                            ></v-list-item-content>
                                            <v-list-item-content
                                                v-else
                                                class="align-end font-weight-bold"
                                            >
                                                <template
                                                    v-if="
                                                        typeof log.properties[header] ===
                                                            'string' ||
                                                            typeof log.properties[header] ===
                                                                'number' ||
                                                            typeof log.properties[header] ===
                                                                'boolean'
                                                    "
                                                >
                                                    {{ log.properties[header] }}
                                                </template>
                                                <template
                                                    v-else
                                                    v-for="(prop, index) in log.properties[header]"
                                                >
                                                    <span v-if="index !== 'content'">
                                                        {{ index }} : {{ prop }}
                                                    </span>
                                                    <br />
                                                </template>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-card>
                            </template>
                        </v-data-iterator>
                    </v-flex>
                </v-layout>
            </template>
        </v-layout>
        <br /><br />
    </div>
</template>
<script>
import { transform, isEqual, isObject } from 'lodash';
import Time from '../../../mixins/Time';

export default {
    props: ['log'],
    mixins: [Time],
    data() {
        return {
            items: [this.log.properties],
            singleLogFields: [],
            changeType: null
        };
    },
    methods: {
        getDiffHeaders() {
            return this.difference(this.log.properties.old_data, this.log.properties.new_data);
        },
        difference(object = {}, base = {}) {
            let self = this;

            return transform(object, (result, value, key) => {
                if (!isEqual(value, base[key])) {
                    result[key] =
                        isObject(value) && isObject(base[key])
                            ? self.difference(value, base[key])
                            : value;
                }
            });
        },
        shouldShowDifferenceView() {
            return (
                this.log.properties.hasOwnProperty('old_data') &&
                Object.keys(this.log.properties.old_data).length &&
                this.log.properties.hasOwnProperty('new_data')
            );
        }
    },
    computed: {
        expandType() {
            if (this.shouldShowDifferenceView()) {
                return 'differenceView';
            }

            return 'singleDiff';
        }
    }
};
</script>

<style lang="scss">
.v-data-iterator {
    width: 100%;
}
</style>
