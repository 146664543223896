<template>
    <v-row style="z-index: 9999">
        <v-dialog v-model="localDialog" persistent>
            <div class="flow-modal">
                <v-alert v-if="pathway.active" color="error" text>
                    The pathway is active and you cannot make changes to it. You can still view the
                    configuration below but in order to make changes you have to deactivate it.
                </v-alert>
                <div class="flow-modal__head">
                    <button class="flow-modal__close" @click="localDialog = false"></button>
                    <h6 class="flow-modal__title tc--body">
                        Split Configuration - {{ node.content.title }}
                    </h6>
                </div>

                <div
                    class="flow-modal__cont"
                    v-for="(node, index) in childNodes"
                    :key="'child#' + node.id"
                >
                    <rule-builder
                        :pathway="pathway"
                        :parentNode="parentNode"
                        :node="node"
                        :nodes="nodes"
                        :rules="rules"
                        :index="index"
                        @rulesChanged="updateRules($event)"
                    ></rule-builder>
                </div>
            </div>
        </v-dialog>
    </v-row>
</template>
<script>
import RuleBuilder from '../split_rules/RuleBuilder';

export default {
    name: 'split-rules-modal',
    props: ['pathway', 'node', 'nodes', 'dialog'],
    components: { RuleBuilder },
    mounted() {
        this.node.next.forEach(nodeId => {
            this.childNodes.push(this.nodes.find(x => x.id === nodeId));
        });
    },
    data() {
        return {
            parentNode: this.node,
            localDialog: this.dialog,
            rules: this.node.split_rules ? this.node.split_rules : {},
            childNodes: []
        };
    },
    methods: {
        updateRules($event) {
            this.rules[$event.node.id] = $event.rulesList;

            if (this.pathway.active) {
                return;
            }

            axios
                .patch('/admin/split_rules/' + this.node.id, { split_rules: this.rules })
                .then(response => {
                    this.$emit('splitRulesUpdated', { node_id: this.node.id, rules: this.rules });
                })
                .catch(error => {
                    alert('Failed to store rules, please reload the page or contact a developer');
                });
        }
    },
    watch: {
        localDialog() {
            this.$emit('modalClosed', this.localDialog);
        }
    }
};
</script>
