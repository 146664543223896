<template>
    <div class="row grid-row--aic">
        <deck-header></deck-header>

        <div class="mt--500 grid-col grid-col--12 removed--xs"></div>

        <div class="grid-cont">
            <transition name="fade" mode="out-in">
                <notification
                    message="Profile successfully updated!"
                    :duration="2000"
                    @close="snackbar = false"
                    v-if="snackbar"
                ></notification>
            </transition>

            <div class="mt--100">
                <profile-editor @profileUpdated="showNotification"></profile-editor>
            </div>
        </div>

        <pathway-footer></pathway-footer>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import collectorActions from '../../../mixins/collectorActions';

export default {
    name: 'profile-view',
    props: ['originalGuest'],
    mixins: [collectorActions],
    mounted() {
        this.$store.commit('SET_GUEST', this.originalGuest);
        this.$store.commit('SET_BACK_TO_FEED', true);
        this.openProfileAction();
    },
    data() {
        return {
            snackbar: false
        };
    },
    computed: {
        ...mapGetters({
            guest: 'guest'
        })
    },
    methods: {
        showNotification() {
            this.snackbar = true;
        }
    },
    watch: {}
};
</script>

<style lang="scss"></style>
