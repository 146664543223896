import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { isObject } from 'vuetify/lib/util/helpers';

export default {
    methods: {
        timePassed(timestamp) {
            if (isObject(timestamp)) {
                return moment.tz(moment.unix(timestamp.seconds), 'UTC').fromNow();
            }

            return moment.tz(timestamp, 'UTC').fromNow();
        },
        localTimezone() {
            return moment.tz.guess();
        },
        renderLocalTime(utcTime) {
            let timezone = this.localTimezone();
            let time = '';

            if (isObject(utcTime)) {
                time = moment.tz(moment.unix(utcTime.seconds), 'UTC');
            } else {
                time = moment.tz(utcTime, 'UTC');
            }

            return time.tz(timezone).format('MMMM Do YYYY, h:mm a');
        },
        renderFormDate(date) {
            return date !== null ? moment(date).format('MMMM Do YYYY') : '';
        },
        videoDuration(d) {
            d = Number(d);
            let h = Math.floor(d / 3600);
            let m = Math.floor((d % 3600) / 60);
            let s = Math.floor((d % 3600) % 60);

            let hDisplay = h > 0 ? h + ' h' : '';
            let mDisplay = m > 0 ? ' ' + m + ' min' : '';
            let sDisplay = s > 0 ? s + (s === 1 ? ' second' : ' seconds') : '';

            if (!mDisplay) {
                return sDisplay;
            }

            return hDisplay + mDisplay;
        },
        UTCTimestampToTimezone(timestamp, timezone) {
            if (timestamp === undefined || timezone === undefined) {
                return '';
            }

            let time = '';

            if (isObject(timestamp)) {
                time = moment.tz(moment.unix(timestamp.seconds), 'UTC');
            } else {
                time = moment.tz(timestamp, 'UTC');
            }

            return time.tz(timezone).format('MMMM Do YYYY, h:mm a');
        },
        currentIsoDate: function() {
            let date = new Date();
            return date.toISOString();
        }
    }
};
