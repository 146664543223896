<template>
    <div class="onboard__slide intro-slide" :class="{ current: isCurrent }">
        <div class="onboard-intro">
            <div class="grid-cont onboard-intro__cont">
                <div class="grid-row grid-row--aic grid-row--jcb">
                    <div
                        class="grid-col grid-col--6 grid-col--md-12 intro-text mb--125"
                        itemscope
                        itemtype="https://schema.org/CreativeWork"
                    >
                        <h1>AARP Care to Talk?&trade; Conversation Cards</h1>
                        <p
                            class="intro-subtitle"
                            itemprop="description"
                        >
                            Caregiving conversations can be difficult. Spark meaningful discussions by using AARP Care
                            To Talk?&trade; conversation cards.
                        </p>

                        <div class="grid-col grid-col--6 grid-col--md-12 intro-video-holder" v-if="isMobile">
                            <youtube
                                class="resource__cover"
                                ref="youtube"
                                :video-id="videoId"
                                player-width="100%"
                                player-height="100%"
                            ></youtube>
                        </div>

                        <button
                            v-if="isMobile"
                            type="button"
                            id="intro__button"
                            dusk="introButton"
                            class="button--rounded onboard-intro__button"
                            @click="showPathway"
                            role="button"
                            aria-labelledby="button-text"
                            data-formelementid="CARE_TO_TALK-BTN-CLK-CREATE_MY_CARD"
                            data-categoryid="caregiving"
                            data-subcategoryid="care to talk"
                        >
                            <span id="button-text">Create my card deck</span>
                        </button>

                        <p class="intro-cards-desc" itemprop="description">
                            What are conversation cards?
                        </p>

                        <ul>
                            <li>
                                Serve as icebreakers to initiate important conversations
                            </li>
                            <li>
                                Assist in discussing current situations and future plans
                            </li>
                            <li>
                                Use one on one with a person you care for, or in a small group
                            </li>
                        </ul>

                        <p class="intro-footer-text"
                           itemprop="description"
                        >
                            Tap 'create my card deck' to answer 3 questions and generate your custom deck.
                        </p>

                        <button
                            v-if="!isMobile"
                            type="button"
                            id="intro__button"
                            dusk="introButton"
                            class="button--rounded onboard-intro__button"
                            @click="showPathway"
                            role="button"
                            aria-labelledby="button-text"
                            data-formelementid="CARE_TO_TALK-BTN-CLK-CREATE_MY_CARD"
                            data-categoryid="caregiving"
                            data-subcategoryid="care to talk"
                        >
                            <span id="button-text">Create my card deck</span>
                        </button>
                    </div>

                    <div class="grid-col grid-col--6 grid-col--md-12 intro-video-holder" v-if="!isMobile">
                        <youtube
                            class="resource__cover"
                            ref="youtube"
                            :video-id="videoId"
                            player-width="100%"
                            player-height="100%"
                        ></youtube>
                    </div>
                </div>
            </div>

            <img src="/img/onboard-intro/cards_bg.png" alt="background" class="bg-image removed--md">
        </div>
    </div>
</template>
<script>
import collectorActions from '../../../mixins/collectorActions';
import { mapGetters } from 'vuex';
import { getIdFromURL } from 'vue-youtube-embed';

export default {
    name: 'pathway-intro-slide',
    props: ['isCurrent'],
    mixins: [collectorActions],
    created() {
        this.videoId = getIdFromURL(this.videoId);
    },
    data() {
        return {
            videoId: 'https://youtu.be/ihhW9LGzdjE'
        };
    },
    computed: {
        ...mapGetters({
            guest: 'guest',
            pathway: 'pathway'
        })
    },
    methods: {
        showPathway() {
            this.$emit('showPathway');
        }
    },
    watch: {}
};
</script>

<style lang="scss">
#intro__button {
    &:focus {
        outline: 1px dotted #111;
    }
}
.onboard-intro--mobile-cards {
    width: 70%;
}


.bg-image {
    position: absolute;
    right: -150px;
    top: 40px;
    height: 100%;
    z-index: 0;
    max-height: 1000px;
}

.onboard-intro__button {
    background: #EC1300;
    color: #fff;
    padding: 15px 55px;
    font-size: 1.25rem;
    font-weight: bold;
    transition: all 0.4s ease-out;

    &:focus {
        outline: 1px dotted #111;
    }

    &:hover {
        background: #AB2125;
    }
}

.background-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 990;
    background: rgba(0, 0, 0, 0.4);
}

.onboard-intro__cards2 {
    cursor: pointer;
    height: 95vh;

    &:focus {
        outline: 1px dashed #ccc;
    }
}
.text-link {
    &.pointer {
        cursor: pointer;
    }
}

.intro-text {
    h1 {
        font-size: 2.25rem;
        margin-bottom: 10px;
        color: #37383D;
    }

    .intro-subtitle {
        font-size: 1.3rem;
        color: #37383D;
        margin-bottom: 40px;
    }

    .intro-footer-text {
        font-size: 1.2rem;
        color: #37383D;
        margin-bottom: 0;
    }

    .intro-cards-desc {
        font-size: 1.3rem;
        color: #37383D;
        font-weight: 600;
        margin-bottom: 20px;
    }

    ul {
        list-style: disc outside;

        li {
            color: #37383D;
            font-size: 1.2rem;
            padding-left: 10px;
            margin-left: 35px;
            font-weight: 300;
        }
    }
}

.intro-video-holder {
    padding-right: 0;
    height: 350px;
    overflow: hidden;
}

@media screen and (max-width: 1279.98px) {
    .intro-text {
        max-width: 50%;
    }
}
@media screen and (max-width: 1023.98px) {
    .intro-text {
        max-width: 100%;

        ul {
            width: 70%;
            margin-left: auto;
            margin-right: auto;

            li {
                margin-left: 20px;
                text-align: left;
            }
        }
    }

    .intro-video-holder {
        height: 440px;
    }
}

@media screen and (max-width: 767.98px) {
    .intro-text {
        max-width: 100%;

        h1 {
            font-size: 2.2rem;
        }

        ul {
            width: 100%;
            li {
                margin-left: 20px;
                text-align: left;
            }
        }
    }
}

@media screen and (max-width: 599.98px) {
    .intro-text {
        max-width: 100%;

        .intro-subtitle {
            margin-bottom: 10px;
        }

        .intro-cards-desc {
            margin-bottom: 10px;
            margin-top: 10px;
        }

        .onboard-intro__button {
            margin-top: 20px;
        }
    }

    .intro-video-holder {
        height: 200px;
    }
}
</style>
