import { mapGetters } from 'vuex';
import { getIdFromURL } from 'vue-youtube-embed';

export default {
    props: ['revertChoice'],
    created() {
        this.videoId = getIdFromURL(this.content.url);
    },
    data() {
        return {
            videoId: '',
            videoLoaded: false,
            player: null
        };
    },
    computed: {
        ...mapGetters({
            content: 'content',
            guest: 'guest',
            pathway: 'pathway'
        })
    },
    methods: {
        videoReady(event) {
            this.player = event.target;
            this.player.playVideo();

            this.playVideoAction(this.content, this.pathway.id);
        },
        loadVideo() {
            this.videoLoaded = true;
        },
        videoEnded() {
            this.$emit('loadNext');
        }
    },
    watch: {
        revertChoice: {
            handler() {
                if (this.revertChoice) {
                    this.$emit('updateRevertChoice', false);
                    this.$emit('backStep');
                }
            },
            immediate: true
        }
    }
};
