<template>
    <v-btn color="primary" depressed @click="$emit('click', $event)">
        <svg
            width="20"
            height="20"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2.4"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
        >
            <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path>
            <polyline points="17 21 17 13 7 13 7 21"></polyline>
            <polyline points="7 3 7 8 15 8"></polyline>
        </svg>
        <span class="ml-2">Save Changes</span></v-btn
    >
</template>
<script>
export default {
    name: 'save-button'
};
</script>
