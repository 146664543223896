<template>
    <div class="admin-card">
        <div class="grid-row grid-row--nog">
            <div class="grid-col grid-col--auto">
                <img
                    :src="contentImage"
                    width="160"
                    height="160"
                    alt=""
                    class="admin-card__image"
                />
            </div>
            <div class="grid-col">
                <div class="grid-row grid-row--aic grid-row--jcb">
                    <div class="grid-col grid-col--auto">
                        <div
                            class="admin-card__badge"
                            :class="`admin-card__badge--${typeMap[content.content_type.name]}`"
                            @click="$emit('applySearch', { types: content.content_type.name })"
                        >
                            {{ content.content_type.name }}
                        </div>
                    </div>
                    <div class="grid-col grid-col--auto ml-auto" v-if="content.featured">
                        <div class="admin-card__status admin-card__status--active">Featured</div>
                    </div>
                    <div class="grid-col grid-col--auto">
                        <a
                            v-if="showEdit"
                            class="admin-card__edit"
                            :href="'/admin/content/' + content.id + '/edit'"
                        >
                            <svg
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                stroke-width="2"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            >
                                <path
                                    d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"
                                ></path>
                                <path
                                    d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"
                                ></path>
                            </svg>
                        </a>
                    </div>
                </div>

                <h4 class="mt--050 h4 h4--sm">{{ content.title }}</h4>

                <div class="grid-row">
                    <div class="grid-col grid-col--10">
                        <p class="mt--025">{{ content.short_description }}</p>
                    </div>
                </div>

                <div class="mt--075 grid-row grid-row--aic">
                    <div class="grid-col">
                        <div class="admin-tags">
                            <div
                                class="admin-tags__item"
                                v-for="topic in content.topics"
                                @click="$emit('applySearch', { topics: topic.id })"
                            >
                                {{ topic.name }}
                            </div>
                        </div>
                    </div>
                    <div class="grid-col grid-col--auto">
                        <button class="admin-card__toggle" @click="spoilerActive = !spoilerActive">
                            <svg
                                viewBox="0 0 24 24"
                                width="24"
                                height="24"
                                stroke="currentColor"
                                stroke-width="3"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            >
                                <polyline points="6 9 12 15 18 9"></polyline>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="admin-card__spoiler" :class="{ active: spoilerActive }">
            <div class="grid-row">
                <div class="mt--075 grid-col grid-col--auto">
                    <div class="p p--xs fw--500 tc--light">Featured:</div>
                    <div class="p p--sm fw--500 tc--dark">
                        {{ content.featured ? 'True' : 'False' }}
                    </div>
                </div>

                <div
                    class="mt--075 grid-col grid-col--auto"
                    v-for="(setting, name) in content.settings"
                >
                    <div class="p p--xs fw--500 tc--light">{{ name | capitalize }}:</div>
                    <div class="p p--sm fw--500 tc--dark">{{ setting }}</div>
                </div>

                <div class="mt--075 grid-col grid-col--auto">
                    <div class="p p--xs fw--500 tc--light">Order:</div>
                    <div class="p p--sm fw--500 tc--dark">
                        {{ content.order }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Time from '../../../mixins/Time';
import ContentEditorModal from './ContentEditorModal';
import { mapGetters } from 'vuex';

export default {
    name: 'admin-content-card',
    props: ['content', 'authUser'],
    mixins: [Time],
    data() {
        return {
            spoilerActive: false,
            typeMap: {
                QuestionSet: 'question',
                Iframe: 'iframe',
                Video: 'video',
                Text: 'article',
                Article: 'article',
                Card: 'card',
                Link: 'link'
            }
        };
    },
    computed: {
        contentImage() {
            if (this.content.content_type.name === 'Card')
                return this.asset('img/card_placeholder.jpg');
            if (this.content.content_type.name === 'Link')
                return this.asset('img/link_placeholder.jpg');
            if (this.content.content_type.name === 'Text')
                return this.asset('img/text_placeholder.jpg');

            return this.content.thumbnail_xs;
        },
        showEdit() {
            if (this.content.content_type.name !== 'Card') return true;

            return !(!this.authUser.admin && this.content.content_type.name === 'Card');
        }
    },
    methods: {
        deleteContent(content) {
            if (confirm('Are you sure you want to delete content ' + content.title + '?')) {
                axios
                    .delete('/admin/content/' + content.id)
                    .then(res => {
                        let index = this.contentList.findIndex(x => x.id === content.id);

                        if (index > -1) {
                            this.contentList.splice(index, 1);
                        }
                    })
                    .catch(err => {
                        alert(err.response.data.errors.content[0]);
                    });
            }
        }
    },
    watch: {}
};
</script>

<style lang="scss">
.admin-card__badge,
.admin-tags__item {
    cursor: pointer;
}
</style>
