<template>
    <div class="rewards-container">
        <div class="deepdive-thanks">

            <div class=".grid-row--jcc rewards-badge-mobile">
                <img :src="asset('img/aarp_rewards.svg')" alt="" />
            </div>
            <div class="grid-row">
                <div class="grid-col--2 rewards-badge-image">
                    <img :src="asset('img/aarp_rewards.svg')" alt="" />
                </div>
                <div class="grid-col pl-1">
                    <h4>You're missing out on AARP Rewards points!</h4>
                    <div class="mt--100">
                        <p class="ta--left">
                            Get started with AARP's free loyalty program to earn points on this
                            activity and hundreds more. AARP members earn 50% more points on all
                            activities.
                        </p>
                        <a
                            target="_blank"
                            class="aarp-trivia-join-rewards__section-cta"
                            href="https://secure.aarp.org/applications/user/programOptIn?promo=AARPREWARDS&ref=https%3A%2F%2Fcaretotalk.aarp.org"
                        >
                            Get started now
                        </a>

                        <a
                            class="aarp-trivia-join-rewards__section-link"
                            target="_blank"
                            href="https://secure.aarp.org/applications/user/login?promo=AARPREWARDS"
                            v-if="! userIsLoggedInWithAARP"
                        >
                            Already part of AARP Rewards? Login to earn points
                            <span class="aarp-trivia-join-rewards__section-link-caret"></span>
                        </a>
                    </div>
                </div>
            </div>
            <button type="button" @click="exit" class="deepdive-thanks__close">
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                >
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
            </button>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    name: 'rewards-modal',
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            guest: 'guest'
        })
    },
    methods: {
        exit() {
            this.$store.commit('SET_SHOW_REWARDS_MODAL', false);
        },
        userIsLoggedInWithAARP() {
            return Boolean(AARP.store.getState().cookie.fedid);
        }
    }
};
</script>
<style lang="scss" scoped>

.rewards-badge-mobile {
    display: none;
}

@media only screen and (max-width: 768px) {
    .rewards-container {
        width: 80% !important;
    }

    .rewards-badge-image {
        display: none;
    }

    .rewards-badge-mobile {
        display: inline-block;
    }

    .aarp-trivia-join-rewards__section-cta {
        max-width: 100% !important;
    }
}

.rewards-container {
    z-index: 999;
    width: 55%;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    h4 {
        text-align: left;
    }

    .aarp-trivia-join-rewards__section-cta {
        font-size: 1em;
        font-weight: 700;
        border-radius: 1.5625em;
        height: 3.125em;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        text-align: center;
        margin: 20px auto auto 0;
        padding: 0 1.25em;
        min-width: 7.8125em;
        max-width: 17.1875em;
        background-color: #ec1300;
        color: #fff;
        border: 2px solid #ec1300;
    }

    .aarp-trivia-join-rewards__section-link {
        display: block;
        vertical-align: middle;
        font-size: 0.875em;
        margin-top: 1.07143em;
        color: #000;
        text-decoration: none;
        cursor: pointer;
        font-weight: 700;
        text-align: left;
    }

    .aarp-trivia-join-rewards__section-link-caret {
        border: solid #ec1300;
        border-width: 0 0.17857em 0.17857em 0;
        display: inline-block;
        padding: 0.17857em;
        -webkit-transform: rotate(-45deg) translateY(-0.0714em);
        transform: rotate(-45deg) translateY(-0.0714em);
        margin-left: 0;
        margin-top: 15px;
    }
}
</style>
