<template>
    <div class="rfeed">
        <deck-header></deck-header>

        <div class="grid-cont rfeed__cont">
            <transition name="fade" mode="out-in">
                <notification
                    class="mb--200"
                    :message="flashMessage"
                    :duration="15000"
                    @close="snackbar = false"
                    v-if="snackbar"
                ></notification>
            </transition>
        </div>
        <div class="grid-cont rfeed__cont">
            <div class="grid-row grid-row--smg">
                <caregiving-deck></caregiving-deck>

                <caregiving-clips :videos="videos"></caregiving-clips>

                <caregiving-resources :links="links"></caregiving-resources>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import collectorActions from '../../../mixins/collectorActions';
import CaregivingClips from './CaregivingClips';
import CaregivingResources from './CaregivingResources';

export default {
    components: { CaregivingResources, CaregivingClips },
    props: ['originalGuest', 'flashMessage', 'videos', 'links'],
    mixins: [collectorActions],
    data() {
        return {
            snackbar: false
        };
    },
    mounted() {
        this.$store.commit('SET_GUEST', this.originalGuest);
        this.openHomepageAction();

        if (this.flashMessage) {
            this.snackbar = true;
        }
    },
    computed: {
        ...mapGetters({
            guest: 'guest'
        })
    },
    methods: {},
    watch: {}
};
</script>

<style lang="scss"></style>
