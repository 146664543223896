<template>
    <div class="grid-row grid-row--jca">
        <facebook-button
            class="share-button--circle share-button--outline"
            :url="url"
            :description="content.title"
            :isBlank="false"
            btnText=""
        />
        <twitter-button
            class="share-button--circle share-button--outline"
            :url="url"
            :description="content.title"
            :isBlank="false"
            btnText=""
        />
        <linked-in-button
            class="share-button--circle share-button--outline"
            :url="url"
            :description="content.title"
            :isBlank="false"
            btnText=""
        />
        <pinterest-button
            class="share-button--circle share-button--outline"
            :url="url"
            :description="content.title"
            :isBlank="false"
            btnText=""
        />
    </div>
</template>
<script>
import FacebookButton from 'vue-share-buttons/src/components/FacebookButton';
import TwitterButton from 'vue-share-buttons/src/components/TwitterButton';
import LinkedInButton from 'vue-share-buttons/src/components/LinkedInButton';
import PinterestButton from 'vue-share-buttons/src/components/PinterestButton';
export default {
    name: 'share',
    components: { TwitterButton, FacebookButton, LinkedInButton, PinterestButton },
    props: ['url', 'content'],
    mounted() {},
    data() {
        return {};
    },
    computed: {}
};
</script>
