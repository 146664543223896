export class DataCollector {
    constructor() {
        this.guestUuid = window.guestUuid;
        this.actions = [];

        this.options = {
            globalProperties: {
                session_uuid: window.sessionUuid,
                guest_uuid: this.guestUuid
            }
        };
    }

    addAction(actionName, actionData) {
        let data = this.options
            ? _.extend({}, this.options.globalProperties, actionData)
            : actionData;

        axios.post('/guest/api/' + actionName + '/collector', data).catch(err => {
            console.log(err);
        });

        this.actions.push({ [actionName]: data });
    }
}
