<template>
    <div>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <v-snackbar color="success" top v-model="snackbar" :timeout="1500">
            Guest Template
            <template v-if="guest.id"
                >Updated</template
            >
            <template v-else
                >Created</template
            >
            <v-icon dark>check_circle</v-icon>
        </v-snackbar>

        <div class="admin-header">
            <v-row>
                <div class="display-1 col-auto">
                    Pathway Test Setup
                </div>
            </v-row>
        </div>

        <v-autocomplete
            :items="guests"
            item-value="id"
            item-text="email"
            v-model="guestId"
            :rules="[v => !!v || 'Please select a guest to test with']"
            label="Select existing guest"
            outlined
            @change="setGuest"
        ></v-autocomplete>

        <v-form>
            <v-row>
                <v-col cols="12" md="5" sm="12" xs="12">
                    <v-text-field
                        label="Guest Name"
                        placeholder="Enter the name"
                        v-model="guest.name"
                        :readonly="guest.id && guest.id.length"
                        outlined
                    ></v-text-field>
                </v-col>

                <v-col cols="12" md="5" sm="12" xs="12">
                    <v-text-field
                        label="Guest Email"
                        placeholder="Enter the email"
                        v-model="guest.email"
                        :readonly="guest.id && guest.id.length"
                        outlined
                    ></v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="5" sm="12" xs="12">
                    <topic-dropdown
                        v-model="topics"
                        :showLabel="false"
                        :key="topicsKey"
                    ></topic-dropdown>
                </v-col>

                <v-col cols="12" md="5" sm="12" xs="12">
                    <v-select
                        required
                        :items="contents"
                        item-value="id"
                        item-text="title"
                        multiple
                        v-model="savedContent"
                        :rules="[v => !!v || 'Please select saved content for the guest']"
                        label="Saved Content"
                        outlined
                    ></v-select>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="5" sm="12" xs="12">
                    <v-select
                        required
                        :items="questionSetNodes"
                        item-value="content.id"
                        item-text="content.title"
                        key="id"
                        multiple
                        v-model="answeredQuestionSetIds"
                        :rules="[v => !!v || 'Please select question sets the guest answered']"
                        label="Question Sets"
                        outlined
                    ></v-select>
                </v-col>
            </v-row>

            <div v-if="answeredQuestionSets.length">
                <v-row>
                    <div class="display-1 col-sm-10">Question Set Answers</div>
                </v-row>

                <div
                    v-for="questionSet in answeredQuestionSets"
                    :key="'questionSet#' + questionSet.id"
                >
                    <div class="title">{{ questionSet.title }}</div>
                    <div v-for="question in questionSet.questions">
                        <v-col cols="12" md="5" xs="12">
                            <v-select
                                required
                                :items="question.answers"
                                item-value="id"
                                item-text="text"
                                v-model="questionAnswers[question.id]"
                                :rules="[v => !!v || 'Please select an answer for this question']"
                                :label="question.text"
                                outlined
                            ></v-select>
                        </v-col>
                    </div>
                </div>
            </div>

            <v-btn color="primary" @click="createGuest()">
                <template v-if="guest.id">
                    Update Guest Template
                </template>
                <template v-else>
                    Create Guest Template
                </template>
            </v-btn>
            <v-btn
                color="primary"
                target="_blank"
                v-if="guest.id"
                @click="nodeSelectionDialog = true"
                >Clone and test</v-btn
            >
        </v-form>

        <v-dialog v-model="nodeSelectionDialog" width="650">
            <v-card>
                <v-card-title class="headline lighten-2">
                    Pick Starting Node
                </v-card-title>

                <v-card-text>
                    <v-select
                        required
                        :items="pathway.nodes"
                        item-value="id"
                        item-text="content.title"
                        v-model="nodeId"
                        :rules="[v => !!v || 'Please select a start node']"
                        label="Start Node"
                        outlined
                    ></v-select>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="nodeSelectionDialog = false">
                        Close
                    </v-btn>
                    <v-btn
                        color="primary"
                        text
                        :href="
                            '/admin/pathways/' + pathway.id + '/mount/' + guest.id + '/' + nodeId
                        "
                        target="_blank"
                    >
                        Start Testing
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
export default {
    name: 'setup',
    props: [
        'pathway',
        'initTopics',
        'contents',
        'questionSetNodes',
        'allQuestionSets',
        'guests',
        'nodes',
        'startNode'
    ],
    mounted() {
        const urlParams = new URLSearchParams(window.location.search);

        if (urlParams.get('startNode')) {
            this.node = parseInt(urlParams.get('startNode'));
        }
    },
    data() {
        return {
            nodeId: this.startNode ? this.startNode.id : null,
            node: null,
            topics: this.initTopics,
            answeredQuestionSetIds: [],
            answeredQuestionSets: [],
            questionAnswers: {},
            guestId: null,
            guest: {},
            savedContent: [],
            snackbar: false,
            overlay: false,
            latestAnswer: null,
            contentFeedback: null,
            feedback: null,
            nodeSelectionDialog: false,
            topicsKey: 0
        };
    },
    methods: {
        createGuest() {
            this.overlay = true;
            axios
                .post('/admin/pathways/' + this.pathway.id + '/test', {
                    name: this.guest.name,
                    email: this.guest.email,
                    topics: this.topics,
                    saved_content: this.savedContent,
                    questionAnswers: this.questionAnswers
                })
                .then(response => {
                    this.overlay = false;
                    this.snackbar = true;

                    this.guest = response.data.guest;
                })
                .catch(error => {
                    this.overlay = false;
                });
        },
        setGuest() {
            this.guest = this.guests.find(g => {
                return g.id === this.guestId;
            });

            if (this.guest.id) {
                axios
                    .get('/admin/guests/' + this.guest.id)
                    .then(response => {
                        this.answeredQuestionSetIds = response.data.questionSetIds;
                        this.topics = response.data.topics;
                        this.savedContent = response.data.savedContent;
                        this.questionAnswers = response.data.answers;
                        this.topicsKey++;
                    })
                    .catch(error => {
                        alert('Failed to fetch guest data');
                    });
            }
        }
    },
    watch: {
        answeredQuestionSetIds() {
            this.answeredQuestionSets = [];

            this.answeredQuestionSetIds.forEach(id => {
                this.answeredQuestionSets.push(
                    this.allQuestionSets.find(content => {
                        return content.id === id;
                    })
                );
            });
        },
        nodeId() {
            this.node = this.nodes.find(nd => {
                return nd.id === this.nodeId;
            });
        }
    }
};
</script>
