require('./bootstrap');

import './helpers/filters.js';
import Vuex from 'vuex';
import VueYouTubeEmbed from 'vue-youtube-embed';
import store from './vuex/guestStore';
import VTooltip from 'v-tooltip';
import VueTour from 'vue-tour';
import VueShepherd from 'vue-shepherd';

require('vue-tour/dist/vue-tour.css');
require('shepherd.js/dist/css/shepherd.css');

window.Vapor = require('laravel-vapor');
window.Vue = require('vue');

Vue.use(Vuex);
Vue.use(VueYouTubeEmbed);
Vue.use(VTooltip);
Vue.use(VueTour);
Vue.use(VueShepherd);

const files = require.context('./', true, /\.vue$/i);
files.keys().map(key =>
    Vue.component(
        key
            .split('/')
            .pop()
            .split('.')[0],
        files(key).default
    )
);

Vue.mixin({
    mounted() {

        //@todo move this code from this mixin to DeckView - mounted
        if (typeof window.authUser !== 'undefined' && window.authUser.access_token && localStorage.getItem('access_token') === null) {
            console.log(window.authUser);
            localStorage.setItem('access_token', window.authUser.access_token);
            localStorage.setItem('access_token_created_timestamp', Date.now());
        }

        //@todo refresh access token if 11:50 passed since it was created
        //@todo clear local storage if user logs out
    },
    computed: {
        isMobile: function() {
            let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent); //webOS?

            if (!isMobile) {
              const isMac = RegExp(/Macintosh/i).test(navigator.userAgent);

              if (isMac && navigator.maxTouchPoints && navigator.maxTouchPoints > 2) {
                console.log('yes is mac and has touchies');
                isMobile = true;
              }
            }
            return isMobile;
        }
    },
    methods: {
        asset: window.Vapor.asset,
        /**
         * Copy a string to clipboard
         * @param  {String} string         The string to be copied to clipboard
         * @return {Boolean}               returns a boolean correspondent to the success of the copy operation.
         * @see https://stackoverflow.com/a/53951634/938822
         */
        copyToClipboard(string) {
            let textarea;
            let result;

            try {
                textarea = document.createElement('textarea');
                textarea.setAttribute('readonly', true);
                textarea.setAttribute('contenteditable', true);
                textarea.style.position = 'fixed'; // prevent scroll from jumping to the bottom when focus is set.
                textarea.value = string;

                document.body.appendChild(textarea);

                textarea.focus();
                textarea.select();

                const range = document.createRange();
                range.selectNodeContents(textarea);

                const sel = window.getSelection();
                sel.removeAllRanges();
                sel.addRange(range);

                textarea.setSelectionRange(0, textarea.value.length);
                result = document.execCommand('copy');
            } catch (err) {
                console.error(err);
                result = null;
            } finally {
                document.body.removeChild(textarea);
            }

            // manual copy fallback using prompt
            if (!result) {
                const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
                const copyHotkey = isMac ? '⌘C' : 'CTRL+C';
                result = prompt(`Press ${copyHotkey}`, string); // eslint-disable-line no-alert
                if (!result) {
                    return false;
                }
            }
            return true;
        }
    }
});

function orientation() {
    if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
        if (screen.availHeight < screen.availWidth) {
            document.getElementById('turn').style.display = 'flex';
            document.getElementById('app').style.display = 'none';
        } else {
            document.getElementById('turn').style.display = 'none';
            document.getElementById('app').style.display = 'block';
        }
    }
}
orientation();
window.addEventListener(
    'orientationchange',
    function() {
        orientation();
    },
    false
);

const app = new Vue({
    el: '#app',
    store
});
