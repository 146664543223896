<template>
    <div>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <div class="admin-header">
            <v-row>
                <div class="col-auto">
                    <back-button :url="`/admin/guests/${guest.id}/actions`"></back-button>
                </div>
                <div class="display-1 col-auto">
                    Session Details: {{ guest.email || guest.guest_uuid }}
                </div>
            </v-row>
        </div>

        <div class="admin-table">
            <v-data-table
                :headers="headers"
                :items="actions"
                :options.sync="options"
                :footer-props="{ 'items-per-page-options': [20, 50, 75, 100] }"
                :items-per-page="20"
                class="elevation-1"
            >
                <template v-slot:no-data>
                    <div>No data</div>
                </template>
                <template v-if="actions.length" v-slot:body="{ items }">
                    <tbody>
                        <tr v-for="item in items" :key="item.uuid">
                            <td>{{ item.name }}</td>
                            <td>
                                <template v-for="(value, key) in item.data">
                                    <span>
                                        {{ key }}: {{ Array.isArray(value) ? value.length : value }}
                                    </span>
                                    <br />
                                </template>
                            </td>
                            <td>
                                <template v-for="(value, key) in item.page_info">
                                    <span> {{ key }}: {{ value }} </span>
                                    <br />
                                </template>
                            </td>
                            <td>{{ renderLocalTime(item.created_at) }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-data-table>
        </div>
    </div>
</template>
<script>
import Time from '../../../mixins/Time';

export default {
    props: ['authUser', 'guest', 'actions'],
    mixins: [Time],
    data() {
        return {
            overlay: false,
            headers: [
                { text: 'Name', value: 'name', sortable: true },
                { text: 'Data', value: 'data', sortable: false },
                { text: 'Page info', value: 'page_info', sortable: false },
                { text: 'Date', value: 'created_at', sortable: true }
            ],
            options: { sortDesc: [false], sortBy: ['created_at'] }
        };
    },
    methods: {}
};
</script>
