<template>
    <div class="grid-cont grid-cont--xl">
        <div class="grid-col grid-col--12">
            <div class="mt--050 grid-row grid-row--smg">
                <div
                    v-for="card in cards"
                    class="mt--100 grid-col grid-col--4 grid-col--md-6 grid-col--xs-12"
                >
                    <deck-card
                        :in-grid="true"
                        :card="card"
                        :key="card.id + '-' + deckTab"
                    ></deck-card>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import collectorActions from '../../../mixins/collectorActions';
import DeckCard from './DeckCard';

export default {
    name: 'deck-grid',
    props: ['cards'],
    mixins: [collectorActions],
    components: { DeckCard },
    mounted() {},
    data() {
        return {
            overlay: false
        };
    },
    computed: {
        ...mapGetters({
            guest: 'guest',
            deckTab: 'deckTab'
        })
    },
    methods: {},
    watch: {}
};
</script>

<style lang="scss">
.stack--buttons {
    margin-top: 48px;
}

.deck-btn--cont {
    min-height: 40px;
    margin-top: -20px;
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    margin-right: auto;
}

.icon {
    margin-top: 3px;
    margin-left: 5px;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
}
</style>
