<template>
    <div class="flow-popover flow-popover--xl">
        <v-btn
            class="infoModalButton"
            v-if="multiContent"
            icon
            depressed
            color="error"
            @click="deleteContent(content)"
        >
            <svg
                viewBox="0 0 24 24"
                width="20"
                height="20"
                stroke="currentColor"
                stroke-width="1.8"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
            >
                <polyline points="3 6 5 6 21 6"></polyline>
                <path
                    d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                ></path>
                <line x1="10" y1="11" x2="10" y2="17"></line>
                <line x1="14" y1="11" x2="14" y2="17"></line>
            </svg>
        </v-btn>
        <div :class="`admin-card__badge admin-card__badge--${typeMap[content.content_type.name]}`">
            {{ content.content_type.name }}
        </div>
        <h6 class="mt--050 tc--dblue">{{ content.title }}</h6>
        <div class="mt--075"></div>
        <div class="admin-tags admin-tags--xs">
            <div
                class="admin-tags__content"
                v-for="topic in content.topics"
                :key="`topic-${topic.id}`"
            >
                {{ topic.name }}
            </div>
        </div>
        <p class="mt--100 p p--xxs">
            {{ content.description }}
        </p>
        <img
            :src="content.thumbnail_sm"
            v-if="content.thumbnail_sm"
            width="290"
            height="145"
            alt=""
            class="mt--100 flow-popover__image"
        />

        <dl
            class="mt--100 p p--xxs flow-popover__list"
            v-if="content.content_type.name === 'QuestionSet'"
        >
            <dt>Preface</dt>
            <dd class="tc--dark">
                <div>{{ content.preface }}</div>
            </dd>
            <dt>Questions</dt>
            <dd class="tc--dark">
                <div>
                    <template v-for="(question, index) in content.questions">
                        {{ index + 1 }}. {{ question.text }}
                        <br />
                    </template>
                </div>
            </dd>
        </dl>

        <dl
            v-if="['Iframe', 'Video'].includes(content.content_type.name)"
            class="mt--100 p p--xxs flow-popover__list"
        >
            <dt>Source Url</dt>
            <dd class="tc--dark">
                <div>
                    <a :href="content.url" target="_blank" class="text-link">
                        {{ content.url }}
                    </a>
                </div>
            </dd>
            <dt>Dimensions</dt>
            <dd class="tc--dark">
                <div>{{ content.settings.width }} by {{ content.settings.height }}</div>
            </dd>
        </dl>
    </div>

    <!--    <v-card class="mx-auto mb-2 info-card" max-width="600">-->
    <!--        <v-img contain class="white&#45;&#45;text align-end" :src="content.thumbnail_sm" max-height="800">-->
    <!--        </v-img>-->

    <!--        <v-card-title class="pb-0" v-text="content.title"></v-card-title>-->
    <!--        <v-card-subtitle class="pb-0 pt-2" v-text="content.content_type.name"></v-card-subtitle>-->
    <!--        <v-card-subtitle class="pb-0 pt-3" v-text="content.description"></v-card-subtitle>-->

    <!--        <v-card-text class="text&#45;&#45;primary pt-5">-->
    <!--            <v-btn-->
    <!--                class="infoModalButton"-->
    <!--                v-if="multiContent"-->
    <!--                fab-->
    <!--                dark-->
    <!--                x-small-->
    <!--                color="red darken-1"-->
    <!--                @click="deleteContent(content)"-->
    <!--            >-->
    <!--                <v-icon dark>delete</v-icon>-->
    <!--            </v-btn>-->
    <!--            <div v-if="content.url">-->
    <!--                URL:-->
    <!--                <a :href="content.url" v-text="content.url" target="_blank"></a>-->
    <!--            </div>-->

    <!--            <div v-if="content.path">-->
    <!--                Path:-->
    <!--                <a :href="content.path" v-text="content.path" target="_blank"></a>-->
    <!--            </div>-->

    <!--            <div v-if="content.preface">-->
    <!--                Preface:-->
    <!--                <p v-text="content.preface"></p>-->
    <!--            </div>-->

    <!--            <div v-for="(setting, key) in content.settings">-->
    <!--                {{ capitalize(key) }}: {{ setting }}-->
    <!--            </div>-->
    <!--        </v-card-text>-->
    <!--    </v-card>-->
</template>
<script>
export default {
    name: 'admin-info-card',
    props: ['content', 'multiContent'],
    data() {
        return {
            typeMap: {
                QuestionSet: 'quest',
                Iframe: 'iframe',
                Video: 'video',
                Text: 'article',
                Card: 'card',
                Link: 'link'
            }
        };
    },
    methods: {
        capitalize(string) {
            return string.charAt(0).toUpperCase() + string.slice(1).replaceAll('_', ' ');
        },
        deleteContent(content) {
            if (confirm('Delete content from multi content node?')) {
                this.$emit('deleteContent', content.id);
            }
        }
    }
};
</script>

<style scoped lang="scss">
.flow-popover {
    position: relative;
    border: 1px solid #ccc;
    margin-bottom: 10px;

    .infoModalButton {
        position: absolute;
        top: 10px;
        right: 10px;
    }
}
</style>
