<template>
    <div class="onboard-quiz__cont">
        <div class="onboard-iframe">
            <content-header></content-header>

            <div class="iframe-intro__cont">
                <iframe
                    :src="content.url"
                    :width="content.settings.width"
                    :height="content.settings.height"
                    class="mt--200 resource__iframe"
                ></iframe>
            </div>
        </div>
    </div>
</template>
<script>
import Time from '../../../mixins/Time';
import IframeMixin from '../../../mixins/IframeMixin';

export default {
    name: 'intro-iframe-content',
    props: [''],
    mixins: [Time, IframeMixin]
};
</script>

<style lang="scss">
.onboard-quiz__cont {
    padding-top: 0;
}
.largeIframe {
    position: absolute;
    left: 0;
}

.onboard-iframe {
    position: relative;
    max-width: 900px;
    margin-right: auto;
    margin-left: auto;

    iframe {
        max-height: 630px;
    }
}
</style>
