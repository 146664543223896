<template>
    <div class="row grid-row--aic">
        <loading :active="overlay" />
        <main-header></main-header>
        <div class="mt--300 grid-col grid-col--12 removed--xs"></div>

        <div class="grid-cont" style="padding-top: 30px">
            <div class="grid-row grid-row--nog mb--200" v-if="searchText">
                <p class="h4">Search results for: {{ searchText }}</p>
            </div>

            <div class="grid-row grid-row--nog mb--200 grid-row--jcc" v-else>
                <p class="h4">Enter search term</p>
            </div>

            <div class="grid-row grid-row--smg">
                <div
                    class="grid-col grid-col--12"
                    v-for="content in searchResults"
                    :key="`search-${content.id}`"
                >
                    <div class="mb--100">
                        <settings-card :content="content"></settings-card>
                    </div>
                </div>

                <div class="grid-col grid-col--12 mt--300" v-if="total > rowsPerPage">
                    <div class="pagination" style="justify-content: center">
                        <a
                            @click="page--"
                            class="pagination__item"
                            :class="{ disabled: page === 1 }"
                        >
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                stroke-width="2.4"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            >
                                <line x1="19" y1="12" x2="5" y2="12"></line>
                                <polyline points="11 18 5 12 11 6"></polyline>
                            </svg>
                        </a>

                        <a
                            v-for="index in lastPage"
                            @click="page = index"
                            class="pagination__item"
                            :class="{ active: page === index }"
                        >
                            {{ index }}
                        </a>

                        <a
                            @click="page++"
                            class="pagination__item"
                            :class="{ disabled: page === lastPage }"
                        >
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                stroke-width="2.4"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            >
                                <line x1="5" y1="12" x2="19" y2="12"></line>
                                <polyline points="13 6 19 12 13 18"></polyline>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <pathway-footer></pathway-footer>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import collectorActions from '../../../mixins/collectorActions';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    name: 'search-view',
    props: ['originalGuest'],
    mixins: [collectorActions],
    components: { Loading },
    mounted() {
        let uri = window.location.search.substring(1);
        let params = new URLSearchParams(uri);

        this.searchText = params.get('search_text') ?? '';

        this.getDataFromApi();

        this.$store.commit('SET_GUEST', this.originalGuest);
    },
    data() {
        return {
            searchText: '',
            searchResults: [],
            overlay: false,
            rowsPerPage: 20,
            page: 1,
            lastPage: 0,
            total: 0
        };
    },
    computed: {
        ...mapGetters({
            guest: 'guest'
        })
    },
    methods: {
        getDataFromApi: _.debounce(function() {
            this.overlay = true;
            this.searchResults = [];
            let endpoint = '/search/json';
            let queries =
                '?search_text=' +
                this.searchText +
                '&rowsPerPage=' +
                this.rowsPerPage +
                '&page=' +
                this.page;

            if (this.searchText.length) {
                axios
                    .get(endpoint + queries)
                    .then(response => {
                        this.searchResults = response.data.data;

                        this.openSearchAction(this.searchText, this.searchResults, this.page);

                        this.showSearchResults = true;
                        this.overlay = false;
                        this.lastPage = response.data.last_page;
                        this.total = response.data.total;

                        window.scrollTo({ top: 0, behavior: 'smooth' });
                    })
                    .catch(function(error) {
                        console.error(error);
                    });
            } else {
                this.searchResults = [];
                this.overlay = false;
            }
        }, 500)
    },
    watch: {
        page: {
            handler() {
                this.getDataFromApi();
            }
        }
    }
};
</script>

<style lang="scss">
.pagination__item {
    cursor: pointer;
}
</style>
