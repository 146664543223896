<template>
    <div>
        <div class="subtitle-1" v-if="showLabel">Topics</div>
        <v-combobox
            v-model="topics"
            :items="allTopics"
            chips
            clearable
            label="Topics"
            multiple
            item-text="name"
            item-value="name"
            :return-object="false"
            :solo="solo"
            :rules="rules"
            @input="handleInput"
            :dense="dense"
            :outlined="dense"
            :hide-details="dense"
        >
            <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                    v-bind="attrs"
                    :input-value="selected"
                    close
                    @click="select"
                    @click:close="remove(item)"
                >
                    <strong>{{ item }}</strong>
                </v-chip>
            </template>
        </v-combobox>
    </div>
</template>
<script>
export default {
    name: 'topic-dropdown',
    props: {
        value: { required: true },
        showLabel: { default: true },
        solo: { default: true },
        dense: { default: false },
        rules: {
            type: [Array],
            default: function() {
                return [];
            }
        },
        allTopicsData: {
            required: false,
            default: function() {
                return [];
            },
            type: [Array]
        }
    },
    created() {
        if (!this.allTopicsData) {
            this.getDataFromApi();
        } else {
            this.allTopics = this.allTopicsData;
        }
    },
    mounted() {
        for (let i = 0; i < this.value.length; i++) {
            if (_.isObject(this.value[i])) {
                this.topics.push(this.value[i].name);
            } else {
                this.topics.push(this.value[i]);
            }
        }
        // emit topics without objects
        setTimeout(() => {
            this.$emit('input', this.topics);
        }, 300);
    },
    data() {
        return {
            topics: [],
            allTopics: []
        };
    },
    methods: {
        handleInput(e) {
            this.$emit('input', this.topics);
        },
        remove(item) {
            this.topics.splice(this.topics.indexOf(item), 1);
            this.topics = [...this.topics];
            this.handleInput();
        },
        getDataFromApi: _.debounce(function() {
            axios
                .get('/admin/topics/json')
                .then(response => {
                    this.allTopics = response.data;
                })
                .catch(function(error) {
                    console.error(error);
                });
        }, 100)
    },
    watch: {
        value() {
            if (this.value.length && _.isObject(this.value[0])) {
                this.topics = [];

                for (let i = 0; i < this.value.length; i++) {
                    this.topics.push(this.value[i].name);
                }

                // emit topics without objects
                setTimeout(() => {
                    this.$emit('input', this.topics);
                }, 300);
            }
        }
    }
};
</script>
